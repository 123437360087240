<!--        TYTUL STANOWISKO DOSWIADCZENIE-->
<template>
  <div class="col row column">
    <q-form ref="formRef" greedy class="col column q-scrollarea">
      <div class="col row q-scrollarea" style="overflow-y: auto; background-color: rgb(217,225,238);">
        <q-card flat style="border-radius: 9px; overflow-y: auto;" class="col row q-mr-md q-pa-xs">
          <q-card-section class="col row q-px-md">
            <div class="row full-width">
              <q-input class="q-py-sm q-mr-md"
                       color="primary"
                       stack-label
                       outlined
                       v-model="offer.title"
                       disable
                       label="Tytuł ogłoszenia"
                       :rules="[ val => val.length <= 40 || 'Please use maximum 40 characters',
              val => val.length >0 || 'Podaj tytuł ogłoszenia']"
                       dense>
                <template v-slot:append>
                  <q-avatar>
                    <q-icon
                        name="sym_o_title"
                        size="1rem"/>
                  </q-avatar>
                </template>
              </q-input>
              <q-select class="col q-py-sm q-mr-md" color="primary" stack-label outlined v-model="offer.jobPosition"
                        :options="filteredPosition"
                        :rules="[val => val.length >0 || 'Wybierz stanowisko']"
                        label="Stanowisko"
                        dense
                        use-input
                        @filter="(val, update) => filterAllPositions(val, update)"
              >
                <template v-slot:append>
                  <q-icon
                      name="mdi-account-tie"
                      size="1rem"/>
                </template>
              </q-select>
              <q-select class="col q-py-sm" color="primary" stack-label outlined v-model="offer.seniority"
                        :options="OfferDataTranslator.translateExperienceLevelsTypeToPolish()"
                        dense
                        :rules="[val => val.length >0 || 'Wybierz poziom stanowiska']"
                        label="Poziom stanowiska">
                <template v-slot:append>
                  <q-icon
                      name="mdi-account-star"
                      size="1rem"/>
                </template>
              </q-select>
            </div>
            <div class="q-pt-1rem q-pt-sm full-width">
              <q-field ref="fieldRef" v-model="offer.description" label-slot borderless dense
                       :rules="[val => val.length < 4000|| 'Zbyt długi opis - max 4000 znaków.']">
                <my-text class="full-width">
                  <q-editor
                      class="full-width"
                      v-model="offer.description"
                      min-height="13rem"
                      max-height="13rem"
                      toolbar-color="primary"
                      :toolbar="[
        ['bold', 'unordered'],
      ]"
                      :style="fieldRef && fieldRef.hasError ? 'border-color: #C10015' : ''"/>
                </my-text>
              </q-field>
              <div class=" q-my-md">
                <q-separator></q-separator>
              </div>
            </div>

            <contracts-section ref="contractsComponentRef" v-model="offer.contracts"
                               :form-template-data="formTemplateData"></contracts-section>

            <questions-section ref="childComponentRef" v-model="offer.questions"
                               :form-template-data="formTemplateData"></questions-section>
            <skills ref="skillsRef" :form-template-data="formTemplateData" v-model="offer"></skills>

            <languages-section
                :form-template-data="formTemplateData"
                v-model="offer">
            </languages-section>
            <operating-mode :form-template-data="formTemplateData" v-model="offer"></operating-mode>

            <q-card flat class="q-mx-sm q-mt-lg full-width q-mb-md" style="border-radius: 9px;background-color: rgba(229,237,246,0.82);">
              <q-card-section>
                <my-text class="text-white">
                  <span class="text-weight-bold text-primary" style="font-size: 15px;">Lokalizacje biur</span>
                  <span class="text-grey-8"> - podaj w jakich miastach znajdują się biura.
        </span>
                </my-text>
              </q-card-section>
            </q-card>

            <div class="q-pt-sm full-width">
              <q-select
                  dense
                  color="primary"
                  use-chips
                  multiple
                  v-model="offer.locations"
                  :rules="[val => locationsValidationAccordingToPlan(val) || locationPlanRulesMsg(val)]"
                  label="Lokalizacje biur"
                  clear-icon="cancel"
                  use-input
                  :options="locationsOptions"
                  outlined
                  @filter="(val, update) => filterAllLocations(val, update)"
              >
              </q-select>
            </div>
            <div v-if="showButtons" class="">
              <q-separator></q-separator>
            </div>
          </q-card-section>
        </q-card>
      </div>
      <q-stepper-navigation class="q-mt-sm q-pt-sm" v-if="showButtons">
        <q-btn style="border-radius: 7px" @click="nextStep()" color="dark" no-caps dense class="q-px-md" label="Aktualizuj dane"/>
      </q-stepper-navigation>
    </q-form>
  </div>
</template>

<script setup>
import {defineEmits, defineProps, computed, ref} from "vue"
import LanguagesSection from "@/components/common/add-offer/job-offer/language/LanguagesSection";
import OperatingMode from "@/components/common/add-offer/job-offer/operating-mode/OperatingModeSection";
import Skills from "@/components/common/add-offer/job-offer/skills/Skills";
import ContractsSection from "@/components/common/add-offer/job-offer/contracts/ContractsSection";
import OfferDataTranslator from "@/services/organization/offer/OfferDataTranslator";
import NotificationService from "@/services/notification/NotificationService";
import OrganizationOfferManagementService from "@/services/organization/offer/OrganizatioOfferManagementService"

const props = defineProps({
  formTemplateData: Object,
  title: String,
  modelValue: Object,
  showButtons: Boolean
})

const offer = computed({
  get: () => props.modelValue,
  set: (val) => emit('update:modelValue', val),
});

const emit = defineEmits(['update:modelValue', 'step:next', 'step:previous'])

const filteredPosition = ref(null)

function jobPositionOptions() {
  let options = []
  props.formTemplateData.jobOffer.jobPositions.forEach(jobPosition => {
    options.push(JobPositionTranslator.translateConstant(jobPosition))
  })
  return options;
}

function locationsValidationAccordingToPlan(val) {
  if (offer.value.planType === 'BASIC') {
    return val.length > 0 && val.length <= 2;
  }
  if (offer.value.planType === 'STANDARD') {
    return val.length > 0 && val.length <= 7;
  }
  if (offer.value.planType === 'PREMIUM') {
    return val.length > 0 && val.length <= 16;
  }
}

function locationPlanRulesMsg() {
  if (offer.value.planType === 'BASIC') {
    return 'Wybrałeś plan Basic, możesz podać od jednej do dwóch lokalizacji'
  }
  if (offer.value.planType === 'STANDARD') {
    return 'Wybrałeś plan Standard, możesz podać od jednej do siedmiu lokalizacji'
  }
  if (offer.value.planType === 'PREMIUM') {
    return 'Wybrałeś plan Premium, możesz podać od jednej do szesnastu lokalizacji'
  }
}

const childComponentRef = ref(null)
const contractsComponentRef = ref(null)
import {useQuasar} from 'quasar'
import QuestionsSection from "@/components/common/add-offer/job-offer/questions/QuestionsSection";
import JobPositionTranslator from "@/services/offer/JobPositionTranslator";

const $q = useQuasar();
const formRef = ref(null)

async function nextStep() {
  const skillsValid = validateSkills();
  const operatingModesValid = validateOperatingModes();
  const languageProficiencyValid = validateLanguageProficiency();
  const formValid = formRef.value.validate();
  const contractsValid = await validateContracts();
  console.info("final: " +contractsValid)
  const questionsValid = await validateQuestions()
  if (skillsValid && operatingModesValid && languageProficiencyValid && formValid && contractsValid && questionsValid) {
    updateOffer(offer.value)
  } else {
    console.error('Validation failed. Cannot proceed to the next step. Questions: ' + questionsValid);
  }
}

function updateOffer(jobOfferToUpdate) {
  const copiedJobOffer = JSON.parse(JSON.stringify(jobOfferToUpdate));
  OfferDataTranslator.mapSkillLevelsToModel(copiedJobOffer);
  copiedJobOffer.seniority = OfferDataTranslator.translateFromPolishToExperienceLevel(copiedJobOffer.seniority);
  mapContractsTypeToModel(copiedJobOffer);
  mapLanguagesToModel(copiedJobOffer);
  mapLocationsToModel(copiedJobOffer);
  mapQuestionsToModel(copiedJobOffer);
  OrganizationOfferManagementService.updateJobOffer(copiedJobOffer).then(respone =>{
    if(respone.ok){
      NotificationService.showNotification($q, 'positive', 'Zaktualizowano dane oferty');
    }
  });
}


function mapContractsTypeToModel(jobOfferToUpdate) {
  jobOfferToUpdate.contracts.forEach(contract => {
    contract.type = OfferDataTranslator.translateFromPolishToContractType(contract.type)
  })
}

function mapLanguagesToModel(jobOfferToUpdate) {
  jobOfferToUpdate.languages.forEach(language => {
    console.info(language)
    language.language = OfferDataTranslator.translateFromPolishToLanguage(language.language)
    language.proficiency = OfferDataTranslator.mapFromLanguageRateToProficiency(language.proficiency)
  })
}

function mapLocationsToModel(jobOfferToUpdate) {
  jobOfferToUpdate.locations = jobOfferToUpdate.locations.map(location => {
    return OfferDataTranslator.translateFromPolishToCity(location);
  });
}

function mapQuestionsToModel(jobOfferToUpdate) {
  jobOfferToUpdate.questions.forEach(question => {
    question.answerType = OfferDataTranslator.translateFromPolishToQuestionAnswerType(question.answerType);
  })
}


function validateSkills() {
  let skills = offer.value.skills;
  if (skillsRef.value.validate()) {
    for (const skill of skills) {
      if (skill.level === null) {
        NotificationService.showNotification($q, 'negative', 'Podaj wymagany poziom umiejętności dla ' + skill.name);
        return false;
      }
    }
  }
  return true;
}

function validateOperatingModes() {
  if (offer.value.operatingModes.length === 0) {
    NotificationService.showNotification($q, 'negative', 'Podaj przynajmniej jeden tryb pracy');
    return false;
  }
  return true;
}

function validateLanguageProficiency() {
  let languages = offer.value.languages;
  for (const lang of languages) {
    if (lang.proficiency === null) {
      NotificationService.showNotification($q, 'negative', 'Podaj poziom znajomości języka dla: ' + lang.language);
      return false;
    }
  }
  return true;
}

async function validateContracts() {
  if (offer.value.contracts.length > 0) {
    return await contractsComponentRef.value.validateChildComponents();
  } else {
    return true;
  }
}


async function validateQuestions() {
  if (offer.value.questions.length > 0) {
    return await childComponentRef.value.validateChildComponents()
  } else {
    return true
  }
}


const skillsRef = ref(null)

function filterAllPositions(val, update) {
  let options = jobPositionOptions()
  update(() => {
    if (val === "") {
      filteredPosition.value = jobPositionOptions()
    } else {
      let needle = val.toLowerCase();
      filteredPosition.value = options.filter(
          v => v.toLowerCase().indexOf(needle) > -1
      );
    }
  });
}

const locationsOptions = ref([])

function filterAllLocations(val, update) {
  let options = OfferDataTranslator.translateCitiesToPolish()
  update(() => {
    if (val === "") {
      locationsOptions.value = options;
    } else {
      let needle = val.toLowerCase();
      locationsOptions.value = options.filter(
          v => v.toLowerCase().indexOf(needle) > -1
      );
    }
  });
}
</script>
<style lang="sass" scoped>
@import "/src/assets/css/main.css"
.q-input
  font-family: "Verdana"
  font-size: 12px

.q-field-target
  font-family: "Verdana"
  font-size: 12px
  color: #581c9b

.q-select
  font-family: "Verdana"
  font-size: 12px

.q-editor
  font-family: "Verdana"
  font-size: 11px

.my-stepper
  width: 65%
</style>
