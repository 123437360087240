<template>
  <div class="full-width">
    <q-card flat class="q-mx-sm q-mt-lg full-width" style="border-radius: 9px;background-color: rgba(229,237,246,0.82);">
      <q-card-section>
        <my-text class="text-white">
          <span class="text-weight-bold text-primary" style="font-size: 15px;">Tryb pracy</span>
          <span class="text-grey-8"> - określ miejsce wykonywania pracy.
        </span>
        </my-text>
      </q-card-section>
    </q-card>
    <div class="row q-pb-sm q-mt-xs">
      <q-checkbox
          v-model="offer.operatingModes" label="Praca stacjonarna" :val="props.formTemplateData.jobOffer.operatingModes[0]"
          checked-icon="task_alt"
          unchecked-icon="highlight_off"
          color="cyan-7"
          class="q-pr-lg text-grey-8"
      />
      <q-checkbox
          v-model="offer.operatingModes" label="Praca hybrydowa" :val="props.formTemplateData.jobOffer.operatingModes[1]"
          checked-icon="task_alt"
          color="cyan-7"
          unchecked-icon="highlight_off"
          class="q-pr-lg text-grey-8"
      />
      <q-checkbox
          v-model="offer.operatingModes" label="Praca zdalna" :val="props.formTemplateData.jobOffer.operatingModes[2]"
          checked-icon="task_alt"
          color="cyan-7"
          unchecked-icon="highlight_off"
          class="q-pr-lg text-grey-8"
      />
    </div>
    <div class="q-my-none">
      <q-separator></q-separator>
    </div>


    <q-card flat class="q-mx-sm q-mt-lg full-width" style="border-radius: 9px;background-color: rgba(229,237,246,0.82);">
      <q-card-section>
        <my-text class="text-white">
          <span class="text-weight-bold text-primary" style="font-size: 15px;">Zatrudnienie</span>
          <span class="text-grey-8"> - określ czasowe zaangażowanie pracownika.
        </span>
        </my-text>
      </q-card-section>
    </q-card>
    <div class="row q-pb-sm q-mt-xs">
      <q-checkbox
          v-model="offer.workTypes" label="Pełny etat" val="FULL_TIME"
          checked-icon="task_alt"
          unchecked-icon="highlight_off"
          color="cyan-7"
          class="q-pr-lg text-grey-8"
      />
      <q-checkbox
          v-model="offer.workTypes" label="Część etatu" val="PART_TIME"
          checked-icon="task_alt"
          color="cyan-7"
          unchecked-icon="highlight_off"
          class="q-pr-lg text-grey-8"
      />
    </div>
  </div>
</template>

<script setup>
import {defineProps, defineEmits, computed} from 'vue'

const props = defineProps({
  formTemplateData: Object,
  modelValue: Object
})

const offer = computed({
  get: () => props.modelValue,
  set: (val) => emit('update:modelValue', val),
});

const emit = defineEmits(['update:modelValue'])

</script>