<template>
  <q-card flat v-ripple class="q-mt-none my-border"
          bordered
          :class="{ 'q-hoverable': !isMouseOverFavBtn, 'cursor-pointer': !isMouseOverFavBtn }"
          style="border-radius: 7px; border-width: 1px;"
          @click="openJobOffersInNewTab(props.jobOffer.id)">
    <span :class="{ 'q-focus-helper': !isMouseOverFavBtn}" style="color: #454a80"></span>
    <q-card-section>
      <div class="row">
        <div class="q-pa-xs" style=" display: flex;   flex-direction: column;
  justify-content: center;  /* Centering y-axis */
  align-items :center">
          <q-img
              style="width: 55px;height: 55px; border-radius: 6px;"
              v-bind:src="props.jobOffer.organization.logoUrl"
              fit='fill'
              :ratio="1"
          />
        </div>
        <div class="col q-pl-xs q-pt-xs q-pr-xs">
          <div class="justify-between">
            <div class="q-pa-none row justify-between">
              <div class="row">
                <p v-if="isSelected" class="q-pl-sm text-primary" style="font-size: medium; font-weight: 300">
                  {{ props.jobOffer.title }}</p>
                <p v-else class="q-pl-sm text-grey-8" style="font-size: large;">{{ props.jobOffer.title }}</p>
              </div>
              <!--              <q-chip disable v-if="isSelected"-->
              <!--                      style="border-width: 1px; max-height: 23px; font-size: 14px; font-weight: 400"-->
              <!--                       :label="salaryToString()"-->
              <!--                      square-->
              <!--                      :class="getSalaryColor()"-->
              <!--                     />-->
              <!--              <q-chip disable v-else style="border-width: 1px; max-height: 23px; font-size: 14px; font-weight: 400"-->
              <!--                       :label="salaryToString()"-->
              <!--                      :class="getSalaryColor()"-->
              <!--                      square-->
              <!--                      />-->
              <div class="q-mr-sm">
                <p style="border-width: 1px; max-height: 23px; font-size: 11px; font-weight: 500"
                   :class="getSalaryColor()">
                  {{ salaryToString() }}
                </p>
              </div>
            </div>
            <div class="row q-pl-sm">
              <q-icon name="corporate_fare" color="secondary" size="16px"/>
              <p class="q-pl-xs q-pa-none q-ma-none text-grey-8" style="font-size: x-small;">
                {{ props.jobOffer.organization.name }}</p>
              <q-icon name="public" color="secondary" class="q-pl-lg" size="16px"/>
              <p class="q-pl-xs q-pa-none q-ma-none text-grey-8" style="font-size: x-small;">
                {{ LocationTranslator.getCityDescriptions(props.jobOffer.locations) }}</p>
            </div>
          </div>
        </div>

      </div>
    </q-card-section>
  </q-card>
</template>

<script setup>
import {defineProps, ref, onMounted} from "vue";
import LocationTranslator from "@/services/LocationTranslator";

const props = defineProps({
  jobOffer: Object,
  isSelected: Boolean,
  borderColor: String,
  isFav: Boolean
})

onMounted(() => {
  isFav.value = props.isFav
})
import {useRouter} from 'vue-router'

const router = useRouter()

function openJobOffersInNewTab(jobId) {
  // Construct the URL for the JobOffersMainBoard route with the job ID
  const url = router.resolve({
    name: 'JobOffersMainBoard',
    params: {id: jobId},
  }).href;

  // Open the URL in a new tab
  window.open(url, '_blank');
}

function salaryToString() {
  if (props.jobOffer.salary !== null) {
    return props.jobOffer.salary.from.toString() + ' - ' + props.jobOffer.salary.to.toString() + ' ' + props.jobOffer.salary.currency
  } else {
    return 'BRAK STAWKI'
  }
}

function getSalaryColor() {
  if (salaryToString() === 'BRAK STAWKI') {
    return 'text-grey-8'
  } else {
    return 'text-light-blue'
  }
}

const isFav = ref(false)


</script>

<style lang="scss" scoped>

.my-border:after {
  position: absolute;
  left: 0%;
  top: 0%;
  content: '';
  border-radius: 9px;
  height: 100%;
  transition: all 0.3s linear;
  width: 100%;
  border-bottom: 2px solid $primary;
  transform: scaleX(0);
  bottom: -2px;
}

.my-border:hover:after {
  transform: scale(1);
}
</style>