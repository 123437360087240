<!--        TYTUL STANOWISKO DOSWIADCZENIE-->
<template>
  <q-form greedy @submit="nextStep()">
    <div class="row">
      <q-input class="q-pa-sm"
               color="primary"
               stack-label
               outlined
               v-model="offer.title"
               label="Tytuł ogłoszenia"
               style="width: 19rem"
               :rules="titleRules"
               dense>
        <template v-slot:append>
          <q-avatar>
            <q-icon
                name="sym_o_title"
                size="1rem"/>
          </q-avatar>
        </template>
      </q-input>
      <q-select class="col q-pa-sm" color="primary" stack-label outlined v-model="offer.jobPosition"
                :options="filteredPosition"
                :rules="jobPositionRules"
                label="Stanowisko"
                dense
                use-input
                @filter="(val, update) => filterAllPositions(val, update)"
      >
      </q-select>
      <q-select class="col-3 q-pa-sm" color="primary" stack-label outlined v-model="offer.seniority"
                :options="OfferDataTranslator.translateExperienceLevelsTypeToPolish()"
                dense
                :rules="seniorityRules"
                label="Poziom stanowiska">
      </q-select>
    </div>
    <div class="q-pt-1rem q-pa-sm">
      <q-field ref="fieldRef" v-model="offer.description" label-slot borderless dense
               :rules="[val => val.length < 4000|| 'Zbyt długi opis - max 4000 znaków.']">
        <my-text class="full-width">
          <q-editor
              class="full-width"
              v-model="offer.description"
              min-height="13rem"
              max-height="13rem"
              toolbar-color="primary"
              :toolbar="[
        ['bold', 'unordered'],
      ]"
              :style="fieldRef && fieldRef.hasError ? 'border-color: #C10015' : ''"/>
        </my-text>

      </q-field>
    </div>

    <contracts-section ref="contractsComponentRef"
                       v-model="offer.contracts"
                       :form-template-data="formTemplateData">
    </contracts-section>

    <questions-section ref="childComponentRef" v-model="offer.questions"
                       :form-template-data="formTemplateData"></questions-section>

    <skills ref="skillsRef" :form-template-data="formTemplateData" v-model="offer"></skills>
    <languages-section
        :form-template-data="formTemplateData"
        v-model="offer">
    </languages-section>
    <operating-mode :form-template-data="formTemplateData" v-model="offer"></operating-mode>
    <div class="q-pt-md q-pb-md q-pa-sm">
      <q-separator></q-separator>
    </div>
    <div class="q-pa-sm q-pt-sm full-width">
      <q-select
          dense
          color="primary"
          use-chips
          multiple
          v-model="offer.locations"
          :rules="[val => locationsValidationAccordingToPlan(val) || locationPlanRulesMsg(val)]"
          label="Lokalizacje biur"
          clear-icon="cancel"
          use-input
          :options="locationsOptions"
          outlined
          @update:model-value="val => handleModelValueUpdate(val)"
          @filter="(val, update) => filterAllLocations(val, update)"
      >
      </q-select>
      <div v-for="address in offer.addresses" :key="address.city">
        <div class="row q-gutter-md q-mb-sm">
          <q-chip color="primary" style="border-radius: 7px; height: 40px" outline :label="address.city"></q-chip>
          <q-input v-model="address.street" rounded label-color="grey-6" outlined dense
                   class="col bg-white text-grey-9"
                   :rules="[ val => !StringUtils.isStringEmpty(val) || 'Pole jest wymagane' ]"
                   label="Ulica">
          </q-input>
          <q-input v-model="address.streetNumber" rounded label-color="grey-6" outlined dense
                   class="col bg-white text-grey-9"
                   :rules="[ val => !StringUtils.isStringEmpty(val) || 'Pole jest wymagane' ]"
                   label="Numer budynku">
          </q-input>
          <q-input v-model="address.flatNumber" rounded label-color="grey-6" outlined dense
                   class="col bg-white text-grey-9"
                   :rules="[ val => !StringUtils.isStringEmpty(val) || 'Pole jest wymagane' ]"
                   label="Numer lokalu">
          </q-input>
        </div>
      </div>
    </div>
    <div v-if="showButtons" class="q-pt-md q-pb-md q-pa-sm">
      <q-separator></q-separator>
    </div>
    <div class="q-pa-xs q-pb-lg">
      <q-btn-toggle
          style="border-radius: 7px"
          v-model="offer.jobApplicationInfo.applicationType"
          spread
          no-caps
          toggle-color="primary"
          color="white"
          text-color="black"
          :options="[
          {label: 'Składanie CV bezpośrednio na stronie', value: 'INTERNAL'},
          {label: 'Składanie CV za pomocą zewnętrznego formularza', value: 'EXTERNAL'}
        ]"
      />
    </div>
    <q-input class="q-pa-xs q-pb-lg" color="primary" stack-label outlined
             v-if="offer.jobApplicationInfo.applicationType==='EXTERNAL'"
             v-model="offer.jobApplicationInfo.externalApplicationLink"
             :rules="[val => val !== null|| 'Podaj adres www zewnętrznego formularza']"
             label="URL do zewnętrznego formularza"
             dense>
      <template v-slot:append>
        <q-avatar>
          <q-icon
              name="sym_o_title"
              size="1rem"
          />
        </q-avatar>
      </template>
    </q-input>
    <q-stepper-navigation class="q-pa-sm q-pt-sm" v-if="showButtons">
      <q-btn type="submit" color="cyan-7" no-caps outline dense class="q-px-md" label="Dalej"/>
      <q-btn flat @click="previousStep" color="primary" no-caps dense label="Powrót" class="q-ml-md"/>
    </q-stepper-navigation>
  </q-form>
</template>

<script setup>
import {defineEmits, defineProps, computed, ref} from "vue"
import LanguagesSection from "@/components/common/add-offer/job-offer/language/LanguagesSection";
import OperatingMode from "@/components/common/add-offer/job-offer/operating-mode/OperatingModeSection";
import Skills from "@/components/common/add-offer/job-offer/skills/Skills";
import ContractsSection from "@/components/common/add-offer/job-offer/contracts/ContractsSection";
import OfferDataTranslator from "@/services/organization/offer/OfferDataTranslator";
import NotificationService from "@/services/notification/NotificationService";
import StringUtils from "@/services/common/StringUtils";

const props = defineProps({
  formTemplateData: Object,
  title: String,
  modelValue: Object,
  showButtons: Boolean
})

const offer = computed({
  get: () => props.modelValue,
  set: (val) => emit('update:modelValue', val),
});

const emit = defineEmits(['update:modelValue', 'step:next', 'step:previous'])

const filteredPosition = ref(null)

function jobPositionOptions() {
  let options = []
  props.formTemplateData.jobOffer.jobPositions.forEach(jobPosition => {
    options.push(JobPositionTranslator.translateConstant(jobPosition))
  })
  return options;
}

function locationsValidationAccordingToPlan(val) {
  if (offer.value.planType === 'BASIC') {
    return val.length > 0 && val.length <= 2;
  }
  if (offer.value.planType === 'STANDARD') {
    return val.length > 0 && val.length <= 7;
  }
  if (offer.value.planType === 'PREMIUM') {
    return val.length > 0 && val.length <= 16;
  }
}

function locationPlanRulesMsg() {
  if (offer.value.planType === 'BASIC') {
    return 'Wybrałeś plan Basic, możesz podać od jednej do dwóch lokalizacji'
  }
  if (offer.value.planType === 'STANDARD') {
    return 'Wybrałeś plan Standard, możesz podać od jednej do siedmiu lokalizacji'
  }
  if (offer.value.planType === 'PREMIUM') {
    return 'Wybrałeś plan Premium, możesz podać od jednej do szesnastu lokalizacji'
  }
}

const childComponentRef = ref(null)
const contractsComponentRef = ref(null)
import {useQuasar} from 'quasar'
import QuestionsSection from "@/components/common/add-offer/job-offer/questions/QuestionsSection";
import JobPositionTranslator from "@/services/offer/JobPositionTranslator";

const $q = useQuasar();

const titleRules = [
  val => {
    if (!val || val.length === 0) {
      showNotification('negative', 'Podaj tytuł');
      return 'Podaj tytuł ogłoszenia';
    }
    if (val.length > 40) {
      showNotification('negative', 'Podaj tytuł');
      return 'Please use maximum 40 characters';
    }
    return true;
  }
];

const jobPositionRules = [
  val => {
    if (!val || val.length === 0) {
      showNotification('negative', 'Wybierz stanowisko');
      return 'Podaj stanowisko';
    }
    if (val.length > 40) {
      showNotification('negative', 'Podaj tytuł');
      return 'Please use maximum 40 characters';
    }
    return true;
  }
];

const seniorityRules = [
  val => {
    if (!val || val.length === 0) {
      showNotification('negative', 'Wybierz poziom stanowiska');
      return 'Podaj stanowisko';
    }
    if (val.length > 40) {
      showNotification('negative', 'Podaj tytuł');
      return 'Please use maximum 40 characters';
    }
    return true;
  }
];

function showNotification(type, message) {
  $q.notify({
    type: type,
    message: message,
    timeout: 2000, // You can set the timeout as per your requirement
    position: 'bottom'
  });
}

async function nextStep() {

  const isLocationEmpty = await offer.value.locations.length < 1;
  if (isLocationEmpty) {
    NotificationService.showNotification($q, 'negative', 'Podaj przynajmniej jedną lokalizacje');
  }
  const skillsValid = await validateSkills();
  const operatingModesValid = await validateOperatingModes();
  const workTypes = await validateWorkTypes();
  const languageProficiencyValid = await validateLanguageProficiency();
  const contractsValid = await validateContracts()
  console.info('contract walidated 2 ' + contractsValid)
  ;
  const questionsValid = await validateQuestions()
  console.info("skills valiaiton: " + skillsValid)
  //TODO fix validations to use validate method from components?
  if (skillsValid && operatingModesValid && languageProficiencyValid && contractsValid && questionsValid && workTypes && !isLocationEmpty) {
    emit('step:next');
  } else {
    console.error('Validation failed. Cannot proceed to the next step.');
  }
}

function previousStep() {
  console.info(offer.value.questions)
  emit('step:previous')
}


async function validateSkills() {
  let skills = offer.value.skills;
  let validate = await skillsRef.value.validate();
  console.info("skills lenght validation: " + validate)
  if (skills.length > 0) {
    for (const skill of skills) {
      if (skill.level === null) {
        NotificationService.showNotification($q, 'negative', 'Podaj wymagany poziom umiejętności dla ' + skill.name);
        return false;
      }
    }

  } else {
    NotificationService.showNotification($q, 'negative', 'Podaj przynajmniej trzy umiejętności');
    return false
  }
  return true;
}

async function validateOperatingModes() {
  if (offer.value.operatingModes.length === 0) {
    NotificationService.showNotification($q, 'negative', 'Podaj przynajmniej jeden tryb pracy');
    return false;
  }
  return true;
}

async function validateWorkTypes() {
  if (offer.value.workTypes.length === 0) {
    NotificationService.showNotification($q, 'negative', 'Podaj przynajmniej jedeną formę zatrudnienia');
    return false;
  }
  return true;
}

async function validateLanguageProficiency() {
  let languages = offer.value.languages;
  for (const lang of languages) {
    if (lang.proficiency === null) {
      NotificationService.showNotification($q, 'negative', 'Podaj poziom znajomości języka dla: ' + lang.language);
      return false;
    }
  }
  return true;
}

async function validateContracts() {
  if (offer.value.contracts.length > 0) {
    let validateChildComponents = await contractsComponentRef.value.validateChildComponents();
    console.info('contract walidated ' + validateChildComponents)

    if (!validateChildComponents) {
      NotificationService.showNotification($q, 'negative', 'Popraw dane umowy');
    }
    return validateChildComponents
  } else {
    return true
  }
}

function validateQuestions() {
  if (offer.value.questions.length > 0) {
    return childComponentRef.value.validateChildComponents()
  } else {
    return true
  }
}


const skillsRef = ref(null)

function filterAllPositions(val, update) {
  let options = jobPositionOptions()
  update(() => {
    if (val === "") {
      filteredPosition.value = jobPositionOptions()
    } else {
      let needle = val.toLowerCase();
      filteredPosition.value = options.filter(
          v => v.toLowerCase().indexOf(needle) > -1
      );
    }
  });
}

const locationsOptions = ref([])

function filterAllLocations(val, update) {
  let options = OfferDataTranslator.translateCitiesToPolish()
  update(() => {
    if (val === "") {
      locationsOptions.value = options;
    } else {
      let needle = val.toLowerCase();
      locationsOptions.value = options.filter(
          v => v.toLowerCase().indexOf(needle) > -1
      );
    }
  });
}

function addCityToAddresses(city) {
  const cityExists = offer.value.addresses.some(address => address.city === city);
  if (!cityExists) {
    offer.value.addresses.push({city});
  }
}

function handleModelValueUpdate(val) {
  offer.value.locations = val;
  val.forEach(city => addCityToAddresses(city));
  offer.value.addresses.forEach(address => {
    if (!val.includes(address.city)) {
      removeCityFromAddresses(address.city);
    }
  });
  console.info(offer.value.addresses)
}

function removeCityFromAddresses(city) {
  offer.value.addresses = offer.value.addresses.filter(address => address.city !== city);
}
</script>
<style lang="sass" scoped>
@import "/src/assets/css/main.css"
.q-input
  font-family: "Verdana"
  font-size: 12px

.q-field-target
  font-family: "Verdana"
  font-size: 12px
  color: #581c9b

.q-select
  font-family: "Verdana"
  font-size: 12px

.q-editor
  font-family: "Verdana"
  font-size: 11px

.my-stepper
  width: 65%
</style>
