<template>
  <q-layout>
    <q-header class="bg-white">
      <div class="bg-white">
        <menu-bar class="q-pl-sm q-pr-sm"></menu-bar>
      </div>
    </q-header>
    <q-drawer
        v-model="drawerLeft"
        show-if-above
        @mouseover="miniStateLeft = false"
        @mouseout="miniStateLeft = true"
        :breakpoint="500"
        :width="200"
        bordered
        :class="$q.dark.isActive ? 'bg-white' : 'bg-white'"
    >
      <q-list class="column q-col-gutter-sm">
        <div class="q-mx-sm">
          <q-btn
              rounded
              @click="router.push({ name: 'OrganizationJobOffersBoard' });"
              dense
              flat
              style="border-radius: 6px"
              size="15px"
              icon="mdi-account-edit"
              align="left"
              class="q-mx-md full-width text-grey-9 bg-dark"
              color="primary"
              label="Mój profil"
              no-caps
          />
        </div>
        <div class="q-mx-sm">
          <q-btn
              rounded
              @click="OrganizationLogoutService.logout()"
              dense
              flat
              style="border-radius: 6px"
              size="15px"
              icon="mdi-logout"
              align="left"
              class="q-mx-md full-width text-white q-mt-lg"
              color="primary"
              label="Wyloguj"
              no-caps
          />
        </div>
      </q-list>
    </q-drawer>
    <q-page-container style="background-color: rgb(217,225,238);">
      <q-page class="column items-center q-scrollarea">
        <organization-account-data ref="organizationDataRef"
                        v-if="user.id !== null"
                        v-model="user" style="width: 65%; margin-top: 35px"
                        class="q-mx-lg q-mt-lg"></organization-account-data>
        <div class="col row" style="width: 65%; max-height: 60px">
          <q-card-section class="q-pl-none q-pb-none q-pt-none row justify-between full-width" style="max-height: 10px">
            <q-btn size="13px" color="primary"
                   icon="login"
                   style="max-height: 15px; border-radius: 6px"
                   @click="updateData()"
                   label="Zapisz zmiany"
                   no-caps/>
          </q-card-section>
        </div>
      </q-page>
    </q-page-container>
  </q-layout>
</template>


<script setup>
import {onBeforeMount, reactive, ref} from 'vue'
import MenuBar from "@/components/common/MenuBar";
import AuthenticationService from "@/services/AuthenticationService";

import {useRouter} from "vue-router/dist/vue-router";
const router = useRouter()

onBeforeMount(() => {
  initAuthenticatedUserData()
  initUserData()
})

function initUserData() {
  AuthenticationService.getAuthenticatedUser().then(data => {
    data.json().then(body => {
      user.firstName = body.firstName;
      user.id = body.id;
      user.email = body.email;
      user.lastName = body.lastName;
      user.phoneNumber = body.phoneNumber;
      user.position = body.position
    })
  });
}

function initAuthenticatedUserData() {
  AuthenticationService.getAuthenticatedUser()
      .then(response => {
        if (response.status === 200) {
          response.json().then((data) => {
            user.value = data
          })
        } else {
          throw new Error("Cannot get user profile data - user not authenticated")
        }
      });
}

const user = reactive({
  id: null,
  email: null,
  firstName: null,
  lastName: null,
  phoneNumber: null,
  position: null,
});

import NotificationService from "@/services/notification/NotificationService";
import {useQuasar} from "quasar";
import OrganizationAccountData from "@/components/organization/organization-accounts/profile/OrganizationAccountData";
import OrganizationUsersService from "@/services/organization/OrganizationUsersService";

const $q = useQuasar()

async function updateData() {
  let promise = await validateChildComponents();
  console.info(promise)
  if (promise) {
    OrganizationUsersService.updateUserData(user)
        .then(response => {
          if (response.ok) {
            NotificationService.showNotification($q, 'positive', 'Zaktualizowano dane profilu')
          }
        })
  }
}

const organizationDataRef = ref(null);

async function validateChildComponents() {
  return organizationDataRef.value.validate()
}

import OrganizationLogoutService from "@/services/organization/authorization/OrganizationLogoutService";
</script>
<style lang="scss">
.q-btn--dense .on-left {
  margin-right: 22px;
  margin-left: 8px;
}
</style>