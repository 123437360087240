<template>
  <div class="row justify-between q-pa-xs">
    <div class="q-px-sm text-center" v-for="contract in props.contracts" :key="contract.type">
      <div class="row q-ma-none">
          <span class="text-grey-9">{{
              OfferDataTranslator.translateContractTypeToPolish(contract.type)}}</span>
          <span class="q-pr-xs"></span>
          <span class="text-bold text-accent q-px-xs">{{ toSalaryRange(contract) }} </span>
          <span class="text-grey-9">&nbsp;{{ toCurrency(contract) }} </span>
      </div>
    </div>
    <div class="row" v-if="!doesContractsContain('B2B')">
      <p style="font-size: 8px" class="q-ma-none  text-grey-6 q-ml-md">
        <span class="text-grey-6 text">{{ 'B2b - niedostępna' }}</span></p>
    </div>
    <div class="row" v-if="!doesContractsContain('UOP')">
      <p style="font-size: 8px" class="q-ma-none  text-grey-6 q-ml-md">
        <span class="text-grey-6 text">{{ 'Umowa o pracę - niedostępna' }}</span></p>
    </div>
    <div class="row" v-if="!doesContractsContain('UZ')">
        <span class="text-grey-6 text">{{ 'Umowa zlecenie - niedostępna' }}</span>
    </div>
  </div>

</template>

<script setup>
import {defineProps} from 'vue';
import OfferDataTranslator from "@/services/organization/offer/OfferDataTranslator";

const props = defineProps({
  contracts: {
    type: Array,
    required: true
  }
});

function toCurrency(contract) {
  if (contract.salary !== null) {
    return contract.salary.currency + ' ';
  } else {
    return ''
  }
}

function toSalaryRange(contract) {
  if (contract.salary !== null) {
    return ' ' + contract.salary.from.toString() + ' - ' + contract.salary.to.toString()
  } else {
    return '- brak stawki'
  }
}

function doesContractsContain(type) {
  const typesArray = props.contracts.map(contract => contract.type);
  return typesArray.includes(type);
}
</script>

<style scope lang="scss">
my-text-contract {
  color: $primary;
}
span{
  font-size: 11px;
}
</style>
