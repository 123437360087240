<template>
  <q-layout>
    <q-page-container>
      <q-page style="background-color: rgb(217,225,238);">
        <menu-bar class="q-pl-sm q-pr-sm"></menu-bar>
        <div class="login-center" v-if="successfulRegistration">
          <q-card style="width: 350px; border-radius: 9px">
            <q-card-section class="row justify-center q-pb-none">
              <div class="q-pt-none q-pb-sm">
                <q-spinner-hourglass
                    color="primary"
                    size="5em"
                    :thickness="1"/>
              </div>
              <div class="text-center text-h7 text-secondary q-pb-sm">Potwierdź rejestrację i ustaw hasło za pomocą
                maila
                powitającego
              </div>
            </q-card-section>
            <q-card-section class="text-center">
              <q-btn outline no-caps padding="3px xl" type="submit" color="primary" label="Ok"
                     @click="toOrganizationLoginPage"></q-btn>
            </q-card-section>
          </q-card>
        </div>
        <div class="login-center" v-else style="min-width: 350px">
          <q-card style="border-radius: 9px">
            <q-card-section class="row justify-center q-pa-lg">
              <my-text class="text-center text-primary q-mb-none" color="white"
                       style="font-weight: 500; font-size: 18px">
                Szukaj pracowników z ByteJob
                <!--          <q-icon class="q-ml-sm" name="fa-solid fa-people-group" size="23px"></q-icon>-->
              </my-text>
            </q-card-section>
            <q-form class="q-mx-sm" @submit.prevent="submitForm" greedy>
              <q-card-section class="q-pb-sm">
                <q-input
                    dense
                    outlined
                    v-model="organizationName"
                    label="Nazwa firmy"
                    id="companyName"
                    :rules="[val => !!val || 'Nazwa jest wymagana']"
                ></q-input>
                <q-input
                    dense
                    outlined
                    v-model="email"
                    label="E-mail"
                    id="email"
                    :rules="[val => !!val || 'E-mail jest wymagany']"
                ></q-input>
                <div class="text-center q-pt-none q-pb-none"> <!-- Add the text-center class to this div -->
                  <q-btn size="13px" flat color="accent" @click="router.push({ name: 'OrganizationLoginPage' });"
                         icon="fa-solid fa-arrow-right-to-bracket"
                         label="Masz konto? - Zaloguj się"
                         no-caps/>
                </div>
              </q-card-section>
              <q-card-actions class="text-center q-pl-md q-pr-md q-pb-md" align="center">
                <q-btn no-caps padding="3px xl"
                       type="submit"
                       style="border-radius: 7px"
                       color="primary"
                       label="Dołącz"
                       :loading="loading"></q-btn>
              </q-card-actions>
            </q-form>
          </q-card>
        </div>
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<script setup>
import RegistrationService from '@/services/RegistrationService';
import MenuBar from "@/components/common/MenuBar";
import {ref} from 'vue'

import {useQuasar} from 'quasar'
import {useRouter} from "vue-router";

const $q = useQuasar();
const router = useRouter()
const organizationName = ref('')
const email = ref('')
const successfulRegistration = ref(false)

const ERROR_CODES = require('/src/services/ErrorCodes.js');

async function submitForm() {
  const response = await RegistrationService.registerOrganization(organizationName.value, email.value);
  if (response.status == 201) {
    successfulRegistration.value = true;
  } else if (response.status === 400) {
    const json = await response.json()
    if (json.message.includes('Validation failed on fields: [email].')) {
      $q.notify({
        type: 'negative',
        message: 'Konto o podanym adresie e-mail jest już zarejestrowane w systemie.'
      })
    } else if (json.message.includes('email=must be a well-formed email address')) {
      $q.notify({
        type: 'negative',
        message: 'Niepoprawny format adresu e-mail'
      })
    } else if (json.message.includes('Organization with that NIP already exists')) {
      $q.notify({
        type: 'negative',
        message: 'Firma o podanym numerze NIP jest już zarejestrowana w systemie.'
      })
    } else if (json.errorCode.includes(ERROR_CODES.EMAIL_ALREADY_TAKEN)) {
      $q.notify({
        type: 'negative',
        message: 'Podany adres e-mail jest już przypisany do konta - zaloguj się lub zresetuj hasło'
      })
    }
  } else {
    $q.notify({
      type: 'negative',
      message: 'Błąd podczas logowania - skontaktuj się z supportem.'
    })
  }
}

function toOrganizationLoginPage() {
  router.push('/organization-login-page')
}
</script>
<style>
.login-center {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -30%);
}

.q-field__bottom--animated {
  padding-top: 1px;
}
</style>