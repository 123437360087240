<template>
  <div class="row full-width">
    <q-card flat class="q-mx-sm q-mt-lg full-width" style="border-radius: 9px;background-color: rgba(229,237,246,0.82);">
      <q-card-section>
        <my-text class="text-white">
          <span class="text-weight-bold text-primary" style="font-size: 15px;">Języki</span>
          <span class="text-grey-8"> - Dodaj przynajmniej jeden język - pozwoli to określić charakterystyke komunikacji w projekcie.
        </span>
        </my-text>
      </q-card-section>
    </q-card>
    <div class="q-pt-md full-width q-mt-md">
      <q-select
          color="primary"
          use-chips
          multiple
          v-model="stringModelLangValues"
          label="Języki (opcjonalne)"
          dense
          clear-icon="cancel"
          :options="OfferDataTranslator.translateLanguagesToPolish()"
          outlined
          @add="addLanguage"
          @remove="removeLanguage"
      >
      </q-select>
    </div>
    <div class="q-mx-xs row q-mt-lg" v-if="offer.languages.length !== 0">
      <language v-for="lang in offer.languages" :key="lang.language"
                :language="lang.language"
                v-model:proficiency="lang.proficiency"
                :form-template-data="formTemplateData"
                v-model="offer">
      </language>
    </div>
  </div>
  <div class="q-my-md full-width">
    <q-separator></q-separator>
  </div>
</template>

<script setup>
import {ref, defineProps, defineEmits, computed, onBeforeMount} from 'vue'
import Language from "@/components/common/add-offer/job-offer/language/Language";
import OfferDataTranslator from "@/services/organization/offer/OfferDataTranslator";

const props = defineProps({
  formTemplateData: Object,
  modelValue: Object
})

const offer = computed({
  get: () => props.modelValue,
  set: (val) => emit('update:modelValue', val),
});

const stringModelLangValues = ref([]);

onBeforeMount(() => {
  stringModelLangValues.value = initModelTechValues()
})

function initModelTechValues() {
  let modelValues = []
  offer.value.languages.forEach(language => {
    modelValues.push(language.language)
  })
  return modelValues
}


const emit = defineEmits(['sendLanguages'])

function addLanguage(details) {
  offer.value.languages.push({language: details.value, proficiency: null})
}

function removeLanguage(key) {
  let index = offer.value.languages.findIndex(item => item.language === key.value)
  if (index > -1) { // only splice array when item is found
    offer.value.languages.splice(index, 1); // 2nd parameter means remove one item only
  }
}
</script>