<template>
  <application-answers v-model:show-dialog="showAnswers" v-model:model-value="application"></application-answers>
  <q-dialog v-model="showCv" class="row custom-dialog-wrapper" persistent>
    <div class="row no-wrap custom-dialog full-height custom-dialog-content q-pb-none" style="max-width: 1150px">
      <!-- First q-card -->
      <div class="q-mr-md">
        <q-card class="full-height scrollable-area" style=" border-radius: 9px">
          <vue-pdf-embed :source="srcData" style="width: 700px;"/>
        </q-card>
      </div>
      <div class="col row q-pt-none justify-end">
        <div class="col row column">
          <q-card class="col row" style="border-radius: 9px">
            <q-card-section class="column col q-pa-md">
              <div class="col-1 row" style="margin:0; border-radius: 7px">
                <q-chip style="border-radius: 5px" class="full-width q-my-none text-white" size="20px" square
                        color="primary" text-color="white">
                  Notatki - {{ application.applicantName }}
                </q-chip>
              </div>
              <!--           //TODO add editor validation -->
              <div class="col row" style="width: 100%; height: 100%">
                <q-editor
                    v-model="editor"
                    style="overflow-y: auto; height: 100%"
                    max-height="auto"
                    min-height="100vh"
                    class="full-height full-width q-scrollarea"
                    toolbar-color="primary"
                    :toolbar="[
            ['bold', 'unordered', 'undo', 'redo'],
          ]"
                    :style="fieldRef && fieldRef.hasError ? 'border-color: #C10015' : ''"/>
              </div>

              <div class="q-pt-md">
                <q-btn style="border-radius: 5px" class="full-width q-px-none q-mx-none" color="primary"
                       label="Zamknij podgląd" size="14px"
                       @click="closeNotes()"/>
              </div>
            </q-card-section>


          </q-card>
        </div>
      </div>
    </div>
  </q-dialog>

  <div class="q-pt-sm q-px-sm q-pb-sm">
    <q-card class="my-card q-hoverable cursor-pointer" style="border-radius: 9px">
      <span class="q-focus-helper"></span>

      <q-card-section class="q-pa-sm">
        <div class="row justify-between">
          <div class="q-pa-none q-pl-xs">
            <div class="row q-pl-none">
              <q-checkbox color="primary" size="28px" dense v-model="application.checked" @click="emitCheckUpdate"/>
              <div class="q-pl-sm">
                <my-text class="text-grey-9" style="font-size: 14px">{{ application.applicantName }}</my-text>
              </div>
            </div>
          </div>
          <div class="q-pb-none q-gutter-sm">
            <q-checkbox
                size="sm"
                dense
                color="positive"
                v-model="isAccepted"
                checked-icon="o_thumb_up"
                unchecked-icon="o_thumb_up"
                @click="patchRating('ACCEPTED')"
            />
            <q-checkbox
                size="sm"
                dense
                color="orange"
                v-model="isMarked"
                checked-icon="o_live_help"
                unchecked-icon="o_live_help"
                @click="patchRating('UNDECIDED')"

            />
            <q-checkbox
                size="sm"
                dense
                class="q-pr-sm"
                color="warning"
                v-model="isRejected"
                checked-icon="o_thumb_down"
                unchecked-icon="o_thumb_down"
                @click="patchRating('REJECTED')"
            />
          </div>
        </div>
      </q-card-section>
      <q-card-section class="q-pa-none q-pl-sm">
        <div class="row justify-between full-width">
          <div class="q-pt-sm">
            <q-icon name="schedule_send"></q-icon>
            <my-text class="q-pl-xs" style="font-size: 11px">{{ props.modelValue.appliedAt }}</my-text>
          </div>
          <div class="q-mb-sm q-mr-sm">
            <q-btn flat class="q-pr-sm" :href="emailClientValue" size="10px" outline color="blue-8"
                   :label="application.applicantEmail" no-caps style="border-radius: 5px">
            </q-btn>
          </div>
        </div>
      </q-card-section>
      <q-separator class="q-mb-xs"/>
      <q-card-actions>
        <div class="row justify-between full-width">
          <div class="q-gutter-sm justify-between">
            <q-btn class="q-mt-xs bg-grey-3" flat square color="primary" label="Podgląd" no-caps size="9px"
                   style="border-radius: 5px"
                   @click="fetchByteArray"/>
            <q-btn class="q-mt-xs bg-grey-3" flat color="primary" label="Pobierz" no-caps size="9px" @click="downloadCV"
                   style="border-radius: 5px"/>
            <q-btn class="q-mt-xs bg-grey-3" flat v-if="application.answers.length !== 0" color="primary" no-caps
                   label="Odpowiedzi"
                   size="9px"
                   @click="showAnswersDialog()" style="border-radius: 5px"
            />
            <q-btn flat v-else disable color="grey-9" label="Odpowiedzi" no-caps size="9px"
                   @click="showAnswersDialog()" style="border-radius: 6px"
            />
          </div>
          <!--          <div>-->
          <!--            <q-btn size="7px" dense icon="sym_o_phone" disable outline color="primary" label="Nieumówione">-->
          <!--            </q-btn>-->
          <!--          </div>-->
        </div>
      </q-card-actions>
    </q-card>
  </div>
</template>

<script setup>
import {computed, defineProps, ref, defineEmits, onMounted} from 'vue'
import VuePdfEmbed from 'vue-pdf-embed'
import organizationApplicationService from '@/services/OrganizationApplicationService.js';
import ApplicationAnswers from "@/components/organization/applications/ApplicationAnswers";

const emailClientValue = ref(null)
const srcData = ref(null)
const showCv = ref(false)
const showAnswers = ref(false)

//RATING
const isAccepted = ref(false);
const isMarked = ref(false);
const isRejected = ref(false);
const props = defineProps({
  modelValue: Object,
  id: String,
})

const emit = defineEmits(['modelValue', 'accepted'])

const application = computed({
  get: () => props.modelValue,
  set: (val) => emit('update:modelValue', val),
});

onMounted(() => {
  let rating = application.value.result
  emailClientValue.value = "mailto:" + application.value.applicantEmail + "?subject=Me&body="
  if (rating === 'ACCEPTED') {
    isAccepted.value = true;
  } else if (rating === 'UNDECIDED') {
    setRatingValuesToFalse();
    isMarked.value = true
  } else {
    isRejected.value = true
  }
})

function setRatingValuesToFalse() {
  isAccepted.value = false
  isMarked.value = false
  isRejected.value = false
}


const editor = ref(application.value.notes)

function emitCheckUpdate() {
  emit("checkUpdate")
}

function downloadCV() {
  organizationApplicationService.downloadCV(application.value.id)
}

async function fetchByteArray() {
  const response = await organizationApplicationService.getCV(application.value.id)
  const data = await response.json();
  const base64Data = data.file;
  const byteArray = Uint8Array.from(atob(base64Data), c => c.charCodeAt(0));
  const blob = new Blob([byteArray], {type: 'application/pdf'});
  srcData.value = URL.createObjectURL(blob);
  showCv.value = true;
}

async function closeNotes() {
  await organizationApplicationService.patchApplicationNotes(application.value.id, editor.value)
  showCv.value = false
}

async function patchRating(rating) {
  setRatingValuesToFalse()
  if (rating === 'ACCEPTED') {
    isAccepted.value = true;
  } else if (rating === 'UNDECIDED') {
    isMarked.value = true
  } else {
    isRejected.value = true
  }
  await organizationApplicationService.patchApplicationRating(application.value.id, rating)
}

function showAnswersDialog() {
  showAnswers.value = true;
}
</script>

<style lang="scss" scoped>

.custom-dialog {
  width: 100%;
  max-width: 1500px;
  max-height: 800px; // Add this line
}


.custom-dialog-wrapper {
  width: 100%;
  padding: 8px; // Adjust this value to control the padding around the q-dialog
}

.custom-card {
  width: calc(76% - 8px);
  max-width: 704px;
  padding: 4px;
  overflow-y: auto; // Add this line
}


.custom-dialog-content {
  margin-left: 0;
  margin-top: 0;
}

.custom-card-second {
  flex-grow: 1;
  padding: 4px;
  max-width: 607px;
  overflow-y: auto; // Add this line
}

.custom-q-editor {
  height: 100%;

  & > .q-editor__content {
    height: 100%;
  }
}


.scrollable-area {
  height: 500px;
  overflow-y: auto;
}
</style>