import PromoCodeWrongTypeError from './PromoCodeWrongTypeError';

class JobOfferPaymentsService {
    constructor(url) {
        this.url = url;
    }

    async getPromoCode(code) {
        const endpoint = '/api/v1/promo-codes/' + code;
        try {
            const response = await this._sendGetRequest(endpoint);
            return await response;
        } catch (error) {
            console.error('Error retrieving meetings:', error);
            throw error;
        }
    }

    async _sendGetRequest(endpoint) {
        try {
            const requestOptions = {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            };
            const response = await fetch(this.url + endpoint, requestOptions);
            return response;
        } catch (error) {
            console.error('Error during request:', error);
            throw error;
        }
    }


    async evaluatePlanPrice(code, planType) {
        try {
            return this.getPromoCode(code).then(response => {
                if (response.status === 404) {
                    throw Error("Niepoprawny kod promocyjny")
                } else {
                    return response.json().then(body => {
                        return this.findAmountByPlanType(body, planType)
                    })
                }
            })
        } catch (e) {
            console.warn(e)
            throw e;
        }
    }

findAmountByPlanType(promotionData, planType) {
        const lowercasePlanType = planType.toLowerCase();
        const supportedPlanTypesLowercase = promotionData.supportedPlanTypes.map(type => type.toLowerCase());
        const index = supportedPlanTypesLowercase.indexOf(lowercasePlanType);
        if (index !== -1) {
            let amount = promotionData.newPrices[promotionData.supportedPlanTypes[index]].amount;
            return amount;
        } else {
            throw new PromoCodeWrongTypeError(promotionData.supportedPlanTypes);
        }
    }

}

export default new JobOfferPaymentsService(process.env.VUE_APP_BACKEND_URL);
