class LocationTranslator {

    constructor() {
    }

    getCityDescriptions(locations) {
        let citiesArray = locations.map(location => {
            return location.city
        });
        return citiesArray.join(", ")
    }

    getCityArray(locations) {
        let citiesArray = locations.map(location => {
            return location.city
        });
        return citiesArray
    }

}

export default new LocationTranslator();
