<template>
  <q-layout>
    <q-page-container>
      <q-page style="background-color: rgb(217,225,238);">
  <mobile-menu v-if="isMobile()"></mobile-menu>
  <menu-bar v-else></menu-bar>  <div class="login-center" v-if="successfulRegistration">
    <q-card style="width: 350px; border-radius: 9px">
      <q-card-section class="row justify-center q-pb-none">
        <div class="q-pt-none q-pb-sm">
          <q-spinner-hourglass
              color="secondary"
              size="5em"
              :thickness="1"/>
        </div>
        <div class="text-center text-h7 text-secondary q-pb-sm">Potwierdź rejestrację i ustaw hasło za pomocą maila
          powitającego
        </div>
      </q-card-section>
      <q-card-section class="text-center">
        <q-btn no-caps padding="3px xl" type="submit" color="primary" label="Ok"
               @click="toApplicantLoginPage()"></q-btn>
      </q-card-section>
    </q-card>
  </div>
  <div v-else class="login-center">
    <q-card style="width: 350px; border-radius: 9px;">
      <q-card-section class="row justify-center q-pb-sm">
        <div class="text-center text-h6 text-primary">Znajdź pracę z ByteJob</div>
      </q-card-section>
      <q-form @submit.prevent="submitForm" greedy>
        <q-card-section class="q-pb-xs">
          <q-input
              dense
              outlined
              v-model="email"
              label="E-mail"
              id="email"
              :rules="[val => !!val || 'E-mail jest wymagany']"
          ></q-input>
          <div class="text-center q-pt-none q-pb-none"> <!-- Add the text-center class to this div -->
            <q-btn size="13px" flat color="primary" @click="router.push({ name: 'ApplicantLoginPage' });" icon="login"
                   label="Masz konto? - Zaloguj się"
                   no-caps/>
          </div>
        </q-card-section>
        <q-card-actions class="text-center q-pl-md q-pr-md q-pb-md" align="center">
          <q-btn style="border-radius: 9px" no-caps rounded padding="3px xl" type="submit" color="primary" label="Dołącz"
                 @click="register()"></q-btn>
        </q-card-actions>
      </q-form>
    </q-card>
  </div>
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<script setup>
import MenuBar from "@/components/common/MenuBar";
import {ref} from 'vue';
import {useRouter} from "vue-router/dist/vue-router";
import ApplicantRegistrationService from "@/services/applicant/ApplicantRegistrationService";

const email = ref('')

import {useQuasar} from 'quasar'
import MobileMenu from "@/components/mobile/MobileMenu";

const $q = useQuasar();
const successfulRegistration = ref(false)
const router = useRouter()

const APPLICANTS_ERROR_CODES = require('/src/services/ErrorCodes.js');

async function register() {
  const response = await ApplicantRegistrationService.registerApplicant(email.value);
  if (response.status == 201) {
    successfulRegistration.value = true;
  } else if (response.status === 400) {
    const json = await response.json()
    if (json.message.includes('Validation failed on fields: [email].')) {
      $q.notify({
        type: 'negative',
        message: 'Konto o podanym adresie e-mail jest już zarejestrowane w systemie.'
      })
    } else if (json.message.includes('email=must be a well-formed email address')) {
      $q.notify({
        type: 'negative',
        message: 'Niepoprawny format adresu e-mail'
      })
    } else if (json.errorCode.includes(APPLICANTS_ERROR_CODES.EMAIL_ALREADY_TAKEN)) {
      $q.notify({
        type: 'negative',
        message: 'Konto o podanym adresie e-mail już istnieje, zaloguj się lub zresetuj hasło'
      })
    }
  } else {
    $q.notify({
      type: 'negative',
      message: 'Błąd podczas logowania - skontaktuj się z supportem.'
    })
  }
}

function toApplicantLoginPage() {
  router.push('/employee-login-page')
}

function isMobile() {
  return $q.platform.is.mobile
}
</script>
<style>
.login-center {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -30%);
}
</style>