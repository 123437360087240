<template>
  <q-card v-if="isLoggedUser !== null"
          style="background-color: #fdfdfd; border-radius: 9px">
    <q-card-section class="q-pa-lg">
      <div class="q-pb-sm">
        <my-text class="text-primary text-weight-medium" type="submit" style="font-size: 16px;">Aplikuj na stanowisko {{
            position
          }}
        </my-text>
      </div>
      <div class="q-pb-md">
        <q-separator></q-separator>
      </div>
      <q-form @submit.prevent="handleSubmit()" greedy>
        <div class="row q-gutter-md">
          <div class="col">
            <q-chip class="q-ml-none" style="font-size: 13px" dense color="white" text-color="grey-8">
              <my-text>Imię i nazwisko</my-text>
              <p class="text-red-8 q-ma-none " style="font-size: 13px">*</p>
            </q-chip>
            <q-input v-if="isLoggedUser && applicantFirstName !== null && applicantLastName !==null"
                     class="q-pt-none bg-white" color="priamry" dense outlined
                     v-model="applicantName"
                     label="pole wymagane"
                     label-color="grey-5" disable/>
            <q-input v-else
                     class="q-pt-none bg-white"
                     color="primary"
                     dense
                     outlined
                     v-model="applicantName"
                     label="pole wymagane"
                     :rules="[val => !isBlank(val) || 'Podaj imię i nazwisko']"
                     label-color="grey-5"/>
          </div>
          <div class="col">
            <q-chip class="q-ml-none" style="font-size: 13px" dense color="white" text-color="grey-8">
              <my-text>E-mail</my-text>
              <p class="text-red-8 q-ma-none " style="font-size: 13px">*</p>
            </q-chip>
            <q-input v-if="isLoggedUser" class="q-pt-none bg-white" dense color="primary"
                     outlined v-model="email" disable type="email"
                     :rules="[val => EmailValidator.isEmailValid(val) || 'niepoprawny format',val => !isBlank(val) || 'Podaj e-mail']"
                     label="pole wymagane" label-color="grey-5"/>
            <q-input v-else
                     class="q-pt-none bg-white"
                     dense
                     color="primary"
                     outlined v-model="email"
                     :rules="[val => EmailValidator.isEmailValid(val) || 'niepoprawny format',val => !isBlank(val) || 'Podaj e-mail']"
                     label="pole wymagane"
                     label-color="grey-5"/>
          </div>
        </div>
        <div class="q-pt-none">

          <!--            TODO dodaj cv w petli + tooltipa-->
          <div class="q-px-none q-pt-none q-pb-md">
            <div class="row justify-start q-mb-sm">
              <div v-if="loggedUserResumes !== null && loggedUserResumes.length === 0 && isLoggedUser"
                   class="q-ml-none">
                <div class="q-pl-none q-ml-none q-mr-none q-pr-none" style="font-size: 13px" dense color="white"
                     text-color="grey-8">
                  <div class="q-ma-none">
                    <q-btn outline @click="router.push({ name: 'ApplicantProfilePage' });" no-caps
                           class="q-ma-none q-px-sm q-mr-sm q-mt-xs"
                           style="border-radius: 5px" label="Dodaj Cv do swojego profilu" color="accent"
                           size="xs"></q-btn>
                    <q-btn round color="accent" size="xs" dense>
                      <q-icon size="12px" name="fa-solid fa-info"></q-icon>
                      <q-tooltip max-width="200px" anchor="top middle" self="bottom middle" :offset="[10, 10]"
                                 class="bg-white text-grey-9">
                        Dodaj Cv do swojego profilu - będziesz mógł go automatyczie użyć podczas aplikowania.
                      </q-tooltip>
                    </q-btn>
                  </div>
                  <div class="q-pt-none col q-pt-xs" v-if="loggedUserResumes.length === 0">
                    <my-text class="text-grey-8" style="font-size: 10px">
                      PDF. Rozmiar max 5 MB
                    </my-text>
                  </div>
                </div>
              </div>
              <div v-else-if="loggedUserResumes !== null && loggedUserResumes.length !== 0 && isLoggedUser"
                   class="q-gutter-sm q-ml-none q-ma-none">
                <span class="q-ml-none row q-pt-none q-ml-xs q-mt-none">Wybierz Cv<p class="text-red-8 q-ma-none "
                                                                                     style="font-size: 13px">*</p></span>
                <div class="q-ma-none row q-gutter-sm" v-if="loggedUserResumes.length !== 0">
                  <q-select class="q-ma-none q-pa-none q-pt-sm" dense outline style="border-radius: 5px"
                            :options="toResumesFileNames()"
                            outlined
                            :model-value="fileName"
                            color="primary" size="xs"
                            @update:model-value="val => setResume(val)"></q-select>
                </div>
              </div>
            </div>
            <div v-if="!isLoggedUser">
               <span class="q-ml-none row q-pt-none q-ml-xs q-mt-none">Dodaj Cv<p class="text-red-8 q-ma-none "
                                                                                  style="font-size: 13px">*</p>
               </span>
              <q-card
                  v-ripple
                  class="my-box cursor-pointer q-hoverable"
                  style="border-radius: 9px"
                  @mouseover="handleMouseOver"
                  @mouseout="handleMouseOut"
                  @click="handleFileInput()">
                <div v-if="isBlank(uploadedFile)">
                  <q-card-section v-if="isMouseOnCard" class="text-center">
                    <q-icon name="post_add" size="20px" class="text-primary q-px-none"></q-icon>
                  </q-card-section>
                  <q-card-section v-else class="text-center">
                    <q-icon name="post_add" size="20px" class="text-grey-7 q-pa-none"></q-icon>
                  </q-card-section>
                </div>
                <div v-else>
                  <q-card-section class="text-center">
                    <q-icon name="task" size="20px" class="text-primary q-px-none"></q-icon>
                    <p class="q-pb-none q-mb-none text-grey-8">{{ fileName }}</p>
                  </q-card-section>
                </div>

                <span class="q-focus-helper">
        </span>
              </q-card>
            </div>
          </div>
        </div>
        <q-space/>
        <q-card-section class="q-pa-none q-mb-md" v-if="questions.length !== 0">
          <q-separator class="q-mb-sm"></q-separator>
          <questions ref="questionsRef" v-model="answersWrapper.answers">
          </questions>
        </q-card-section>
        <q-separator class="q-mb-md q-mt-sm" v-if="questions.length !== 0"></q-separator>
        <div>
          <div class="row">
            <q-toggle
                v-model="shouldContactInSpecificHours"
                icon="alarm"
                size="sm"
                style="font-size: 13px"
                class="text-grey-8 q-mt-none"
                label="Preferowany kontakt w wybranych godzinach"
            />
            <div style="margin-top: 5px" class="q-ml-sm">
              <q-badge v-if="shouldContactInSpecificHours" color="primary">
                od {{ contactInSpecificHour.min }}:00 do {{ contactInSpecificHour.max }}:00
              </q-badge>
            </div>
          </div>
          <div class="q-px-sm" v-if="shouldContactInSpecificHours">
            <q-range
                v-model="contactInSpecificHour"
                :min="7"
                :max="18"
                :step="1"
            />
          </div>
          <div class="q-mt-lg">
            <!--        TODO-->
            <q-checkbox color="primary" dense v-model="dataAgreement" style="font-size: 12px"
                        label="Wyrażam zgodę na przetwarzanie moich danych osobowych dla celów przyszłych rekrutacji"
                        class="text-grey-8"/>
          </div>
          <div class="full-width row q-mt-lg q-gutter-md">
            <q-btn flat style="width: 20vh; border-radius: 6px" size="md" icon="send" align="center" label="Aplikuj"
                   type="submit"
                   class="col bg-accent-3 text-accent"/>
            <q-btn flat style="width: 20vh; border-radius: 6px" size="md" icon="mdi-account-edit-outline" align="center"
                   label="Zmień dane"
                   class="col bg-accent-3 text-primary" @click="router.push({ name: 'ApplicantProfilePage' });"/>
          </div>
        </div>
      </q-form>
    </q-card-section>
  </q-card>
</template>

<script setup>
import {defineEmits, defineProps, onBeforeMount, reactive, ref} from 'vue';
import ApplicationService from "@/services/applicant/ApplicationService";
import AuthenticationService from "@/services/AuthenticationService";
import {useRouter} from "vue-router/dist/vue-router";
import Questions from "@/components/common/job-offers/applications/Questions";
import EmailValidator from "@/services/common/EmailValidator";
import {useQuasar} from "quasar";
import NotificationService from "@/services/notification/NotificationService";
import ApplicantDataService from "@/services/applicant/ApplicantDataService";
import {currentUser} from '@/global-variables/currentUser.js';

const router = useRouter()

const shouldContactInSpecificHours = ref(false)
const contactInSpecificHour = ref({
  min: 10,
  max: 15
});
const props = defineProps({
  jobOfferId: String,
  position: String,
  questions: Array
});
const applicantName = ref('');
const applicantFirstName = ref(null);
const applicantLastName = ref(null);
const email = ref('');
const uploadedFile = ref('');
const isLoggedUser = ref(null)
const fileName = ref('');
const emit = defineEmits(['close-modal']);
const dataAgreement = ref(false)
const loggedUserResumes = ref(null)
const chooseResumeIdForLogged = ref(null)
const phoneNumber = ref(null)
const answersWrapper = reactive({answers: []})


onBeforeMount(() => {
  initAnswersObject()
  if (currentUser.isAuthenticated()) {
    if (currentUser.firstName !== null) {
      applicantFirstName.value = currentUser.firstName
      applicantName.value = currentUser.firstName
    }
    if (currentUser.lastName !== null) {
      applicantLastName.value = currentUser.lastName
      applicantName.value = applicantName.value + ' ' + currentUser.lastName;
    }

    email.value = currentUser.email
    phoneNumber.value = currentUser.phoneNumber
    isLoggedUser.value = true
    if (currentUser.preferredContactTime !== null) {
      contactInSpecificHour.value.min = hourToInt(currentUser.preferredContactTime.from);
      contactInSpecificHour.value.max = hourToInt(currentUser.preferredContactTime.to);
    }

    ApplicationService.getResumes().then(response => {
      response.json().then(body => {
        loggedUserResumes.value = body
        const defaultResume = body.find(resume => resume.defaultResume);
        chooseResumeIdForLogged.value = defaultResume.id;
        fileName.value = defaultResume.fileName;
        uploadedFile.value = defaultResume.file;
      });
    });
  } else {
    isLoggedUser.value = false
    loggedUserResumes.value = []
  }

})

function initAnswersObject() {
  props.questions.forEach(q => {
    const answerObj = {
      questionId: q.id,
      question: q.question,
      answer: '',
      required: q.required,
      type: q.answerType
    };
    answersWrapper.answers.push(answerObj);
  });
}

function hourToInt(hour) {
  return parseInt(hour, 10);
}

async function handleSubmit() {
  let isValid = await validateQuestions()
  if (isValid && isCvAttached() && isAgreementChecked()) {
    if (await AuthenticationService.isAuthenticatedApplicantUser()) {
      await applyAsLoggedUser()
    } else {
      console.info("anonymous user application")
      await applyAsAnonymousUser()
    }
  }
}

async function applyAsLoggedUser() {
  let preferredContactTime = contactInSpecificHour.value.min + ':00-' + contactInSpecificHour.value.max + ':00';
  let firstName = applicantName.value.split(' ')[0]
  let lastName = applicantName.value.split(' ')[0]
  await ApplicantDataService.updateData(localStorage.getItem('userId'), firstName, lastName, phoneNumber.value, contactInSpecificHour.value.min + ':00', contactInSpecificHour.value.max + ':00')
  ApplicationService.getResumes().then(response => {
    response.json().then(body => {
      console.info(body)
      ApplicationService.applyAsLoggedUser(chooseResumeIdForLogged.value, preferredContactTime, props.jobOfferId, answersWrapper.answers)
          .then(() => {
            emit('close-modal')
          })
    })
  })
}

async function applyAsAnonymousUser() {
  let preferredContactTime = contactInSpecificHour.value.min + ':00-' + contactInSpecificHour.value.max + ':00';
  ApplicationService.applyAsAnonymousUser(uploadedFile.value, email.value, applicantName.value, preferredContactTime, props.jobOfferId, JSON.stringify({answers: answersWrapper.answers}))
      .then(response => {
        if (response.ok) {
          emit('close-modal')
        } else {
          response.json().then(body => {
            NotificationService.showNotification($q, "negative", body.title)
          })
        }
      })
}

const $q = useQuasar()

function isCvAttached() {
  if (isBlank(uploadedFile.value)) {
    NotificationService.showNotification($q, 'negative', 'Proszę wgrać swoje CV')
    return false;
  }
  return true;
}

function isAgreementChecked() {
  if (!dataAgreement.value) {
    NotificationService.showNotification($q, 'negative', 'Zaznacz zgodę na przetwarzanie informacji')
    return false;
  }
  return true;
}

function handleFileInput() {
  let input = document.createElement('input');
  input.type = 'file';
  input.accept = '.pdf';

  input.onchange = () => {
    let files = Array.from(input.files);
    let file = files[0];

    if (file.size > 5 * 1024 * 1024) { // 5MB in bytes
      NotificationService.showNotification($q, 'negative', 'Maksymalny rozmiar pliku - 5 MB.');
      return;
    } else {
      uploadedFile.value = file;
      fileName.value = file.name;
      NotificationService.showNotification($q, 'positive', 'Załączono plik - ' + fileName.value);
    }

  };

  input.click();
}


function isBlank(str) {
  return str === null || /^\s*$/.test(str);
}

const isMouseOnCard = ref(false);

function handleMouseOver() {
  isMouseOnCard.value = true;
}

function handleMouseOut() {
  isMouseOnCard.value = false;
}

const questionsRef = ref(null);

async function validateQuestions() {
  if (questionsRef.value !== null) {
    return questionsRef.value.validate()
  } else {
    return true
  }
}

function setResume(localFileName) {
  let resume = loggedUserResumes.value.find(resume => resume.fileName === localFileName);
  uploadedFile.value = resume.file
  fileName.value = localFileName
  chooseResumeIdForLogged.value = resume.id
}

function toResumesFileNames() {
  return loggedUserResumes.value.map(resume => resume.fileName)
}
</script>
