class OrganizationInvoiceService {
    constructor(url) {
        this.url = url;
    }


    async getInvoices(pageNumber, sorting) {
        let params = new URLSearchParams({})
        params.append('page', pageNumber)
        params.append('size', 15)
        params.append('sort', sorting || 'NEWEST');
        const requestOptions = {
            method: "GET",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
            },
        };
        return fetch(process.env.VUE_APP_BACKEND_URL + "/organizations/" + localStorage.organizationId + "/invoices?" + params, requestOptions)
            .then((response) => {
                return response.json().then((data) => {
                    return data
                })
            })
    }

    async getInvoice(id) {
        const requestOptions = {
            method: "GET",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
            },
        };
        return fetch(process.env.VUE_APP_BACKEND_URL + "/organizations/" + localStorage.organizationId + "/invoices/" + id, requestOptions)
            .then((response) => {
                return response.json().then((data) => {
                    return data
                })
            })
    }

    async downloadInvoice(id) {
        const json = await this.getInvoice(id);
        const byteCharacters = atob(json.file);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        const blob = new Blob([byteArray], {type: 'application/pdf'});

        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = json.fileName;

        document.body.appendChild(link);
        link.click();

        console.info(link)
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    }
}

export default new OrganizationInvoiceService(process.env.VUE_APP_BACKEND_URL);
