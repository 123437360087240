import script from "./ContractInfoAdditional.vue?vue&type=script&setup=true&lang=js"
export * from "./ContractInfoAdditional.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QForm from 'quasar/src/components/form/QForm.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QForm,QCheckbox,QSelect,QInput,QBtn,QIcon});
