<template>
  <div>
    <q-card flat class=" q-mt-lg" style="border-radius: 9px;background-color: rgba(229,237,246,0.82);">
      <q-card-section>
        <my-text class="text-white">
          <span class="text-weight-bold text-primary" style="font-size: 15px;">Pytania</span>
          <span class="text-grey-8">
pojawią się w sekcji aplikacji na daną ofertę. Obowiązuje limit pięciu pytań. W każdym z nich można
        wybrać czy odpowiedź na pytanie jest obowiązkowa.
        Typ odpowiedzi pozwala akceptować tekst lub tylko liczby. np. "Ile masz lat doświadczenia?" wymaga podania
        liczby w latach, dzięki temu łatwiej filtrować aplikacje, które spełniają dane kryteria.
        </span>
        </my-text>
      </q-card-section>
    </q-card>
    <q-form v-if="questions.length !== 0">
      <question
          ref="childComponentRef"
          :form-template-data="formTemplateData"
          v-for="q in questions"
          :key="q.id"
          :v-for-key="q.id"
          v-model:answer-type="q.answerType"
          v-model:question="q.question"
          v-model:required="q.required"
          @add:question="addQuestion"
          @remove:question="removeQuestionById">
      </question>
    </q-form>
    <q-card flat class="q-mx-none q-mt-lg q-mb-lg" style="border-radius: 9px;background-color: rgba(229,237,246,0.82);">
      <q-card-section class="row justify-between">
        <my-text class="text-white q-mt-sm">
          <span class="text-grey-8">
            <span v-if="questions.length <= 0">Brak zdefiniowanych pytań</span>
            <span v-else>Liczba zdefiniowanych pytań: {{questions.length}}</span>
            - maksymalna liczba 3.
        </span>
        </my-text>
        <q-btn class="q-px-md" label="Dodaj pytanie" color="primary" dense size="5px" style="font-size: 10px" @click="addQuestion()">
          <q-icon
              class="q-ml-md"
              name="sym_o_add"
              size="1.5rem"
          />
        </q-btn>
      </q-card-section>
    </q-card>
    <div class="q-my-md">
      <q-separator></q-separator>
    </div>
  </div>
</template>

<script setup>

import {computed, defineEmits, defineExpose, defineProps, ref} from 'vue'
import Question from "@/components/common/add-offer/job-offer/questions/Question";
import NotificationService from "@/services/notification/NotificationService";

const props = defineProps({
  formTemplateData: Object,
  modelValue: Object
})


const questions = computed({
  get: () => props.modelValue,
  set: (val) => emit('update:modelValue', val),
});

const questionId = ref(1);

import {useQuasar} from 'quasar'

const $q = useQuasar();

function validateQuestionsNumber() {
  if (questions.value.length > 4) {
    NotificationService.showNotification($q, 'negative', 'Maksymalna liczba pytań w formularzu wynosi 5');
    return false;
  }
  return true;
}

function addQuestion() {
  if (validateQuestionsNumber()) {
    questions.value.push({id: questionId.value, question: null, answerType: null, required: false})
    questionId.value = questionId.value + 1
  }
}


function removeQuestionById(id) {
  for (let i = 0; i < questions.value.length; i++) {
    if (questions.value[i].id === id) {
      questions.value.splice(i, 1);
      return true; // return true to indicate successful removal
    }
  }
  return false; // return false to indicate that contract with given id was not found
}

const emit = defineEmits(['update:modelValue'])
const childComponentRef = ref(null);

async function validateChildComponents() {
  let pass = true;
  // Use map to create an array of promises
  const validationPromises = childComponentRef.value.map(child => {
    return child.validate(); // Assuming child.validate() returns a promise
  });

  // Wait for all promises to resolve
  await Promise.all(validationPromises).then(results => {
    // Check each validation result
    results.forEach(result => {
      pass = pass && result; // Update pass based on each validation result
    });
  }).catch(error => {
    // Handle any errors
    console.error('Validation error:', error);
    pass = false; // Set pass to false if any error occurs
  });
  console.info("QUESITONS: " + pass)
  return pass; // Return the final value of pass
}


defineExpose({
  validateChildComponents
})
</script>