<template>
  <div class="row q-my-lg q-mx-lg" style="background-color: rgb(217,225,238);">
    <div class="q-scrollarea q-pr-md" style="overflow-y: auto; width: 45%">
      <employer-offer-overview v-for="jobOffer in offers" :job-offer="jobOffer" :key="jobOffer.id"
                               :scroll-target="scrollTargetRef"
                               @show:dialog="showPublicationConfirmationDialog()"
                               :is-active="props.isActive"
                               :is-planned="props.isPlanned"></employer-offer-overview>
    </div>
    <div class="q-pl-xs column col q-pr-none row" style="width: auto; ">
      <div class="col row">
        <q-card class="q-mb-md col row" style="border-radius: 9px">
          <apexchart
              style="border-radius: 9px 9px 9px 9px; background-color: white;"
              type="bar"
              height="100%"
              class="full-width"
              width="100%"
              :options="applicationsChartOptions"
              :series="applicationsGroupedChartData"
          />
        </q-card>
      </div>

      <div class="col row">
        <q-card class="q-mb-md col row " style=" border-radius: 9px">
          <apexchart
              style="border-radius: 9px 9px 9px 9px; background-color: white;"
              type="line"
              height="100%"
              width="100%"
              class="full-width"
              :options="viewsChartOptions"
              :series="viewsSeries"
          />
        </q-card>
      </div>

      <div class="col row">
        <q-card class=" col row" style="border-radius: 9px">
          <apexchart
              style="border-radius: 9px 9px 9px 9px; background-color: white;"
              type="line"
              class="full-width"
              width="100%"
              height="100%"
              :options="activeOffersChartOptions"
              :series="activeOffersSeries"
          />
        </q-card>
      </div>

    </div>
    <publication-extension-confirmation v-model="showDeleteDialog"></publication-extension-confirmation>
  </div>
</template>

<script setup>
import {defineProps, onMounted, ref} from 'vue';
import EmployerOfferOverview from "@/components/organization/job-offers/EmployerOfferOverview";
import jobOfferService from '@/services/JobOfferService.js';
import viewsChartService from "@/services/ViewsChartService";
import applicationsChartService from "@/services/ApplicationsChartService";
import PublicationExtensionConfirmation from "@/components/organization/job-offers/PublicationExtensionConfirmation";

const showDeleteDialog = ref(false)

function showPublicationConfirmationDialog() {
  showDeleteDialog.value = true
}

const props = defineProps({
  isActive: Boolean,
  isPlanned: Boolean
})

const scrollTargetRef = ref(null)
const offers = ref([])
const applicationsGroupedChartData = ref([])
//TODO not use page index from vue, add own variobale, and based on response couynt until last page, than do not perform request
onMounted(() => {
  jobOfferService.getJobOffersForOrganization().then(data => {
    console.info(data)
    let filteredOffers;
    if (!props.isActive && props.isPlanned) {
      filteredOffers = data.filter(offer => offer.active === false && new Date(offer.publication.publishedAt) > new Date());
    } else if (props.isActive) {
      filteredOffers = data.filter(offer => offer.active === true);
    } else if (!props.isActive && !props.isPlanned) {
      filteredOffers = data.filter(offer => offer.active === false && new Date(offer.publication.publishedAt) < new Date());
    }
    offers.value.push(...filteredOffers);
    viewsData.value = viewsChartService.createArrayOfSummaryViews(offers.value);
    activeOffersData.value = viewsChartService.findNumbersOfActiveOffersInSpecificDay(offers.value);
    applicationsGroupedChartData.value = applicationsChartService.addApplicationsNumberToGroupedChartData(offers.value);
    console.info(activeOffersSeries.value)
  })

});

const viewsChartOptions = ref({
  tooltip: {
    enabled: true, // Disable tooltips
  },
  legend: {
    offsetY: -10, // Adjust this value to decrease the space between legend and chart
  },
  dataLabels: {
    enabled: false,
    style: {
      colors: ['#a5ffe7']
    },
  },
  stroke: {
    width: [3],
    curve: ['smooth'],
    colors: ['#a2a8ec']
  },
  chart: {
    toolbar: {
      show: false
    },
    id: 'forecast-chart'
  },
  grid: {
    yaxis: {
      lines: {
        show: false
      }
    }
  },
  xaxis: {
    labels: {
      style: {
        colors: '#868686',
        fontSize: '11px', // Set your desired font size
      },
    },
    categories: viewsChartService.getLast30DaysDateAsArray(),
  },
  fill: {
    type: 'gradient',
    gradient: {
      shade: 'dark',
      shadeIntensity: 1,
      type: 'vertical',
      opacityFrom: 1,
      opacityTo: 1,
      stops: [0, 100, 100, 100],
      gradientToColors: ['#a2ece3'] // Green and purple colors
    },
  },

  yaxis:
      {
        labels: {
          style: {
            colors: '#868686' // Change this to your desired label color
          }
        },
        // min: (min) => {
        //   min = Math.min(...viewsData.value) - 100;
        //   return min;
        // },
        // max: (max) => {
        //   max = Math.max(...viewsData.value) + 100;
        //   return max;
        // },
        title: {
          text: 'Wyświetlenia ofert',
          style: {
            fontStyle: 'Verdana',
            color: '#4382c9' // Change this to your desired label color
          }
        },
      }
});

const applicationsChartOptions = ref({
  grid: {
    yaxis: {
      lines: {
        show: false
      }
    }
  },
  legend: {
    offsetY: -18,
    fontSize: '9px'
  },
  chart: {
    toolbar: {
      show: false
    },
    id: 'forecast-chart',
    type: 'bar',
    stacked: true,
  },
  plotOptions: {
    bar: {
      columnWidth: '40%', // Adjust this value to change the bar width
      horizontal: false, // Vertical bars for grouped chart
      group: 'series',   // Group by series
    }
  },
  dataLabels: {
    style: {
      colors: ['#ffffff']
    },
    enabled: false
  },
  stroke: {
    width: 1,
    curve: 'smooth',
    colors: ['#aaf6e5']
  },
  xaxis: {
    labels: {
      style: {
        fontSize: '11px', // Set your desired font size
        colors: '#868686'
      },
    },
    categories: viewsChartService.getLast30DaysDateAsArray(),
  },
  fill: {
    colors: ['#755bb4'],
  },

  yaxis: {
    labels: {
      formatter: function (value) {
        return Math.floor(value); // Rounds down to remove decimal places
      },
      style: {
        colors: '#868686' // Change this to your desired label color
      }
    },
    title: {
      text: 'Przesłane CV',
      style: {
        fontStyle: 'Verdana',// Change this to your desired label color
        color: '#4382c9' // Change this to your desired label color
      }
    },
  }
});

// const applicationsSeries = ref(applicationsGroupedChartData.value);

const viewsData = ref([])
const activeOffersData = ref([])
const activeOffersSeries = ref([{
  name: 'Aktywne oferty',
  data: activeOffersData

},])
const viewsSeries = ref([
  {
    name: 'Sumaryczna liczba wyświetleń oferty',
    data: viewsData,
    yaxis: 0,
  },
]);


const activeOffersChartOptions = ref({
  grid: {
    yaxis: {
      lines: {
        show: false
      }
    }
  },
  legend: {
    offsetY: -10, // Adjust this value to decrease the space between legend and chart
  },
  dataLabels: {
    style: {
      colors: ['#a5ffe7']
    },
    enabled: false
  },
  stroke: {
    width: [3],
    curve: ['stepline'],
    colors: ['#4723b0']
  },
  chart: {
    toolbar: {
      show: false
    },
    id: 'forecast-chart'
  },
  xaxis: {
    labels: {
      style: {
        colors: '#868686',
        fontSize: '11px', // Set your desired font size
      },
    },
    categories: viewsChartService.getLast30DaysDateAsArray(),
  },
  fill: {
    type: 'gradient',
    gradient: {
      shade: 'dark',
      shadeIntensity: 1,
      type: 'vertical',
      opacityFrom: 1,
      opacityTo: 1,
      stops: [0, 100, 100, 100],
      gradientToColors: ['#a2ece3'] // Green and purple colors
    },
  },

  yaxis: {
    labels: {
      style: {
        colors: '#868686' // Change this to your desired label color
      }
    },
    title: {
      text: 'Aktywne oferty',
      style: {
        color: '#4382c9',
        fontStyle: 'Verdana'// Change this to your desired label color
      }
    },
  }
});


// const chartOptions = {
//   xaxis: {
//     tickAmount: 12,
//     type: "datetime",
//   },
//   yaxis: {
//     max: 70,
//   },
// };
//
// const chartSeries = [
//   {
//     name: "Bubble 1",
//     data: [
//       {
//         x: new Date("2023-09-05").getTime(),
//         y: 40,
//         z: 2,
//       },
//       {
//         x: new Date("2023-09-06").getTime(),
//         y: 6,
//         z: 5,
//       },
//       // Add more data points as needed
//     ],
//   },
// ];
// function changeToActive() {
//   offers.value = []
//   offerType.value = 'ACTIVE';
//   resetInfiniteScroll();
// }
//
// function changeToInactive() {
//   offers.value = []
//   offerType.value = 'INACTIVE';
//   resetInfiniteScroll();
// }
//
// function resetInfiniteScroll() {
//   // Reset the infinite scroll state
//   infinitiveScroll.value.reset();
//   // Clear the offers and other related variables
//   currentNumberOfElements.value = null;
//   totalNumberOfElements.value = null;
//   // Fetch the initial set of offers
//   fetchOffers(0, () => {
//   });
// }

</script>

<style lang="scss" scoped>
.apexcharts-tooltip {
  display: none !important;
}
</style>