<template>
  <q-dialog v-model="showDialog" persistent>
    <q-card style="border-radius: 9px">
      <q-card-section class="row items-center q-pb-sm">
        <q-icon class="q-pr-sm" name="fa-solid fa-file-circle-xmark" size="md" color="primary" text-color="white"/>
        <span
            class="q-ml-sm">Czy usunąć zaznaczone aplikacje? Liczba plików: {{ checkedAppsCount }}</span>
      </q-card-section>

      <q-card-actions align="right">
        <q-btn flat label="Anuluj" color="grey-8" v-close-popup no-caps/>
        <q-btn flat label="Usuń" color="primary" v-close-popup no-caps @click="sendDeleteEmit()"/>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup>
import {computed, defineEmits, defineProps} from "vue";

const props = defineProps({
  checkedAppsCount: String,
  showDialog: Boolean
})

const showDialog = computed({
  get: () => props.showDialog,
  set: (val) => emit('update:showDialog', val),
});

const checkedAppsCount = computed({
  get: () => props.checkedAppsCount,
  set: (val) => emit('update:checkedAppCount', val),
});

const emit = defineEmits(['delete:applications'])

function sendDeleteEmit() {
  emit('delete:applications')
}
</script>