import script from "./ApplicantCvCard.vue?vue&type=script&setup=true&lang=js"
export * from "./ApplicantCvCard.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QCard from 'quasar/src/components/card/QCard.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QIcon,QCheckbox});qInstall(script, 'directives', {Ripple});
