<template>
  <q-layout>
    <q-drawer
        v-model="drawerLeft"
        show-if-above
        @mouseover="miniStateLeft = false"
        @mouseout="miniStateLeft = true"
        :breakpoint="500"
        :width="250"
        class="bg-gradient"
        bordered
    >
      <div>
        <div class="text-center q-mt-lg">
          <q-img fit="contain" :src="logo" style="width: 100px; height: 100px"/>
        </div>

        <div class="text-center">
          <my-text class="text-bold text-white" style="font-size: 30px; font-family: monospace">Byte Job</my-text>
        </div>
      </div>

      <q-list class="column q-col-gutter-sm">
        <div class="q-mx-md q-mt-lg">
          <q-btn
              rounded
              @click="router.push({ name: 'OrganizationJobOffersBoard' });"
              dense
              flat
              style="border-radius: 6px"
              size="15px"
              icon="mdi-account-edit"
              align="left"
              class="q-mx-lg full-width text-white bg-dark"
              color="primary"
              label="Mój profil"
              no-caps
          />
        </div>
        <div class="q-mx-md">
          <q-btn
              rounded
              @click="router.push({ name: 'ApplicantApplications' });"
              dense
              flat
              style="border-radius: 6px"
              size="15px"
              icon="mdi-briefcase-outline"
              align="left"
              class="q-mx-md full-width text-white"
              color="white"
              label="Rekrutacje"
              no-caps
          />
        </div>
        <div class="q-mx-md">
          <q-btn
              rounded
              @click="logout()"
              dense
              flat
              style="border-radius: 6px"
              size="15px"
              icon="mdi-logout"
              align="left"
              class="q-mx-md full-width text-white q-mt-lg"
              color="primary"
              label="Wyloguj"
              no-caps
          />
        </div>
        <div class="q-mx-md absolute-bottom q-mb-md">
          <q-btn
              rounded
              @click="ifRemoveAccount()"
              dense
              flat
              style="border-radius: 6px"
              size="15px"
              icon="fa-solid fa-user-xmark"
              align="left"
              class="q-mx-md full-width text-white q-mt-lg"
              color="primary"
              label="Usuń konto"
              no-caps
          />
        </div>
      </q-list>
    </q-drawer>
    <q-page-container style="background-color: #dde5ee;">
      <q-page class="column items-center col row">
        <menu-bar></menu-bar>
        <div class="col row q-scrollarea text-center q-mb-md" style="width: 100%; overflow-y: auto; justify-content: center;">
          <div class="column q-mt-sm" style="max-width: 65%; width: 100%;">
            <applicant-data ref="applicantDataRef"
                            v-if="user.id !== null"
                            v-model="user"
                            style="margin-top: 35px;"
                            class="q-mx-lg q-mt-lg"></applicant-data>
            <applicant-c-v v-if="user.id !== null" :applicant-id="user.id"
                           class="q-mx-lg q-mt-md"></applicant-c-v>
            <div style="max-height: 60px;">
              <q-card-section class="q-mt-none q-pl-none q-pt-none row justify-between full-width"
                              style="max-height: 10px;">
                <q-btn size="13px" color="primary"
                       icon="login"
                       class="q-ml-lg"
                       style="max-height: 15px; border-radius: 6px;"
                       @click="updateData()"
                       label="Zapisz zmiany"
                       no-caps/>
              </q-card-section>
            </div>
            <account-removal-confirmation v-model:show-dialog="showAccountRemovalDialog"
                                          @remove:account="removeAccount()"></account-removal-confirmation>
          </div>
        </div>


      </q-page>
    </q-page-container>
  </q-layout>
</template>


<script setup>
import {onBeforeMount, reactive, ref} from 'vue'
// import MenuBar from "@/components/common/MenuBar";
import ApplicantData from "@/components/applicant/profile/ApplicantData";
import ApplicantCV from "@/components/applicant/profile/ApplicantCV";
import AuthenticationService from "@/services/AuthenticationService";

import {useRouter} from "vue-router/dist/vue-router";
import logo from '@/assets/logo.png'; // Adjust the path as needed
import ApplicantDataService from "@/services/applicant/ApplicantDataService";
import NotificationService from "@/services/notification/NotificationService";
import {useQuasar} from "quasar";
import MenuBar from "@/components/common/MenuBar";
import AccountRemovalConfirmation from "@/components/applicant/profile/AccountRemovalConfirmation";

const router = useRouter()

onBeforeMount(() => {
  initAuthenticatedUserData()
  initUserData()
})

function initUserData() {
  AuthenticationService.getAuthenticatedUser().then(data => {
    data.json().then(body => {
      user.firstName = body.firstName;
      user.id = body.id;
      user.email = body.email;
      user.lastName = body.lastName;
      user.phoneNumber = body.phoneNumber;
      if (body.preferredContactTime !== null) {
        user.contactInSpecificHours.min = hourToInt(body.preferredContactTime.from);
        user.contactInSpecificHours.max = hourToInt(body.preferredContactTime.to);

      }
    })
  });
}

function hourToInt(hour) {
  return parseInt(hour, 10);
}

function initAuthenticatedUserData() {
  AuthenticationService.getAuthenticatedUser()
      .then(response => {
        if (response.status === 200) {
          response.json().then((data) => {
            user.value = data
          })
        } else {
          throw new Error("Cannot get user profile data - user not authenticated")
        }
      });
}

const user = reactive({
  id: null,
  email: null,
  firstName: null,
  lastName: null,
  phoneNumber: null,
  contactInSpecificHours: {
    min: 10,
    max: 18
  }
});

const $q = useQuasar()

async function updateData() {
  let promise = await validateChildComponents();
  if (promise) {
    ApplicantDataService.updateData(user.id, user.firstName, user.lastName, user.phoneNumber, user.contactInSpecificHours.min.toString() + ":00", user.contactInSpecificHours.max.toString() + ":00")
        .then(response => {
          if (response.ok) {
            NotificationService.showNotification($q, 'positive', 'Zaktualizowano dane profilu')
          }
        })
  }
}

const applicantDataRef = ref(null);

async function validateChildComponents() {
  return applicantDataRef.value.validate()
}

const showAccountRemovalDialog = ref(false)

function removeAccount() {
  showAccountRemovalDialog.value = false;
  ApplicantDataService.deleteAccount(user.id)
      .then(() => {
        AuthenticationService.logout()
            .then(() => {
              router.push({name: 'JobOfferMainBoard'});
            });
      })
}

function ifRemoveAccount() {
  showAccountRemovalDialog.value = true;
}

function logout() {
  AuthenticationService.logout().then(() => {
    router.push({name: 'JobOfferMainBoard'});
  });
}
</script>
<style lang="scss">
.q-btn--dense .on-left {
  margin-right: 22px;
  margin-left: 8px;
}

.bg-gradient {
  background: linear-gradient(180deg, $primary 20%, $light-blue-11 150%); /* Gradient procentowy */
  color: white; /* Text color */
}
</style>