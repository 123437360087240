<template>
  <q-btn-dropdown
      square
      v-model="fab2"
      padding="4px xl"
      flat
      style="border-radius: 7px"
      class=" text-white q-mr-md"
      label="LOGIN"
      active-icon="sym_o_download"
      vertical-actions-align="right"
      icon="login"
      direction="down"
  >
    <div class="q-pr-none">
      <q-list class=" q-pt-xs-md" style="border-radius: 9px">
        <q-item class="q-pa-sm">
          <q-btn flat
                 square
                 style="border-radius: 7px"
                 color="accent"
                 class=" q-pa-sm full-width"
                 @click="router.push({ name: 'OrganizationLoginPage' });" icon="sym_o_work"
                 label="Pracodawca"/>
        </q-item>
        <div class="q-px-sm">
          <q-separator></q-separator>
        </div>
        <q-item class="q-pa-sm">
          <q-btn flat
                 square
                 style="border-radius: 7px"
                 color="primary"
                 class=" full-width"
                 @click="router.push({ name: 'ApplicantLoginPage' });" icon="sym_o_person"
                 label="Kandydat"/>
        </q-item>
      </q-list>
    </div>
  </q-btn-dropdown>
</template>

<script setup>
import {useRouter} from 'vue-router'

const router = useRouter()

</script>

<style lang="sass" scoped>

p
  font-size: 15px
  color: #575757

.btn-fixed-size
  width: 100%
  height: 56px

.my-card
  width: 100%
  max-width: 350px
  background-color: rgb(217, 225, 238)

.separator
  width: 100%
  margin: 20px
</style>