<template>
  <div class="row justify-between">
    <div class="q-pr-lg q-pb-none">
      <q-badge outline color="primary" :label="props.language"/>
      <q-rating
          v-model="proficiency"
          size="1em"
          icon="fa-regular fa-square"
          icon-selected="fa-solid fa-square"
          color="primary"
          class="q-pl-sm"
          :max="5"
      />
      <div class="q-pt-xs">
        <my-text>
          {{ 'Poziom: ' + describeRatingValue() }}
        </my-text>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref, defineProps, onMounted, computed, defineEmits} from 'vue'
import OfferDataTranslator from "@/services/organization/offer/OfferDataTranslator";

const props = defineProps({
  formTemplateData: Object,
  language: String,
  proficiency: String,
  modelValue: Object,
})

const proficiency = computed({
  get: () => props.proficiency,
  set: (val) => emit('update:proficiency', val),
});

const emit = defineEmits(['update:modelValue'])

onMounted(() => {
  allLanguages.value = OfferDataTranslator.translateLanguageProficienciesToPolish()
})

const allLanguages = ref(null)

function describeRatingValue() {
  let level = mapRatingProficiencyToModelValue()
  if (level != null) {
    return level
  } else {
    return 'wybierz poziom'
  }
}

function mapRatingProficiencyToModelValue() {
  if (proficiency.value === 1) {
    return 'Podstawowy'
  } else if (proficiency.value === 2) {
    return 'Komunikatywny'
  } else if (proficiency.value === 3) {
    return 'Średniozaawansowany'
  } else if (proficiency.value === 4) {
    return 'Zaawansowany'
  } else if (proficiency.value === 5) {
    return 'Biegły'
  }
}

</script>