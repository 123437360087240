class PhoneNumberValidator {
    isValidPolishPhoneNumber(value) {
        // Regular expression to match Polish phone numbers starting with "+48"
        const polishPhoneNumberRegex = /^\+48\d{9}$/;

        // Check if the value matches the regex pattern
        return polishPhoneNumberRegex.test(value);
    }
}

export default new PhoneNumberValidator();

