class ViewsChartService {


    constructor() {

    }

    createMapWithLast30Dates() {
        const today = new Date();
        const last30DaysMap = new Map();
        for (let i = 0; i < 30; i++) {
            const date = new Date(today);
            date.setDate(today.getDate() - i);
            const formattedDate = this.formatDate(date);
            last30DaysMap.set(formattedDate, 0); // You can set the initial value as needed
        }
        return last30DaysMap;
    }

    formatDate(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    createArrayOfSummaryViews(offers) {
        const map = this.addViewsFromOfferToSpecificDay(offers)
        return Array.from(map.values()).reverse();
    }

    addViewsFromOfferToSpecificDay(offers) {
        const allViewsMap = this.createMapWithLast30Dates();
        for (const offer of offers) {
            this.calculateViews(offer, allViewsMap);
        }
        return allViewsMap;
    }

    calculateViews(offer, viewsMap) {
        for (const [date, views] of Object.entries(offer.dailyViews)) {
            this.addViewsToExistingDate(date, views, viewsMap);
        }
        return Array.from(viewsMap.values()).reverse();
    }

    calculateViewsForOneOffer(offer) {
        const viewsMap = this.createMapWith30DaysFromOfferPublication(offer);
        for (const [date, views] of Object.entries(offer.dailyViews)) {
            this.addViewsToExistingDate(date, views, viewsMap);
        }
        return Array.from(viewsMap.values());
    }

    addViewsToExistingDate(date, views, viewsMap) {
        if (viewsMap.has(date)) {
            let sum = viewsMap.get(date);
            viewsMap.set(date, sum + views)
        }
    }

    findNumbersOfActiveOffersInSpecificDay(offers){
        const map = this.createMapWithLast30Dates();
        for (const offer of offers) {
            for (const date of map.keys()) {
                if(this.isOfferActiveOnThisDate(date,offer)){
                    let sum = map.get(date);
                    map.set(date,sum+1)
                }
            }
        }
        return Array.from(map.values()).reverse();
    }

    isOfferActiveOnThisDate(date, offer) {
        return new Date(offer.publication.publishEndAt) >= new Date(date) && new Date(offer.publication.publishedAt) <= new Date(date)
    }

    getLast30DaysDateAsArray() {
        const dateStrings = Array.from(this.createMapWithLast30Dates().keys());
        const sorted =  dateStrings.sort((a, b) => new Date(a) - new Date(b));
        return sorted.map(sortedDate => sortedDate.slice(5).replace('-','.'))
    }

    createMapWith30DaysFromOfferPublication(offer) {

        const last30DaysMap = new Map();
        for (let i = 0; i < 30; i++) {
            const publishedAt = new Date(offer.publication.publishedAt);
            const date =new Date(offer.publication.publishedAt);
            date.setDate(publishedAt.getDate() + i)
            const formattedDate = this.formatDate(date);
            last30DaysMap.set(formattedDate, 0); // You can set the initial value as needed
        }
        return last30DaysMap;
    }

}

export default new ViewsChartService();
