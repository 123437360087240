class JobOfferService {
    constructor(url) {
        this.url = url;
    }

    getAllJobOffers(params,signal) {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: 'include',
            signal:signal
        };
        try {
            return fetch(process.env.VUE_APP_BACKEND_URL + "/job-offers/active?" + params, requestOptions)
                .then((response) => {
                    return response
                })
        } catch(err) {
            if (err.name == 'AbortError') { // handle abort()
                alert("Aborted!");
            } else {
                throw err;
            }
        }


    }

    getFavJobOffers(applicantId) {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: 'include',
        };

        return fetch(process.env.VUE_APP_BACKEND_URL + "/applicants/" + applicantId + "/favorite-offers", requestOptions)
            .then((response) => {
                return response
            })
    }

    getJobOffer(jobOfferId) {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include"
        };
        return fetch(process.env.VUE_APP_BACKEND_URL + "/job-offers/" + jobOfferId, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`Error ${response.status}: ${response.statusText}`);
                }
                return response
            })
    }

    async getJobOffersForOrganization() {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include"
        };
        return fetch(process.env.VUE_APP_BACKEND_URL + "/organizations/" + localStorage.getItem("organizationId") + "/job-offers", requestOptions)
            .then((response) => {
                if (response.status !== 200) {
                    console.log("clearing cache 2")
                    localStorage.clear();
                    throw new Error(`Error ${response.status}: ${response.statusText}`);
                }
                return response.json().then((data) => {
                    return data
                }).catch((err) => {
                    console.log("clearing cache 11")
                    localStorage.clear();
                    console.log(err);
                })
            })
    }

    async getJobOfferForOrganization(offerId) {

        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
        };
        return fetch(process.env.VUE_APP_BACKEND_URL + "/organizations/" + localStorage.getItem("organizationId") + "/job-offers/" + offerId, requestOptions)
            .then((response) => {
                if (response.status !== 200) {
                    console.log("clearing cache 12")
                    localStorage.clear();
                    throw new Error(`Error ${response.status}: ${response.statusText}`);
                }
                return response.json().then((data) => {
                    return data
                }).catch((err) => {
                    console.log("clearing cache 13")
                    localStorage.clear();
                    console.log(err);
                })
            })
    }

    async updateOfferVisibility(offerId, isVisible) {
        const requestOptions = {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({visible: isVisible}),
        };
        return fetch(process.env.VUE_APP_BACKEND_URL + "/organizations/" + localStorage.getItem("organizationId") + "/job-offers/" + offerId + '/visible', requestOptions)
            .then((response) => {
                return response.json().then((data) => {
                    return data
                })
            })
    }

    getSimilarJobOffers(jobOfferId) {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include"
        };
        return fetch(process.env.VUE_APP_BACKEND_URL + "/job-offers/" + jobOfferId + "/similar", requestOptions)
            .then((response) => {
                if (!response.ok) {
                    console.info("clearing 9")
                    localStorage.clear();
                    throw new Error(`Error ${response.status}: ${response.statusText}`);
                }
                return response.json().then((data) => {
                    return data
                }).catch((err) => {
                    console.info("clearing 1")
                    localStorage.clear();
                    console.log(err);
                })
            })
    }
}

export default new JobOfferService(process.env.VUE_APP_BACKEND_URL);
