<template>
  <q-card bordered v-ripple class="q-mt-none q-mb-md"
          :class="{ 'q-hoverable': !isMouseOverFavBtn, 'cursor-pointer': !isMouseOverFavBtn }"
          style="border-radius: 9px; border-width: 1px"
          @click="toJobOffers(props.application.jobOffer.id)">
    <!--    <q-card v-ripple class="my-box cursor-pointer q-hoverable my-card" @click="goToDetails(props.jobOffer.id)">-->
    <span :class="{ 'q-focus-helper': !isMouseOverFavBtn}" style="color: #9a9a9a"></span>
    <q-card-section>
      <div class="row">
        <div class="q-pa-none q-pr-md" style=" display: flex;   flex-direction: column;
  justify-content: center;  /* Centering y-axis */
  align-items :center">
          <q-img
              style="width: 65px;height: 65px; border-radius: 6px;"
              v-bind:src="props.application.jobOffer.organization.logoUrl"
              fit='fill'
              :ratio="1"
          />
        </div>
        <div class="col q-pt-none q-pr-xs">
          <div class="justify-between">
            <div class="q-pa-none row justify-between">
              <div class="row">
                <p class="q-pl-sm text-grey-9" style="font-size: 15px; font-weight: 600">
                  {{ props.application.jobOffer.title }}</p>
              </div>
              <div class="q-mr-none q-mb-none row">
                <p class="q-mb-none text-accent q-mr-sm"
                   style="border-width: 1px; max-height: 23px; font-size: 14px; font-weight: 400"
                >
                  {{ salaryToString() }}
                </p>
                <p v-if="props.application.jobOffer.salary!==null" class="q-mb-none text-primary"
                   style="border-width: 1px; max-height: 23px; font-size: 14px; font-weight: 400"
                >
                  {{ props.application.jobOffer.salary.currency }}
                </p>
              </div>
            </div>
            <div class="row q-pl-sm">
              <q-icon name="corporate_fare" color="secondary" size="16px"/>
              <p class="q-pl-xs q-pa-none q-ma-none text-grey-8" style="font-size: small;">
                {{ props.application.jobOffer.organization.name }}</p>
              <!--              <q-icon name="public" color="secondary" class="q-pl-lg" size="16px"/>-->
              <!--              <p class="q-pl-xs q-pa-none q-ma-none text-grey-8" style="font-size: small;">-->
              <!--                {{ LocationTranslator.getCityDescriptions(props.application.jobOffer.locations) }}-->
              <!--              </p>-->
              <locations-info-dropdown
                  class="q-ml-md"
                  :is-selected="true"
                  style="padding-top: 2px"
                  :locations="props.application.jobOffer.locations">
              </locations-info-dropdown>
            </div>
          </div>
        </div>
        <div>
        </div>
      </div>
    </q-card-section>

    <q-separator/>
    <q-card-section class="justify-between q-pa-xs row">

      <q-chip class="q-ml-xs q-mb-none" style="border-radius: 5px" size="sm" color="primary" text-color="white"
              icon="fa-solid fa-paper-plane">
        {{ props.application.appliedAt }}
      </q-chip>
      <q-chip outline style="border-radius: 5px" size="sm" color="primary" text-color="white" icon="event">
        Preferowany czas kontaktu od&nbsp;<b>{{ props.application.preferredContactTime.from }} </b>&nbsp;do&nbsp;<b>
        {{ props.application.preferredContactTime.to }} </b>
      </q-chip>
    </q-card-section>
  </q-card>
</template>

<script setup>
import {defineProps, onMounted, ref} from "vue";
import {useRouter} from 'vue-router'
import LocationsInfoDropdown from "@/components/common/job-offers/LocationsInfoDropdown";

const router = useRouter()

const props = defineProps({
  application: Object,
  borderColor: String,
})

onMounted(() => {
  console.info("APLIKACJA " + props.application)
})

function toJobOffers(id) {
  router.push({name: 'JobOffersMainBoard', params: {id}});
}

function salaryToString() {
  if (props.application.jobOffer.salary !== null) {
    return props.application.jobOffer.salary.from.toString() + ' - ' + props.application.jobOffer.salary.to.toString() + ' '
  } else {
    return 'BRAK STAWKI'
  }
}

const isMouseOverFavBtn = ref(false)

// function toggleMouseOverFavBtn(val) {
//   isMouseOverFavBtn.value = val;
// }
// function formatDateString(inputDateString) {
//   const inputDate = new Date(inputDateString);
//
//   const options = {
//     day: '2-digit',
//     month: 'long',
//     year: 'numeric',
//     hour: '2-digit',
//     minute: '2-digit',
//     timeZone: 'UTC'
//   };
//
//   return inputDate.toLocaleString('pl-PL', options);
// }
</script>

<style lang="sass" scoped>

</style>