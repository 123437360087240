<template>
  <q-btn-dropdown
      outline
      v-model="fab2"
      padding="4px xl"
      hide-icon
      class="text-white q-mr-md bg-transparent"
      label="REJESTRACJA"
      square
      dense
      style="border-radius: 7px"
      vertical-actions-align="left"
      direction="down"
  >
    <div class="q-pr-none">
      <q-list class=" q-pt-xs-md" style="border-radius: 9px">
          <q-item class="q-pa-sm">
            <q-btn flat
                   square
                   style="border-radius: 7px"
                   color="accent"
                   class=" q-pa-sm full-width"
                   @click="router.push({ name: 'OrganizationRegistration' });" icon="sym_o_work"
                   label="Pracodawca"/>
          </q-item>
        <div class="q-px-sm">
          <q-separator></q-separator>
        </div>
        <q-item class="q-pa-sm">
            <q-btn flat
                    square
                   style="border-radius: 7px"
                   color="primary"
                    class=" full-width"
                    @click="router.push({ name: 'EmployeeRegistration' });" icon="sym_o_person"
                    label="Kandydat"/>
          </q-item>
      </q-list>
    </div>
  </q-btn-dropdown>
  <q-dialog class="my-card" transition-duration="800" v-model=registrationModal>
    <q-card class="my-card" style="border-radius: 9px">
      <p align="left" class="q-px-md q-pb-s q-pt-md"
         style=" font-size: 40px; margin: 0px; padding-top: 6px; padding-bottom: 0; color: #522f7e ">Join now</p>
      <registration-data-section></registration-data-section>
      <div class="row justify-center" style="margin-bottom: 10px;">
        <p class="q-px-md q-pb-s q-pt-md" style="font-size: 15px; padding: 0px; margin:0; padding-top: 11px"><Strong>Already
          registered?</Strong></p>
        <q-btn no-caps align="center" flat label="Sign in" color="positive" size="17px"
               style="padding: 0px; margin: 0; padding-left: 5px"/>
      </div>
      <!--      SEPARATOR-->
      <q-separator inset color="accent" style="margin: 2px"></q-separator>
      <!--      PLATFORM BENEFITS DESCRIPTION-->
      <sso-registration-data-section></sso-registration-data-section>
    </q-card>
  </q-dialog>
</template>

<script setup>
import {useRouter} from "vue-router/dist/vue-router";
import { ref} from 'vue';
import RegistrationDataSection from "@/components/common/registration/details/RegistrationDataSection.vue";
import SsoRegistrationDataSection from "@/components/common/registration/details/SsoRegistrationDataSection.vue";

const router = useRouter()
const registrationModal = ref(false);


</script>

<style lang="sass" scoped>

p
  font-size: 15px
  color: #575757

.btn-fixed-size
  width: 100%
  height: 56px

.my-card
  width: 100%
  max-width: 380px
  background-color: rgb(217, 225, 238)
  margin-bottom: 14rem

</style>