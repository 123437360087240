<template>
  <div class="dialogWrapper">
    <!--      EMAIL FIELD-->
    <q-card-actions align="center" class="q-px-md q-pb-s q-pt-md" v-if="!isRegistrationDone">
      <q-input class="input-fixed-size"
               outlined
               type="email"
               color="primary"
               label-color="positive"
               borderless
               standout="border-width=4px"
               v-model="email"
               label="e-mail"
               :rules="[
              val => !!val || 'E-mail address is required',
              val => isEmailValid() || 'Provide correct e-mail address'
              ]"
      >
        <template v-slot:prepend>
          <q-icon color="primary" name="mail_outline"/>
        </template>
      </q-input>
      <q-input v-model="password"
               class="input-fixed-size"
               outlined
               label="password"
               label-color="positive"
               bg-color="white"
               hide-hint
               :type="isPwd ? 'password' : 'text'"
               hint="Minimum length 6 characters with at least one number, one special character and one capital letter."
               standout="border-width=4px">
        <template v-slot:prepend>
          <q-icon name="lock_open"/>
        </template>
        <template v-slot:append>
          <q-icon
              :name="isPwd ? 'visibility_off' : 'visibility'"
              class="cursor-pointer"
              @click="isPwd = !isPwd"
          />
        </template>
      </q-input>
    </q-card-actions>

    <!--   ACTIVE DONE BUTTON-->
    <q-card-actions v-if="isPasswordValid(this.password) && isEmailValid() && !isRegistrationDone" align="center"
                    class="q-px-md q-pb-sm">
      <q-btn @click="sendInitRegistrationProcess" no-caps class="btn-fixed-size" color="positive"
             label="Agree and join"
             icon-right="check"/>
    </q-card-actions>

    <!--      INACTIVE BUTTON WHEN EMAIL IN CORRECT FORMAT IS NOT PROVIDED-->
    <q-card-actions v-else-if="!isRegistrationDone" align="center" class="q-px-md q-pb-sm">
      <q-btn no-caps disable class="btn-fixed-size" color="primary" :label="this.passValidationDsc"/>
    </q-card-actions>

    <q-card-actions v-else-if="isRegistrationDone && !wasEmailUsedBefore" align="center" class="q-px-lg q-pb-md">
      <div class="row">
        <div class="col-10 q-pt-xs">
          <p style="font-size: 16px;">Finish your registration using confirmation link sent to your
            e-mail.</p>
        </div>
        <div class="col-md q-pb-md q-pl-md">
          <q-spinner-hourglass
              color="positive"
              size="4em"
          />
        </div>
      </div>
      <q-btn outline class="btn-fixed-size" color="primary" label="Ok" v-close-popup/>

    </q-card-actions>
    <q-card-actions v-else-if="wasEmailUsedBefore" align="center" class="q-px-lg q-pb-md">
      <div class="row">
        <div class="col-10 q-pt-xs">
          <p style="font-size: 16px;color:">Email was ude before, password reset link is sent to your
            e-mail.</p>
        </div>
        <div class="col-md q-pb-md q-pl-md">
          <q-spinner-hourglass
              color="positive"
              size="4em"
          />
        </div>
      </div>
      <q-btn outline class="btn-fixed-size" color="primary" label="Ok" v-close-popup/>
    </q-card-actions>
  </div>

</template>

<script>
import {ref} from "vue";

export default {
  name: "RegistrationDataSection",
  computed: {
    passValidationDsc: function () {
      if (!this.isEmailValid()) {
        return ("Provide correct email")
      } else if (this.password.length < 6) {
        return ("Too short password");
      } else if (this.password.length > 50) {
        return ("Too long password");
      } else if (!/\d/.test(this.password)) {
        return ("Missing number character");
      } else if (!/[A-Z]/.test(this.password)) {
        return ("Missing capital letter character");
      } else {
        return ("Agree and Join");
      }
    }
  },
  methods: {
    isEmailValid() {
      const re = /^\w+(\[\+\.-]?\w)*@\w+(\[\.-]?\w+)*\.[a-z]+$/i;
      return re.test(this.email);
    },
    isPasswordValid(str) {
      if (str.length < 6) {
        return false;
      } else if (str.length > 20) {
        return false;
      } else if (!/\d/.test(str)) {
        return false;
      } else if (!/[A-Z]/.test(str)) {
        return false;
      } else {
        return true;
      }
    },
    sendInitRegistrationProcess() {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({email: this.email, rawPassword: this.password})
      };
      this.error = null;
      this.wasEmailUsedBefore = false;
      fetch(process.env.VUE_APP_BACKEND_URL + "/registrations", requestOptions)
          .then(response => {
            console.log(response.status)

            if (response.ok) {
              this.isRegistrationDone = true;
              return response.body;
            } else if (response.status === 409) {
              this.wasEmailUsedBefore = true;
              this.isRegistrationDone = true;
              throw new Error("Email was used before.")
            } else {
              throw new Error("Error during registration process.")
            }
          })
          .catch((error) => {
            console.log(error);
            this.error = error.message;
          });
    }
  },
  data() {
    return {
      email: ref(''),
      password: ref(''),
      isRegistrationDone: false,
      isPwd: true,
      error: null,
      wasEmailUsedBefore: false
    }
  },
}

</script>
<style lang="sass" scoped>
.input-fixed-size
  width: 100%
  height: 56px
  padding-bottom: 5px
  margin-bottom: 20px

.btn-fixed-size
  width: 100%
  height: 56px

p
  color: #505050
</style>