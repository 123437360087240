<template>
  <div class="row justify-center q-pt-lg q-mt-lg q-scrollarea col row" style="overflow-y: auto"
       v-if="formTemplateData !== null">
    <q-form @submit="sendPostWithJobOffer()" greedy :style="calculateWidthBasedOnStep()">

      <!--      //TODO disable click-moving jumping between steps -->
      <q-stepper
          v-model="step"
          header-nav
          ref="stepper"
          color="primary"
          animated
          style="border-radius: 9px;"
          class="q-mb-lg"
      >
        <q-step
            :name="1"
            title="Plan"
            active-color="dark"
            icon="settings"
            :done="step > 1"
            :header-nav="step > 1"
        >
          <!--          <span class="text-grey-9">Do czasu zakończenia promocji oferty ogłoszeń można dodawać za darmo.</span>-->
          <div class="row justify-between q-mx-md">
            <plan-basic @step:next="nextStep()"
                        :plan="getBasicPlan()"
                        class="q-my-lg"
                        v-model:plan-for-offer="jobOfferData.plan"
                        style="border-color: #151273;
                         border-width: 1px">
            </plan-basic>

            <plan-standard @step:next="nextStep()"
                           :type="'Standard'"
                           :plan="getStadardPlan()"
                           class="q-my-lg"
                           style="border-color: #151273;
                            border-width: 2px"
                           v-model:plan-for-offer="jobOfferData.plan">
            </plan-standard>

            <plan-premium @step:next="nextStep()"
                          :type="'Premium'"
                          :plan="getPremiumPlan()"
                          class="q-my-lg"
                          style="border-color: #151273;
                           border-width: 1px"
                          v-model:plan-for-offer="jobOfferData.plan">

            </plan-premium>
          </div>
        </q-step>

        <q-step
            style="max-width: 950px;"
            :name="2"
            class="full-height"
            active-color="dark"
            title="O firmie"
            icon="settings"
            :done="step > 2"
            :header-nav="step > 2"
        >
          <company-form
              style="max-width: 950px"
              :account-details="accountDetails"
              :form-template-data="formTemplateData"
              v-model="jobOfferData.organization"
              @step:next="nextStep"
              @step:previous="previousStep"
          ></company-form>
        </q-step>

        <q-step
            :name="3"
            active-color="dark"
            title="Ogłoszenie"
            icon="create_new_folder"
            :done="step > 3"
            :header-nav="step > 3"
        >
          <job-offer-form :form-template-data="formTemplateData"
                          v-model="jobOfferData"
                          @step:next="nextStep"
                          @step:previous="previousStep"
                          :show-buttons="true"
          >

          </job-offer-form>
        </q-step>

        <q-step
            :name="4"
            title="Podgląd"
            active-color="dark"
            icon="fa-regular fa-eye"
            :done="step > 4"
            :header-nav="step > 4"
        >
          <q-card flat class="q-mx-sm q-mt-sm q-mb-lg full-width"
                  style="border-radius: 9px;background-color: rgba(229,237,246,0.82);">
            <q-card-section>
              <my-text class="text-white">
                <span class="text-weight-bold text-primary" style="font-size: 15px">Podgląd</span> <span
                  class="text-grey-8">
                Twojego ogłoszenia. Jeśli chcesz poprawić wybrane dane, wróć do poprzedniego kroku.
      </span>
              </my-text>
            </q-card-section>
          </q-card>
          <div class="col row q-mb-md" v-if="copiedJobOfferUiRepresentation !==null">
            <job-offer-details
                :show-apply="false"
                :logo-in-base64="jobOfferData.organization.logoInBase64"
                :padding-top="0"
                :job-offer="copiedJobOfferUiRepresentation">
            </job-offer-details>
          </div>
          <q-stepper-navigation class="q-pt-none">
            <div class="q-mb-md">
              <q-separator></q-separator>
            </div>
            <!--            TODO SUBMIT BUTTON REMOVED - ADD LOGIC FOR SAVING OFFER-->
            <q-btn color="cyan-7" no-caps dense outline class="q-px-md"
                   label="Przejdź do płatności"
                   @click="nextStep"/>
            <q-btn flat @click="previousStep()" color="primary" no-caps dense label="Powrót" class="q-ml-md"/>
          </q-stepper-navigation>
        </q-step>
        <q-step
            :name="5"
            :done="step > 5"
            @step:previous="previousStep()"
            title="Płatność"
            active-color="dark"
            icon="fa-regular fa-wallet"
            :header-nav="step > 5"
        >
          <div class="col row q-mb-md full-width">

            <job-offer-payment
                ref="paymentDataRef"
                :plan="jobOfferData.plan"
                :is-logged-organization-user="false"
                :payment="jobOfferData.payment">
            </job-offer-payment>

          </div>
          <q-stepper-navigation class="q-pt-none">
            <div class="q-mb-md">
              <q-separator></q-separator>
            </div>

            <q-btn type="submit" color="cyan-7"
                   no-caps
                   dense
                   outline
                   class="q-px-md"
                   label="Przejdź do Przelewy24"
            />

            <q-btn
                flat
                @click="step = 4"
                color="primary"
                no-caps
                dense
                label="Powrót"
                class="q-ml-md"
            />

          </q-stepper-navigation>
        </q-step>
      </q-stepper>
    </q-form>
  </div>
</template>

<script setup>
import {onMounted, reactive, ref} from "vue"
import JobOfferForm from "@/components/common/add-offer/job-offer/JobOfferForm";
import CompanyForm from "@/components/common/add-offer/company-info/CompanyForm";
import JobOfferPlansService from "@/services/organization/offer/JobOfferPlansService";
import _ from 'lodash';
import JsonBlankFieldsCleaner from "@/services/common/JsonBlankFieldsCleaner";

onMounted(() => {
  getFormtTemplateData().then(data => {
    formTemplateData.value = data;
  });
})

function getFormtTemplateData() {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json"
    },
  };
  return fetch(process.env.VUE_APP_BACKEND_URL + "/job-offers/form-template-data", requestOptions)
      .then((response) => {
        return response.json().then((data) => {
          return data
        }).catch(() => {
          // console.log(err);
        })
      })
}

const formTemplateData = ref(null)

const step = ref(1)

const jobOfferData = reactive({
  organization: {
    name: "test",
    webPageUrl: "test.com",
    type: "Startup",
    description: '<strong>Dodaj informacje o firmie (opcjonalne):</strong> <ul>\n' +
        '  <li>Charakterystyka</li>\n' +
        '  <li>Projekty</li>\n' +
        '  <li>Styl pracy</li>\n' +
        '</ul>',
    industries: ["Blockchain"],
    size: "",
    nip: "5213960904",
    logoInBase64: "",
    branding: {
      primaryColor: "#20b1f5",
      secondaryColor: "#202ef5"
    }
  },
  plan: {},
  title: "title",
  seniority: "",
  jobPosition: "",
  planType: 'STANDARD',
  contracts: [{type: null, salary: {from: 6000, to: 7000, currency: 'PLN'}, id: 0}],
  questions: [],
  description: '<strong>Dodaj informacje o:</strong> <ul>\n' +
      '  <li>Firmie</li>\n' +
      '  <li>Projekcie</li>\n' +
      '  <li>Obowiązkach</li>\n' +
      '  <li>Benefitach</li>\n' +
      '</ul>',
  operatingModes: [],
  workTypes: [],
  skills: [],
  languages: [],
  addresses: [],
  locations: [],
  active: true,
  jobApplicationInfo: {
    externalApplicationLink: null,
    applicationType: ref("INTERNAL")
  },
  payment: {
    email: "",
    payNow: true,
    billingData: {
      companyName: "",
      nip: "",
      address: {
        street: "",
        streetNumber: "",
        flatNumber: "",
        city: "",
        zipCode: ""
      }
    },
    promoCode: ""
  }
})

const accountDetails = reactive({
  adminEmail: ""
})

const copiedJobOfferUiRepresentation = ref(null)

function nextStep() {
  step.value = step.value + 1;
  console.info("STEP " + step.value)
  if (isStepForJobOfferDetails()) {
    mapValuesToRequestModel()
  }
  if (step.value === 5) {
    console.info('email' + accountDetails.adminEmail)
    jobOfferData.payment.email = accountDetails.adminEmail;
    jobOfferData.payment.billingData.companyName = jobOfferData.organization.name
  }
}

function isStepForJobOfferDetails() {
  return step.value === 4
}

function mapValuesToRequestModel() {
  console.info(JSON.stringify(copiedJobOfferUiRepresentation.value))
  console.info(JSON.stringify(jobOfferData))
  copiedJobOfferUiRepresentation.value = _.cloneDeep(jobOfferData);
  OfferDataTranslator.mapSkillLevelsToModel(copiedJobOfferUiRepresentation.value)
  copiedJobOfferUiRepresentation.value.seniority = OfferDataTranslator.translateFromPolishToExperienceLevel(copiedJobOfferUiRepresentation.value.seniority)
  mapContractsTypeToModel(copiedJobOfferUiRepresentation.value)
  console.info('langs ' + jobOfferData.languages)
  console.info('langs ' + copiedJobOfferUiRepresentation.value.languages)
  mapLanguagesToModel(copiedJobOfferUiRepresentation.value)
  mapLocationsToModel(copiedJobOfferUiRepresentation.value)
  mapQuestionsToModel(copiedJobOfferUiRepresentation.value)
  console.info('position copied' + copiedJobOfferUiRepresentation.value.jobPosition)
}

function previousStep() {
  step.value = step.value - 1;
}

import OrganizationDataTranslator from "@/services/organization/OrganizationDataTranslator";
import OfferDataTranslator from "@/services/organization/offer/OfferDataTranslator";
import NotificationService from "@/services/notification/NotificationService";
import {useQuasar} from "quasar";
// import {useRouter} from "vue-router/dist/vue-router";
// import jobOfferService from "@/services/JobOfferService";
import PlanStandard from "@/components/common/add-offer/plans/PlanStandard";
import PlanBasic from "@/components/common/add-offer/plans/PlanBasic";
import PlanPremium from "@/components/common/add-offer/plans/PlanPremium";
import JobOfferDetails from "@/components/common/job-offers/JobOfferDetails";
import JobOfferPayment from "@/components/organization/job-offers/adding/JobOfferPayment";
import JobPositionTranslator from "@/services/offer/JobPositionTranslator";

// const router = useRouter()

const $q = useQuasar()

async function sendPostWithJobOffer() {
  let isCorrect = await validatePaymentData();
  console.info('is correct payment data ' + isCorrect)
  if (isCorrect) {
    OfferDataTranslator.mapSkillLevelsToModel(jobOfferData)
    jobOfferData.organization.type = OrganizationDataTranslator.translateFromPolishToCompanyType(jobOfferData.organization.type)
    jobOfferData.organization.industries = jobOfferData.organization.industries.map(industry => OrganizationDataTranslator.translateFromPolishToCompanyIndustry(industry));
    jobOfferData.organization.size = OrganizationDataTranslator.translateFromPolishToCompanySize(jobOfferData.organization.size)
    jobOfferData.organization.logoInBase64 = jobOfferData.organization.logoInBase64.split(',')[1];
    jobOfferData.seniority = OfferDataTranslator.translateFromPolishToExperienceLevel(jobOfferData.seniority)
    jobOfferData.jobPosition = JobPositionTranslator.translateToModel(jobOfferData.jobPosition)
    mapContractsTypeToModel(jobOfferData)
    mapLanguagesToModel(jobOfferData)
    mapLocationsToModel(jobOfferData)
    mapQuestionsToModel(jobOfferData)
    JsonBlankFieldsCleaner.removeEmptyFields(jobOfferData)
    const offerDto = {
      organization: jobOfferData.organization,
      offer: jobOfferData,
      accountDetails: accountDetails,
    };
    console.info(JSON.stringify(offerDto))
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(offerDto),
    };

    fetch(process.env.VUE_APP_BACKEND_URL + '/job-offers', requestOptions)
        .then(async (response) => {
              console.log(response.status);
              if (response.status === 201) {
                NotificationService.showNotification($q, 'positive', 'Dodano ofertę')
                response.json().then(body => {
                  console.info(body.jobOffer.id)
                  addedJobOfferId.value = body.jobOffer.id;
                })
                step.value = 5;
              } else if (response.status === 400) {
                const json = await response.json()
                if (json.detailedMessage.includes('Wrong file format.')) {
                  NotificationService.showNotification($q, 'negative', 'Błędny format logo, wspierane formaty .jpg .png .jpeg')
                }
              }
            }
        )
  }

}

const addedJobOfferId = ref(null)

// function goToJobOfferInAllOffers() {
//   let id = addedJobOfferId.value;
//   waitForJobOffer(id)
// }
//
//
// async function waitForJobOffer(id) {
//   const maxWaitTime = 10000; // Maximum wait time in milliseconds (5 seconds)
//   const pollInterval = 500; // Polling interval in milliseconds
//
//   let elapsedTime = 0;
//
//   while (elapsedTime < maxWaitTime) {
//     jobOfferService.getAllJobOffers().then(response => {
//       return response.json().then((data) => {
//         let isAdded = false;
//         data.content.forEach(offer => {
//           console.info(offer)
//           if (offer.id === id) {
//             isAdded = true;
//           }
//         })
//         if (isAdded) {
//           router.push({name: 'JobOffersMainBoard', params: {id}});
//           return;
//         }
//       })
//     })
//     await new Promise(resolve => setTimeout(resolve, pollInterval));
//     elapsedTime += pollInterval;
//     console.error('Timeout reached. Unable to fetch job offer within the specified time.');
//   }
// }


function mapContractsTypeToModel(jobOfferData) {
  jobOfferData.contracts.forEach(contract => {
    contract.type = OfferDataTranslator.translateFromPolishToContractType(contract.type)
  })
}

function mapLanguagesToModel(jobOfferData) {
  jobOfferData.languages.forEach(language => {
    language.language = OfferDataTranslator.translateFromPolishToLanguage(language.language)
    language.proficiency = OfferDataTranslator.mapFromLanguageRateToProficiency(language.proficiency)
  })
}

function mapLocationsToModel(jobOfferData) {
  jobOfferData.locations = jobOfferData.locations.map(location => {
    // const translatedLocation = OfferDataTranslator.translateFromPolishToCity(location);
    //TODO fix locations
    //TODO dodac walidacje do lokalizacji
    //TODO nie rzucac wyjatku jak nie moze znalezc?
    console.info(location)
    return {
      city: "Lublin",
      county: "Lublin",
      voivodeship: "lubelskie"
    };
  });
}

function mapQuestionsToModel(jobOfferData) {
  jobOfferData.questions.forEach(question => {
    question.answerType = OfferDataTranslator.translateFromPolishToQuestionAnswerType(question.answerType);
  })
}

function getBasicPlan() {
  console.info("basic" + formTemplateData.value.plans)
  return JobOfferPlansService.getBasicPlan(formTemplateData.value.plans)
}

function getStadardPlan() {
  console.info("standard" + formTemplateData.value.plans)
  return JobOfferPlansService.getStandardPlan(formTemplateData.value.plans)
}

function getPremiumPlan() {
  console.info("premium" + formTemplateData.value.plans)
  return JobOfferPlansService.getPremiumPlan(formTemplateData.value.plans)
}

function calculateWidthBasedOnStep() {
  if (step.value === 4) {
    return 'width: 45%'
  } else if (step.value === 1) {
    return 'width: 70%'
  } else {
    return 'width: 50%'
  }
}

const paymentDataRef = ref(null)

async function validatePaymentData() {
  let validate = paymentDataRef.value.validate();
  return await validate
}
</script>

<style lang="scss" scoped>
//.my-stepper {
//  width: 950px;
//}

.q-chip {
  background: white;
}

html, body {
  height: 100vh;
  background-color: #dde5ee;
}

.scrollableDiv {
  overflow-y: auto;
  width: 100%;
  flex: 1;
}
</style>
