<template>
  <div>
    <q-card bordered class="q-mb-lg" style="border-radius: 9px;">
      <div class="q-mx-lg q-mb-sm">
        <q-separator></q-separator>
      </div>
      <div class="row full-width">
        <q-card-section style="max-width: 170px" class="col q-mb-md">
          <div class="text-center">
            <q-avatar v-if="isAvatarReady" class="text-center q-mt-none q-ma-xs q-ml-md" size="85px"
                      style="background-color: #c9d7ec">
              <q-img v-if="userAvatar && userAvatar.contentType && userAvatar.file" fit="fill"
                     v-bind:src="'data:' + userAvatar.contentType + ';base64,' + userAvatar.file"
                     style="width: 100%; height: 100%;"
              />
              <q-img v-else fit="contain"
                     v-bind:src="require('@/assets/blank-avatar-4.png')"
                     style="width: 100%; height: 100%"/>
            </q-avatar>
            <q-avatar v-else class="text-center q-mt-none q-ma-xs q-ml-md" size="85px"
                      style="background-color: #c9d7ec">
              <q-img fit="contain"
                     style="width: 100%; height: 100%"/>
            </q-avatar>
          </div>
          <div class="row q-mt-xs">
            <q-btn class="q-mt-xs col q-pl-none q-pr-none" flat size="sm" color="primary" @click="changeAvatar()"
                   label="zmień"/>
            <q-btn class="q-mt-xs col q-pl-none q-pr-none" flat size="sm" color="red-4" @click="deleteAvatar()"
                   label="usuń"/>
          </div>
        </q-card-section>

        <div class="col">
          <div class="row">
            <q-card-section class="row col q-pb-sm">
              <q-form ref="childForm" class="row full-width" greedy>
                <div class="full-width col">
                  <q-input class="q-pa-sm q-pb-md col" color="primary" stack-label outlined bg-color="white"
                           v-model="user.firstName"
                           label-color="primary"
                           :rules="[ val =>val !== null && val.length !== 0 || 'Imię jest wymagane' ]"
                           label="Imię"
                           dense>
                    <template v-slot:append>
                      <q-avatar>
                        <q-icon
                            name="sym_o_title"
                            size="1rem"/>
                      </q-avatar>
                    </template>
                  </q-input>
                </div>
                <div class="full-width col">
                  <q-input class="q-pa-sm q-pb-md col"
                           color="primary" stack-label outlined bg-color="white"
                           v-model="user.lastName"
                           label-color="primary"
                           label="Nazwisko"
                           :rules="[ val =>val !== null && val.length !== 0 || 'Nazwisko jest wymagane' ]"
                           dense>
                    <template v-slot:append>
                      <q-avatar>
                        <q-icon
                            name="sym_o_title"
                            size="1rem"/>
                      </q-avatar>
                    </template>
                  </q-input>
                </div>
                <div class="full-width col">
                  <q-input class="q-pa-sm q-pb-md col"
                           color="primary" stack-label outlined bg-color="white"
                           v-model="user.position"
                           label-color="primary"
                           label="Stanowisko"
                           :rules="[ val =>val !== null && val.length !== 0 || 'Stanowisko jest wymagane' ]"
                           dense>
                    <template v-slot:append>
                      <q-avatar>
                        <q-icon
                            name="sym_o_title"
                            size="1rem"/>
                      </q-avatar>
                    </template>
                  </q-input>
                </div>
              </q-form>
            </q-card-section>
          </div>
          <div class="row q-mx-md">
            <div class="col">
              <q-input class="q-pa-sm q-pb-md col"
                       color="primary" stack-label outlined bg-color="white"
                       v-model="user.phoneNumber"
                       label-color="primary"
                       label="Numer telefonu"
                       dense>
                <template v-slot:append>
                  <q-avatar>
                    <q-icon
                        name="sym_o_title"
                        size="1rem"/>
                  </q-avatar>
                </template>
              </q-input>
            </div>
            <div class="full-width col">
              <q-input class="q-pa-sm q-pb-md col"
                       color="primary" stack-label outlined bg-color="white"
                       v-model="user.email"
                       disable
                       label-color="primary"
                       label="E-mail"
                       :rules="[ val => val !== null && val.length <= 40 || 'Please use maximum 40 characters' ]" dense>
                <template v-slot:append>
                  <q-avatar>
                    <q-icon
                        name="sym_o_title"
                        size="1rem"/>
                  </q-avatar>
                </template>
              </q-input>
            </div>
          </div>
        </div>
      </div>

    </q-card>
  </div>

</template>
<script setup>
import {computed, defineEmits, defineExpose, defineProps, onBeforeMount, ref} from "vue";
import AuthenticationService from "@/services/AuthenticationService";
import NotificationService from "@/services/notification/NotificationService";
import OrganizationUsersService from "@/services/organization/OrganizationUsersService";

import {useQuasar} from "quasar";

const userAvatar = ref(null)

const props = defineProps({
  formTemplateData: Object,
  modelValue: Object
})

const user = computed({
  get: () => props.modelValue,
  set: (val) => emit('update:modelValue', val),
});

const emit = defineEmits(['update:modelValue'])

onBeforeMount(() => {
  initUserAvatar();
});

const $q = useQuasar()

const avatar = ref(null);
const isAvatarReady = ref(false)

function changeAvatar() {
  let input = document.createElement('input');
  input.type = 'file';
  input.accept = '.jpg, .jpeg, .png,'; // Specify accepted file extensions
  input.onchange = () => {
    let files = Array.from(input.files);
    console.info(files[0])
    if (files.length > 0) {
      const file = files[0];
      const reader = new FileReader();

      reader.onload = () => {
        var parts = reader.result.split(',');
        avatar.value = parts[1].trim(); //
        OrganizationUsersService.changeAvatar(user.value.id, avatar.value, file.name).then(() => {
          user.value = AuthenticationService.getAuthenticatedUser().then(data => {
            data.json().then(data => {
              user.value = data
              NotificationService.showNotification($q, 'positive', 'Zmieniono avatar')
              initUserAvatar();
            })
          })
        })
      };

      reader.readAsDataURL(file);
    }
  };

  input.click();
}


function initUserAvatar() {
  OrganizationUsersService.getAvatar(user.value.id)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(`Failed to fetch avatar. Status: ${response.status}`);
        }
      })
      .then(json => {
        userAvatar.value = json;
      })
      .catch(error => {
        console.error(error);
      });
  isAvatarReady.value = true
}


function deleteAvatar() {
  OrganizationUsersService.deleteAvatar(user.value.id)
      .then(response => {
        console.info(response.status)
        if (response.status === 204) {
          userAvatar.value = {}
          NotificationService.showNotification($q, 'negative', 'Usunięto avatar')
        }
      })
      .catch(error => {
        // Handle errors here
        console.error("Error deleting avatar:", error);
      });
}

defineExpose({
  validate
})

const childForm = ref(null)

async function validate() {
  let validate = await childForm.value.validate();
  return validate;
}
</script>