<template>
  <q-dialog v-model="showDialog" persistent>
    <q-card style="border-radius: 9px">
      <q-card-section class="row items-center q-pb-none q-mx-md q-mt-sm">
        <q-icon name="fa-solid fa-user-xmark" color="primary" size="md" class="q-mr-md" text-color="white"/>
        <span class="q-ml-sm col">Czy na pewno chcesz usunąć konto? Spowoduje to utrate wszystkich danych włącznie z historią aplikacji.</span>
      </q-card-section>

      <q-card-actions align="right" class="q-mr-sm">
        <q-btn flat label="Anuluj" color="grey-8" no-caps v-close-popup/>
        <q-btn flat label="Usuń" color="primary" no-caps v-close-popup @click="sendRemovalEmit()"/>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup>
import {computed, defineEmits, defineProps} from "vue";

const props = defineProps({
  showDialog: Boolean
})

const showDialog = computed({
  get: () => props.showDialog,
  set: (val) => emit('update:showDialog', val),
});
const emit = defineEmits(['remove:account'])

function sendRemovalEmit() {
  emit('remove:account')
}
</script>