class ApplicantDataService {

    constructor(url) {
        this.url = url;
    }

    async updateData(id, firstName, lastName, phoneNumber, contactFrom, contactTo) {
        try {
            const requestOptions = {
                credentials: "include",
                method: 'PUT',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    firstName: firstName,
                    lastName: lastName,
                    phoneNumber: phoneNumber,
                    preferredContactTime: {
                        from: contactFrom,
                        to: contactTo
                    }
                }),
            };
            console.info(requestOptions.body)
            console.info(id)
            return await fetch(this.url + '/applicants/' + id, requestOptions);
        } catch (error) {
            console.error('Error during request:', error);
            throw error;
        }
    }

    async changeAvatar(userId, fileInBase64, fileName) {
        try {
            const cacheKey = `avatar-${userId}`;
            const cacheName = 'avatar-cache';
            const cache = await caches.open(cacheName);
            await cache.delete(cacheKey);
            const requestOptions = {
                method: 'PUT',
                credentials: 'include',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({fileInBase64, fileName}),
            };
            return await fetch(this.url + '/applicants/' + userId + '/avatar', requestOptions);
        } catch (error) {
            console.error('Error during request:', error);
            throw error;
        }
    }

    async changePassword(userId, oldPassword, newPassword) {
        try {
            const requestOptions = {
                method: 'PUT',
                credentials: 'include',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({oldPassword, newPassword}),
            };
            return await fetch(this.url + '/applicants/' + userId + '/change-password', requestOptions);
        } catch (error) {
            console.error('Error during request:', error);
            throw error;
        }
    }

    async deleteAvatar(userId) {
        const cacheKey = `avatar-${userId}`;
        const cacheName = 'avatar-cache';

        try {
            const cache = await caches.open(cacheName);
            await cache.delete(cacheKey);
            const requestOptions = {
                method: 'DELETE',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            };

            return await fetch(this.url + '/applicants/' + userId + '/avatar', requestOptions);
        } catch (error) {
            console.error('Error during request:', error);
            throw error;
        }
    }

    async getAvatar(userId) {
        const cacheKey = `avatar-${userId}`;
        const cacheName = 'avatar-cache';

        try {
            const cache = await caches.open(cacheName);
            const cachedResponse = await cache.match(cacheKey);
            if (cachedResponse) {
                return cachedResponse;
            }
            const requestOptions = {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            };
            const response = await fetch(this.url + '/applicants/' + userId + '/avatar', requestOptions);
            if (response.ok) {
                await cache.put(cacheKey, response.clone());
            }
            return response;
        } catch (error) {
            console.error('Error during request:', error);
            throw error;
        }
    }

    async deleteAccount(userId) {
        try {
            const requestOptions = {
                method: 'DELETE',
                credentials: 'include',
                headers: {
                    "Content-Type": "application/json",
                },
            };
            return await fetch(this.url + '/applicants/' + userId, requestOptions);
        } catch (error) {
            console.error('Error during request:', error);
            throw error;
        }
    }


}

export default new ApplicantDataService(process.env.VUE_APP_BACKEND_URL);