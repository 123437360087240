<template>
  <div
       style="min-width: 168px; max-width: 168px;min-height: 168px; max-height: 168px">
    <q-card v-ripple class="cursor-pointer q-hoverable full-width full-height"
            style="border-radius: 9px; min-width: 168px; max-width: 168px;min-height: 168px; max-height: 168px;"
            @mouseover="handleMouseOver"
            @mouseout="handleMouseOut"
            @click="importData()">
      <div v-if="logoData === null && isBlank(organizationData.logoUrl)"  style="min-width: 168px; max-width: 168px;min-height: 168px; max-height: 168px">
        <q-card-section v-if="isMouseOnCard" class="text-center">
          <q-icon name="add_photo_alternate" size="100px" class="text-secondary q-mt-sm"></q-icon>
        </q-card-section>
        <q-card-section v-else class="text-center">
          <q-icon name="add_photo_alternate" size="100px" class="text-grey-7 q-mt-sm"></q-icon>
        </q-card-section>
      </div>
      <div v-if="!isBlank(organizationData.logoUrl) && logoData === null" class="q-pa-none full-width full-height"
           style=" display: flex;
  flex-direction: column;
  justify-content: center;  /* Centering y-axis */
  align-items :center">
        <q-img
            style="border-radius: 9px;"
            :ratio="4/4"
            :src="organizationData.logoUrl"
            fit="contain"/>
      </div>
      <div v-else class="q-pa-none full-width full-height" style=" display: flex;
  flex-direction: column;
  justify-content: center;  /* Centering y-axis */
  align-items :center">
        <q-img
            class="q-pa-none"
            :ratio="4/4"
            :src="logoData"
            fit="contain"/>
      </div>

      <span class="q-focus-helper">

        </span>
    </q-card>
  </div>
</template>

<script setup>


import {computed, defineProps, ref, defineEmits, onMounted} from "vue";

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  formTemplateData: Object,
  modelValue: Object
})

const organizationData = computed({
  get: () => props.modelValue,
  set: (val) => emit('update:modelValue', val),
});

const logoData = ref(null)

onMounted(() => {
  console.info(organizationData.value.logoUrl)
})

function isBlank(str) {
  return /^\s*$/.test(str);
}

function importData() {
  let input = document.createElement('input');
  input.type = 'file';
  input.accept = '.jpg, .jpeg, .png'; // Specify accepted file extensions
  input.onchange = () => {
    let files = Array.from(input.files);
    if (files.length > 0) {
      const file = files[0];
      const reader = new FileReader();

      reader.onload = () => {
        logoData.value = reader.result
        var parts = reader.result.split(',');
        organizationData.value.logoInBase64 = parts[1];
      };
      reader.readAsDataURL(file);
    }
  };

  input.click();
}

</script>