<template>
  <div class="row justify-center q-pt-lg q-mt-lg scrollableDiv" v-if="formTemplateData !== null">
    <q-form @submit="sendPostWithJobOffer()" greedy :style="calculateWidthBasedOnStep()">
      <q-stepper
          v-model="step"
          header-nav
          ref="stepper"
          color="primary"
          animated
          style="border-radius: 9px"
          class="my-stepper q-mb-lg">
        <q-step
            :name="1"
            title="Wybierz ofertę"
            active-color="cyan-7"
            icon="settings"
            :done="step > 1"
            :header-nav="step > 1"
        >
          <!--          <span class="text-grey-9">Do czasu zakończenia promocji oferty ogłoszeń można dodawać za darmo.</span>-->
          <div class="row justify-between q-mx-md">
            <plan-basic @step:next="nextStep()"
                        :plan="getBasicPlan()"
                        class="q-my-lg"
                        v-model:plan-for-offer="jobOfferData.plan"
                        style="border-color: #151273;
                         border-width: 1px">
            </plan-basic>

            <plan-standard @step:next="nextStep()"
                           :type="'Standard'"
                           :plan="getStadardPlan()"
                           class="q-my-lg"
                           style="border-color: #151273;
                            border-width: 2px"
                           v-model:plan-for-offer="jobOfferData.plan">
            </plan-standard>

            <plan-premium @step:next="nextStep()"
                          :type="'Premium'"
                          :plan="getPremiumPlan()"
                          class="q-my-lg"
                          style="border-color: #151273;
                           border-width: 1px"
                          v-model:plan-for-offer="jobOfferData.plan">

            </plan-premium>
          </div>
        </q-step>

        <q-step
            :name="2"
            active-color="cyan-7"
            title="Dane ogłoszenia"
            icon="fa-regular fa-file-lines"
            :done="step > 2"
            :header-nav="step > 2"
        >
          <job-offer-form :form-template-data="formTemplateData"
                          v-model="jobOfferData"
                          @step:next="nextStep"
                          @step:previous="previousStep"
                          :show-buttons="true"
          >

          </job-offer-form>
        </q-step>
        <q-step
            :name="3"
            title="Podgląd"
            active-color="cyan-7"
            icon="fa-regular fa-eye"
            :done="step > 3"
            :header-nav="step > 3"
        >
          <q-card flat class="q-mx-sm q-mt-sm q-mb-lg full-width"
                  style="border-radius: 9px;background-color: rgba(229,237,246,0.82);">
            <q-card-section>
              <my-text class="text-white">
                <span class="text-weight-bold text-primary" style="font-size: 15px">Podgląd</span> <span
                  class="text-grey-8">
                Twojego ogłoszenia. Jeśli chcesz poprawić wybrane dane, wróć do poprzedniego kroku.
      </span>
              </my-text>
            </q-card-section>
          </q-card>
          <job-offer-details
              v-if="copiedJobOfferUiRepresentation !==null"
              :show-apply="false"
              :padding-top="0"
              :done="step > 4"
              :job-offer="copiedJobOfferUiRepresentation">
          </job-offer-details>
          <q-stepper-navigation class="q-pt-none">
            <div class="q-my-md">
              <q-separator></q-separator>
            </div>
            <q-btn color="cyan-7" no-caps dense outline class="q-px-md"
                   label="Przejdź do płatności"
                   @click="nextStep()"/>
            <q-btn flat @click="previousStep()" color="primary" no-caps dense label="Powrót" class="q-ml-md"/>
          </q-stepper-navigation>
        </q-step>
        <q-step
            :name="4"
            title="Płatność"
            active-color="cyan-7"
            icon="fa-regular fa-wallet"
            :header-nav="step > 4"
        >
          <div class="col row q-mb-md full-width">
            <job-offer-payment
                ref="paymentDataRef"
                :plan="jobOfferData.plan"
                :is-logged-organization-user="true"
                v-model:payment="jobOfferData.payment">
            </job-offer-payment>
          </div>
          <q-stepper-navigation class="q-pt-none">
            <div class="q-mb-md">
              <q-separator></q-separator>
            </div>
            <q-btn type="submit" color="cyan-7" no-caps dense outline class="q-px-md" label="Przejdź do Przelewy24"/>
            <q-btn flat @click="previousStep()" color="primary" no-caps dense label="Powrót" class="q-ml-md"/>
          </q-stepper-navigation>
        </q-step>
      </q-stepper>
    </q-form>
  </div>
</template>

<script setup>
import {onBeforeMount, reactive, ref} from "vue"
import JobOfferForm from "@/components/common/add-offer/job-offer/JobOfferForm";
import _ from 'lodash';

onBeforeMount(() => {
  organizationService.getOrganizationInfo().then(response => response.json().then(body => {
    jobOfferData.organization = body;
    //TODO rename to name or company name on API level
    jobOfferData.organization.companyName = body.name
  }))
  let clonedJobOfferId = router.currentRoute.value.params.id;
  if (clonedJobOfferId) {
    JobOfferService.getJobOffer(clonedJobOfferId).then(
        response => {
          response.json().then(jobOffer => {
            jobOfferData.organization.companyName = jobOffer.organization.name
            jobOfferData.title = jobOffer.title;
            jobOfferData.jobPosition = jobOffer.jobPosition;
            jobOfferData.contracts = jobOffer.publication.contracts
            OfferDataTranslator.translateContractsToPolish(jobOffer.publication.contracts);
            jobOfferData.description = jobOffer.description
            jobOfferData.questions.forEach(question => {
              question.answerType = OfferDataTranslator.translateAnswerTypeToPolish(question.answerType);
            });
            jobOfferData.seniority = OfferDataTranslator.translateFromSeniorityToPolish(jobOffer.seniority);
            jobOfferData.languages = OfferDataTranslator.translateArrayLanguagesToPolish(jobOffer.languages);
            jobOfferData.locations = LocationTranslator.getCityArray(jobOffer.locations);
            jobOfferData.skills = JobOfferSkillsTranslator.translateLevelFromEnumToIntegerLevel(jobOffer.skills)
            jobOfferData.operatingModes = jobOffer.operatingModes
            jobOfferData.workTypes = jobOffer.workTypes
            jobOfferData.jobApplicationInfo = jobOffer.jobApplicationInfo
            organizationService.getOrganizationInfo().then(response => {
              response.json().then(body => {
                jobOfferData.payment.billingData = body.billingData
              });
              jobOfferData.payment.email = "some@email.xom"
              //TODO
              jobOfferData.payment.method = "BLIK"
            })
          })
        }
    )
  }

  getFormtTemplateData().then(data => {
    formTemplateData.value = data;
  });

})
const formTemplateData = ref(null)

function getFormtTemplateData() {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json"
    },
  };
  return fetch(process.env.VUE_APP_BACKEND_URL + "/job-offers/form-template-data", requestOptions)
      .then((response) => {
        return response.json().then((data) => {
          return data
        }).catch(() => {
          // console.log(err);
        })
      })
}

const step = ref(1)
const jobOfferData = reactive({
  title: "tytul1",
  seniority: "",
  jobPosition: "",
  contracts: [{type: null, salary: {from: null, to: null, currency: null}, id: 0}],
  questions: [],
  plan: {},
  description: '<strong>Dodaj informacje o:</strong> <ul>\n' +
      '  <li>Firmie</li>\n' +
      '  <li>Projekcie</li>\n' +
      '  <li>Obowiązkach</li>\n' +
      '  <li>Benefitach</li>\n' +
      '</ul>',
  operatingModes: [],
  skills: [],
  languages: [],
  locations: [],
  addresses: [],
  workTypes: [],
  planType: "STANDARD",
  contactEmails: [],
  active: true,
  jobApplicationInfo: {
    externalApplicationLink: null,
    applicationType: ref("INTERNAL")
  },
  payment: {
    email: "",
    payNow: true,
    billingData: {
      companyName: "",
      nip: "",
      address: {
        street: "",
        streetNumber: "",
        flatNumber: "",
        city: "",
        zipCode: ""
      }
    },
    promoCode: ""
  }
})

const copiedJobOfferUiRepresentation = ref(null)

function nextStep() {
  step.value = step.value + 1;
  if (isStepForJobOfferDetails()) {
    mapValuesToRequestModel()
  }
}

function isStepForJobOfferDetails() {
  return step.value === 3
}

function mapValuesToRequestModel() {
  copiedJobOfferUiRepresentation.value = _.cloneDeep(jobOfferData);
  OfferDataTranslator.mapSkillLevelsToModel(copiedJobOfferUiRepresentation.value)
  console.info(JSON.stringify("payment of copied " + JSON.stringify(copiedJobOfferUiRepresentation.value.payment)))
  console.info(JSON.stringify("payment offer data " + JSON.stringify(jobOfferData.payment)))
  copiedJobOfferUiRepresentation.value.seniority = OfferDataTranslator.translateFromPolishToExperienceLevel(copiedJobOfferUiRepresentation.value.seniority)
  copiedJobOfferUiRepresentation.value.jobPosition = JobPositionTranslator.translateToModel(copiedJobOfferUiRepresentation.value.jobPosition)
  mapContractsTypeToModel(copiedJobOfferUiRepresentation.value)
  mapLanguagesToModel(copiedJobOfferUiRepresentation.value)
  mapLocationsToModel(copiedJobOfferUiRepresentation.value)
  mapQuestionsToModel(copiedJobOfferUiRepresentation.value)
}

function previousStep() {
  step.value = step.value - 1;
}

function calculateWidthBasedOnStep() {
  if (step.value === 3) {
    return 'width: 45%'
  } else if (step.value === 1) {
    return 'width: 70%'
  } else {
    return 'width: 50%'
  }
}

import OfferDataTranslator from "@/services/organization/offer/OfferDataTranslator";
import {useRouter} from "vue-router/dist/vue-router";
import OrganizatioOfferManagementService from "@/services/organization/offer/OrganizatioOfferManagementService";
import JobOfferPlansService from "@/services/organization/offer/JobOfferPlansService";
import PlanPremium from "@/components/common/add-offer/plans/PlanPremium";
import PlanStandard from "@/components/common/add-offer/plans/PlanStandard";
import PlanBasic from "@/components/common/add-offer/plans/PlanBasic";
import JobOfferService from "@/services/JobOfferService";
import JobOfferSkillsTranslator from "@/services/offer/JobOfferSkillsTranslator";
import LocationTranslator from "@/services/LocationTranslator";
import JobOfferDetails from "@/components/common/job-offers/JobOfferDetails";
import JobOfferPayment from "@/components/organization/job-offers/adding/JobOfferPayment";
import organizationService from "@/services/organization/OrganizationDataService";
import JsonBlankFieldsCleaner from "@/services/common/JsonBlankFieldsCleaner";
import JobPositionTranslator from "@/services/offer/JobPositionTranslator";

const router = useRouter()

async function sendPostWithJobOffer() {
  let isPaymentDataCorrect = await validatePaymentData();
  if (isPaymentDataCorrect) {
    mapValuesToRequestModel()
    JsonBlankFieldsCleaner.removeEmptyFields(copiedJobOfferUiRepresentation.value)
    OrganizatioOfferManagementService.addJobOffer(copiedJobOfferUiRepresentation.value).then(async (response) => {
          if (response.status === 201) {
            response.json().then(body => {
              window.location.href = body.payment.url;
            })
          }
        }
    )
  }
}

function mapContractsTypeToModel(jobOfferData) {
  jobOfferData.contracts.forEach(contract => {
    contract.type = OfferDataTranslator.translateFromPolishToContractType(contract.type)
  })
}

function mapLanguagesToModel(jobOfferData) {
  jobOfferData.languages.forEach(language => {
    language.language = OfferDataTranslator.translateFromPolishToLanguage(language.language)
    language.proficiency = OfferDataTranslator.mapFromLanguageRateToProficiency(language.proficiency)
  })
}

function mapLocationsToModel(jobOfferData) {
  jobOfferData.locations = jobOfferData.locations.map(location => {
    // const translatedLocation = OfferDataTranslator.translateFromPolishToCity(location);
    //TODO fix locations
    //TODO dodac walidacje do lokalizacji
    //TODO nie rzucac wyjatku jak nie moze znalezc?
    console.info(location)
    return {
      city: "Lublin",
      county: "Lublin",
      voivodeship: "lubelskie"
    };
  });
}


function mapQuestionsToModel(jobOfferData) {
  jobOfferData.questions.forEach(question => {
    question.answerType = OfferDataTranslator.translateFromPolishToQuestionAnswerType(question.answerType);
  })
}

function getBasicPlan() {
  return JobOfferPlansService.getBasicPlan(formTemplateData.value.plans)
}

function getStadardPlan() {
  return JobOfferPlansService.getStandardPlan(formTemplateData.value.plans)
}

function getPremiumPlan() {
  return JobOfferPlansService.getPremiumPlan(formTemplateData.value.plans)
}

const paymentDataRef = ref(null)


async function validatePaymentData() {
  let validate = paymentDataRef.value.validate();
  return await validate
}
</script>

<style lang="scss" scoped>
//.my-stepper {
//  width: 950px;
//}

.q-chip {
  background: white;
}

html, body {
  height: 100vh;
  background-color: rgb(217, 225, 238);
}

.scrollableDiv {
  overflow-y: auto;
  width: 100%;
  flex: 1;
}
</style>
