§<template>
  <q-card bordered flat class="full-width" style="border-radius: 9px; border-width: 0px; border-color: white">
    <!--    <div class="q-px-md q-pt-none q-pb-none">-->
    <!--      <q-separator></q-separator>-->
    <!--    </div>-->
    <q-card-section class="row q-pt-none q-pa-none q-pl-sm q-pb-xs intro-bg full-width">
      <div class="row col q-pa-sm justify-between">
        <div class="q-pl-none q-mt-sm bg-white" style="border-radius: 6px;">
          <q-img
              style="width: 105px;height: 105px; border-radius: 6px;margin: 1px"
              v-bind:src="props.logo"
              fit='fill'
              :ratio="1"
          />
        </div>
        <div class="q-mx-lg q-mt-lg col">
          <div style="min-height: 50px">
            <my-text class="text-bold" style="font-size: 20px;">
              {{ props.title }}
            </my-text>
            <q-chip v-if="!isVisible.value" class="text-white text-bold q-ml-md q-mt-none q-mb-none" square label="Ukryta"
                    color="negative"></q-chip>
          </div>
          <div>
            <my-text class="q-pt-md" style="font-size: 15px;">
              <company-text class="text-dark">
                {{ props.companyName }}
              </company-text>
            </my-text>
          </div>
        </div>
        <div class="q-pl-md row q-pa-xs">
          <div class="row q-pr-sm q-pt-none">
            <div class="q-pt-none">
              <q-btn v-if="isNotBlank(webPageUrl)" outline color="white" round flat aria-setsize="12px"
                     @click="openOrganizationPage(webPageUrl)">
                <q-icon size="sm" name="mdi-web" color="white"/>
              </q-btn>
              <q-btn v-if="isNotBlank(instagramPageUrl)" outline color="white" round flat aria-setsize="12px"
                     @click="openOrganizationPage(instagramPageUrl)">
                <q-icon size="sm" name="mdi-instagram" color="white"/>
              </q-btn>
              <q-btn v-if="isNotBlank(facebookPageUrl)" outline color="white" round flat aria-setsize="12px"
                     @click="openOrganizationPage(facebookPageUrl)">
                <q-icon size="sm" name="mdi-facebook" color="white"/>
              </q-btn>
            </div>
          </div>
          <div>
            <q-btn outline color="white" round flat aria-setsize="12px"
                   @click="copyLinkToOffer(facebookPageUrl)">
              <q-icon size="sm" name="mdi-content-copy" color="accent"/>
            </q-btn>
          </div>
        </div>

        <q-separator color="white"></q-separator>
        <position-data class="q-pt-none full-width"
                       :job-position="jobPosition"
                       :seniority-level-name="seniorityLevelName"
                       :modes="operatingModes"
                       :workTypes="workTypes"
                       :branding="branding"
        >

        </position-data>
      </div>
    </q-card-section>
  </q-card>
  <div class="q-mt-md">
    <!--    <q-card class="q-mt-sm" style="width: 100%">-->
    <!--      <q-card-section>-->
    <!--        <additional-intro-data :operating-modes="operatingModes"></additional-intro-data>-->
    <!--      </q-card-section>-->
    <!--    </q-card>-->
    <q-card bordered flat class="q-mt-sm contracts-bg"
            style="width: 100%; border-radius: 9px; border-width: 0px; border-color: white">
      <q-card-section>
        <offered-contracts :contracts="contracts"></offered-contracts>
      </q-card-section>
    </q-card>
  </div>
</template>

<script setup>
import {computed, defineEmits, defineProps} from 'vue';
import OfferedContracts from "@/components/common/job-offers/job-offer-details/intro-card/OfferedContracts";
import PositionData from "@/components/common/job-offers/job-offer-details/intro-card/PositionData";
// import AdditionalIntroData from "@/components/common/job-offers/job-offer-details/intro-card/AdditionalIntroData";

// const hoverStyle = ref({
//   color: 'red',
//   // Add any other styles you want to apply on hover
// });
function isNotBlank(str) {
  if (str === null || str === undefined) {
    return false;
  }

  // Remove leading and trailing spaces using trim()
  const trimmedStr = str.trim();

  // Check if the resulting string is empty or not
  return trimmedStr !== '';
}

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  logo: {
    type: String,
    required: true,
  },
  companyName: {
    type: String,
    required: true,
  },
  contracts: {
    type: Array,
    required: true
  },
  webPageUrl: {
    type: String,
    required: true
  },
  facebookPageUrl: {
    type: String,
    required: true
  },
  instagramPageUrl: {
    type: String,
    required: true
  },
  jobPosition: {
    type: String,
    required: true
  },
  seniorityLevelName: {
    type: String,
    required: true
  },
  operatingModes: {
    type: Array,
    required: true
  },
  workTypes: {
    type: Array,
    required: true
  },
  id: {
    type: String,
    required: true
  },
  modelValue: {
    type: Object,
    required: true
  },
  branding: {
    type: Object,
    required: true
  }
});
const emit = defineEmits(['update:modelValue'])

const isVisible = computed({
  get: () => props.modelValue,
  set: (val) => emit('update:modelValue', val),
});


function openOrganizationPage(page) {
  window.open(page, '_blank')

}

import {useQuasar} from "quasar";

const $q = useQuasar()
import NotificationService from "@/services/notification/NotificationService";

function copyLinkToOffer() {
  setClipboardText(process.env.APP_VUE_DOMAIN + '/job-offers-main-board/' + props.id)
  NotificationService.showNotificationWithPosition($q, 'positive', 'Skopiowano link do oferty', 'top-right')
}

function setClipboardText(text) {
  // Create a text area element to temporarily hold the text
  const textArea = document.createElement("textarea");
  textArea.value = text;

  // Set the text area to be invisible
  textArea.style.position = "fixed";
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.opacity = "0";

  // Append the text area to the DOM
  document.body.appendChild(textArea);

  // Select the text inside the text area
  textArea.select();

  try {
    // Copy the selected text to the clipboard
    document.execCommand("copy");
  } catch (err) {
    console.error("Failed to copy text: ", err);
  }

  // Remove the temporary text area from the DOM
  document.body.removeChild(textArea);
}
</script>
<style scoped lang="scss">
//.my-card {
//  margin: 5px auto;
//}

my-text {
  color: white;
}

company-text {
  color: white;
}

.intro-bg {
  background: linear-gradient(45deg, $primary, $light-blue-11)
}

.contracts-bg {
  background: linear-gradient(45deg, $primary, $light-blue-11)
}

</style>