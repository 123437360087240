class JobPositionTranslator {
    constants = {
        BACKEND: "Backend Development",
        FRONTEND: "Frontend Development",
        FULLSTACK: "Fullstack Development",
        MOBILE: "Mobile Development",
        TESTING: "Testing",
        DEV_OPS: "DevOps",
        SECURITY: "Security",
        UX_UI: "UX/UI Design",
        AI: "Artificial Intelligence",
        DATA: "Data Science",
        GAMING: "Gaming",
        IT_ADMINISTRATION: "IT Administration",
        IT_SUPPORT: "IT Support",
        EMBEDDED: "Embedded Systems",
        ERP: "ERP",
        PRODUCT_MANAGEMENT: "Product Management",
        PROJECT_MANAGEMENT: "Project Management",
        BUSINESS_INTELLIGENCE: "Business Intelligence",
        BUSINESS_ANALYSIS: "Business Analysis",
        AGILE: "Agile Methodology",
        OTHER: "Other"
    };

    translated = {
        "Backend Development": "BACKEND",
        "Frontend Development": "FRONTEND",
        "Fullstack Development": "FULLSTACK",
        "Mobile Development": "MOBILE",
        "Testing": "TESTING",
        "DevOps": "DEV_OPS",
        "Security": "SECURITY",
        "UX/UI Design": "UX_UI",
        "Artificial Intelligence": "AI",
        "Data Science": "DATA",
        "Gaming": "GAMING",
        "IT Administration": "IT_ADMINISTRATION",
        "IT Support": "IT_SUPPORT",
        "Embedded Systems": "EMBEDDED",
        "ERP": "ERP",
        "Product Management": "PRODUCT_MANAGEMENT",
        "Project Management": "PROJECT_MANAGEMENT",
        "Business Intelligence": "BUSINESS_INTELLIGENCE",
        "Business Analysis": "BUSINESS_ANALYSIS",
        "Agile Methodology": "AGILE",
        "Other": "OTHER"
    };


    translateConstant(constant) {
        return this.constants[constant] || "Unknown";
    }

    translateToModel(translated) {
        return this.translated[translated];
    }
}

export default new JobPositionTranslator();
