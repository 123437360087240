class OrganizationDataTranslator {
    constructor() {
        this.companyTypesDisctionary = {
            CORPORATION: "Korporacja",
            SOFTWARE_HOUSE: "Software house",
            SOFTWARE_PRODUCT: "Software product",
            TECH_STARTUP: "Startup technologiczny",
            OTHER: "Inny"
        };
        this.companyIndustriesDictionary = {
            ACCOUNTING: "Księgowość",
            ADVERTISING: "Reklama",
            ADTECH: "Technologie reklamowe",
            AEROSPACE: "Przemysł lotniczy",
            AGRICULTURE: "Rolnictwo",
            ARTIFICIAL_INTELLIGENCE: "Sztuczna inteligencja",
            AUTOMOTIVE: "Motoryzacja",
            AVIATION: "Lotnictwo",
            BANKING: "Bankowość",
            BIG_DATA: "Big Data",
            BIOTECHNOLOGY: "Biotechnologia",
            BLOCKCHAIN: "Blockchain",
            BUSINESS_INTELLIGENCE: "Inteligencja biznesowa",
            BUSINESS_PROCESS_AUTOMATION: "Automatyzacja procesów biznesowych",
            BUSINESS_SERVICES: "Usługi biznesowe",
            CLOUD_SERVICES: "Usługi chmurowe",
            COMPUTER_HARDWARE: "Sprzęt komputerowy",
            COMPUTER_NETWORKS: "Sieci komputerowe",
            CONSULTING: "Doradztwo",
            CYBER_SECURITY: "Cyberbezpieczeństwo",
            DATA_ANALYTICS: "Analiza danych",
            DATA_STORAGE: "Przechowywanie danych",
            DESIGN: "Projektowanie",
            DIGITAL_MARKETING: "Marketing cyfrowy",
            DISTRIBUTION: "Dystrybucja",
            E_COMMERCE: "E-commerce",
            E_LEARNING: "E-learning",
            EDTECH: "Technologie edukacyjne",
            EDUCATION: "Edukacja",
            ELECTRONICS: "Elektronika",
            ENERGY: "Energia",
            ENGINEERING: "Inżynieria",
            ENTERTAINMENT: "Rozrywka",
            FILM: "Film",
            FINANCIAL: "Finanse",
            FINTECH: "Fintech",
            GAMING: "Gry komputerowe",
            GRAPHIC_DESIGN: "Projektowanie graficzne",
            HEALTH: "Zdrowie",
            HEALTHCARE: "Opieka zdrowotna",
            HEALTHTECH: "Technologie medyczne",
            HIGH_TECH: "Technologia wysokich technologii",
            INFORMATION_TECHNOLOGY: "Technologia informacyjna",
            INSURANCE: "Ubezpieczenia",
            IOT: "Internet Rzeczy (IoT)",
            IT_INFRASTRUCTURE: "Infrastruktura informatyczna",
            LEGAL: "Prawny",
            LOGISTICS: "Logistyka",
            MACHINE_LEARNING: "Uczenie maszynowe",
            MANUFACTURING: "Produkcja",
            MARKETING: "Marketing",
            MEDIA: "Media",
            MEDICAL: "Medyczny",
            MILITARY: "Wojskowy",
            MUSIC: "Muzyka",
            NANOTECHNOLOGY: "Nanotechnologia",
            NETWORK_SECURITY: "Bezpieczeństwo sieci",
            NON_PROFIT: "Non-profit",
            OUTSOURCING: "Outsourcing",
            QUANTUM_COMPUTING: "Obliczenia kwantowe",
            PHARMACEUTICAL: "Farmacja",
            PUBLIC_SECTOR: "Sektor publiczny",
            REAL_ESTATE: "Nieruchomości",
            RECRUITING: "Rekrutacja",
            RETAIL: "Handel detaliczny",
            ROBOTICS: "Robotyka",
            SALES: "Sprzedaż",
            SMART_CITY: "Miasto inteligentne",
            SOCIAL_NETWORKING: "Sieci społecznościowe",
            SOFTWARE_DEVELOPMENT: "Rozwój oprogramowania",
            SOFTWARE_HOUSE: "Dom oprogramowania",
            SPACE_TECHNOLOGY: "Technologia kosmiczna",
            TELECOMMUNICATIONS: "Telekomunikacja",
            TOURISM: "Turystyka",
            TRANSPORT: "Transport",
            VR_AR: "Rzeczywistość wirtualna",
            WEB_SERVICES: "Usługi internetowe",
            WEB_TOOLS: "Narzędzia internetowe",
            OTHER: "Inny"
        }

        this.companySizesDictionary = {
            MICRO: "1-10",
            SMALL: "10-100",
            MEDIUM: "100-300",
            LARGE: "300-1000",
            VERY_LARGE: "1000+",
        };
    }

    translateCompanyTypeToPolish() {
        return Object.values(this.companyTypesDisctionary);
    }

    translateFromPolishToCompanyType(translatedValue) {
        for (const key in this.companyTypesDisctionary) {
            if (this.companyTypesDisctionary[key] === translatedValue) {
                return key;
            }
        }
        return '';
    }

    translateFromCompanyTypeToPolish(type) {
        for (const key in this.companyTypesDisctionary) {
            if (key === type) {
                return this.companyTypesDisctionary[key];
            }
        }
        return '';
    }

    translateSizeToPolish(size) {
        for (const key in this.companySizesDictionary) {
            if (key === size) {
                return this.companySizesDictionary[key];
            }
        }
        return '';
    }

    translateIndustryToPolish(industry) {
        for (const key in this.companyIndustriesDictionary) {
            if (key === industry) {
                return this.companyIndustriesDictionary[key];
            }
        }
        return '';
    }

    translateCompanyIndustriesToPolish() {
        return Object.values(this.companyIndustriesDictionary);
    }

    translateCompanySizesToPolish() {
        return Object.values(this.companySizesDictionary);
    }

    translateFromPolishToCompanyIndustry(translatedValue) {
        for (const key in this.companyIndustriesDictionary) {
            if (this.companyIndustriesDictionary[key] === translatedValue) {
                return key;
            }
        }
        return 'UNKNOWN';
    }

    translateFromPolishToCompanySize(translatedValue) {
        for (const key in this.companySizesDictionary) {
            if (this.companySizesDictionary[key] === translatedValue) {
                return key;
            }
        }
        return 'UNKNOWN';
    }

}

export default new OrganizationDataTranslator();

