class ApplicationService {
    constructor(url) {
        this.url = url;
    }

    async addResume(uploadedFile) {
        try {
            const formData = new FormData();
            formData.append('resume', uploadedFile);
            formData.append('setToDefault', true);
            return fetch(process.env.VUE_APP_BACKEND_URL + "/applicants/" + localStorage.getItem('userId') + '/resumes', {
                method: 'POST',
                credentials: "include",
                body: formData,
            })
                .then(response => {
                    return response
                })
        } catch (error) {
            console.error(error);
            throw error
        }
    }

    async getResumes() {
        try {
            return fetch(process.env.VUE_APP_BACKEND_URL + "/applicants/" + localStorage.getItem('userId') + '/resumes', {
                method: 'GET',
                credentials: "include",
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Server response was not ok');
                    }
                    return response
                })
        } catch (error) {
            console.error(error);
            throw error
        }
    }

    async getApplications(queryParams) {
        try {
            return fetch(process.env.VUE_APP_BACKEND_URL + "/applicants/" + localStorage.getItem('userId') + '/applications?size=10000&' + queryParams.toString(), {
                method: 'GET',
                credentials: "include",
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Server response was not ok');
                    }
                    return response
                })
        } catch (error) {
            console.error(error);
            throw error
        }
    }

    async applyAsAnonymousUser(uploadedFile, email, applicantName, preferredContactTime, jobOfferId, answers) {
        try {
            const formData = new FormData();
            formData.append('resume', uploadedFile);
            formData.append('contactEmail', email);
            formData.append('applicantName', applicantName);
            formData.append('preferredContactTime', preferredContactTime);
            formData.append('answers', answers)
            return fetch(process.env.VUE_APP_BACKEND_URL + "/job-offers/" + jobOfferId + '/applications', {
                method: 'POST',
                body: formData,
            }).then(response => {
                    return response
                })
        } catch (error) {
            console.error(error);
            throw error
        }
    }

    async applyAsLoggedUser(resumeId, preferredContactTime, jobOfferId, answers) {
        try {
            const requestBody = {
                jobOfferId: jobOfferId,
                resumeId: resumeId,
                preferredContactTime: preferredContactTime,
                answers: answers
            };

            return await fetch(process.env.VUE_APP_BACKEND_URL + "/applicants/" + localStorage.getItem('userId') + '/applications', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: "include",
                body: JSON.stringify(requestBody),
            });
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async setResumeAsDefault(resumeId) {
        try {
            const requestBody = {
                resumeId: resumeId,
            };

            const response = await fetch(process.env.VUE_APP_BACKEND_URL + "/applicants/" + localStorage.getItem('userId') + '/resumes/default', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: "include",
                body: JSON.stringify(requestBody),
            });

            if (!response.ok) {
                throw new Error('Server response was not ok');
            }

            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
}

export default new ApplicationService(process.env.VUE_APP_BACKEND_URL);