class ApplicantObservedOffers {
    constructor(url) {
        this.url = url;
    }

    async getObservedOffers(applicantId) {
        console.info("app" + applicantId)
        try {
            const requestOptions = {
                method: 'GET',
                credentials: 'include',
                headers: {
                    "Content-Type": "application/json",
                }
            };
            return await fetch(this.url + '/applicants/' + applicantId + '/favorite-offers', requestOptions);
        } catch (error) {
            console.error('Error during request:', error);
            throw error;
        }
    }

    async deleteObservedOffers(applicantId, offerId) {
        console.info("app" + applicantId)
        try {
            const requestOptions = {
                method: 'DELETE',
                credentials: 'include',
                headers: {
                    "Content-Type": "application/json",
                }
            };
            return await fetch(this.url + '/applicants/' + applicantId + '/favorite-offers/' + offerId, requestOptions);
        } catch (error) {
            console.error('Error during request:', error);
            throw error;
        }
    }

    async addObservedOffers(applicantId, offerId) {
        console.info("app" + applicantId)
        try {
            const requestOptions = {
                method: 'POST',
                credentials: 'include',
                headers: {
                    "Content-Type": "application/json",
                }
            };
            return await fetch(this.url + '/applicants/' + applicantId + '/favorite-offers?jobOfferId=' + offerId, requestOptions);
        } catch (error) {
            console.error('Error during request:', error);
            throw error;
        }
    }
}

export default new ApplicantObservedOffers(process.env.VUE_APP_BACKEND_URL);