<template>
  <q-card bordered class="full-width col row" style="border-radius: 9px;">
    <q-card class="row q-pt-none q-pa-none q-pb-xs full-width">
      <q-card class="row q-mt-xs q-mx-xs q-pb-sm q-mb-xs" style="border-radius: 9px 9px 0 0; width: 100%"
              :style="calculateBackgroundGradient()">
        <div class="q-pl-none q-ma-sm" style="border-radius: 6px;">
          <q-img
              v-if="!isNotBlank(props.logoInBase64)"
              style="width: 50px;height: 50px; border-radius: 6px;margin: 1px"
              v-bind:src="props.logo"
              fit='fill'
              :ratio="1"
          />
          <q-img v-else
                 style="width: 50px;height: 50px; border-radius: 6px;margin: 1px"
                 v-bind:src="props.logoInBase64"
                 fit='fill'
                 :ratio="1"
          />
        </div>
        <div class="q-mx-md q-mt-none col">
          <div class="q-mb-sm q-pt-sm">
            <my-text class="text-bold" style="font-size: 14px;">
              {{ props.title }}
            </my-text>
            <q-chip v-if="props.isVisible" class="text-white text-bold q-ml-md q-mt-none" square label="Ukryta"
                    color="negative"></q-chip>
          </div>
          <div>
            <my-text class="q-pt-md" style="font-size: 13px;">
              <company-text :class="'text-grey-10'">
                {{ props.companyName }}
              </company-text>
            </my-text>
          </div>
        </div>
        <div class="q-pl-md row">
          <div class="row q-pr-none q-pt-none q-gutter-xs">
            <div class="q-pt-none">
              <q-btn v-if="isNotBlank(webPageUrl)" outline color="white" round flat aria-setsize="12px"
                     @click="openOrganizationPage(webPageUrl)">
                <q-icon size="sm" name="mdi-web" color="white"/>
              </q-btn>
              <q-btn v-if="isNotBlank(instagramPageUrl)" outline color="white" round flat aria-setsize="12px"
                     @click="openOrganizationPage(instagramPageUrl)">
                <q-icon size="sm" name="mdi-instagram" color="white"/>
              </q-btn>
              <q-btn v-if="isNotBlank(facebookPageUrl)" outline color="white" round flat aria-setsize="12px"
                     @click="openOrganizationPage(facebookPageUrl)">
                <q-icon size="sm" name="mdi-facebook" color="white"/>
              </q-btn>
            </div>
          </div>
        </div>
      </q-card>
      <div class="row col q-px-xs q-pt-none justify-between">
        <q-separator color="white"></q-separator>
        <div class="row justify-between full-width">
          <position-data class="q-pt-none full-width q-px-none col"
                         :job-position="jobPosition"
                         :seniority-level-name="seniorityLevelName"
                         :modes="operatingModes"
                         :workTypes="workTypes"
                         :branding="branding"
          >
          </position-data>
          <div class="col-1">
            <q-btn outline
                   style="position: absolute; bottom:13px;right: 11px"
                   class="q-pa-none q-ma-none"
                   flat aria-setsize="15px"
                   @click="copyLinkToOffer()">
              <q-icon size="35px" name="mdi-content-copy" color="accent"/>
            </q-btn>
          </div>
        </div>
      </div>
    </q-card>
    <div style="width: 100%">
      <q-card flat bordered class="row row q-ma-xs" style="border-radius: 9px;">
        <q-card-section class="full-width q-pa-xs">
          <offered-contracts :contracts="contracts"></offered-contracts>
        </q-card-section>
      </q-card>
    </div>

  </q-card>
</template>

<script setup>
import {defineProps, onMounted} from 'vue';
import OfferedContracts from "@/components/common/job-offers/job-offer-details/intro-card/OfferedContracts";
import PositionData from "@/components/common/job-offers/job-offer-details/intro-card/PositionData";
import {useQuasar} from "quasar";
import NotificationService from "@/services/notification/NotificationService";
// import AdditionalIntroData from "@/components/common/job-offers/job-offer-details/intro-card/AdditionalIntroData";

// const hoverStyle = ref({
//   color: 'red',
//   // Add any other styles you want to apply on hover
// });
function isNotBlank(str) {
  if (str === null || str === undefined) {
    return false;
  }

  // Remove leading and trailing spaces using trim()
  const trimmedStr = str.trim();

  // Check if the resulting string is empty or not
  return trimmedStr !== '';
}

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  isVisible: {
    type: Boolean,
    required: true,
  },
  logo: {
    type: String,
    required: true,
  },
  companyName: {
    type: String,
    required: true,
  },
  contracts: {
    type: Array,
    required: true
  },
  webPageUrl: {
    type: String,
    required: true
  },
  facebookPageUrl: {
    type: String,
    required: true
  },
  instagramPageUrl: {
    type: String,
    required: true
  },
  jobPosition: {
    type: String,
    required: true
  },
  seniorityLevelName: {
    type: String,
    required: true
  },
  operatingModes: {
    type: Array,
    required: true
  },
  workTypes: {
    type: Array,
    required: true
  },
  id: {
    type: String,
    required: true
  },
  branding: {
    type: Object,
    required: true
  },
  colorBrandingAvailable: {
    type: Boolean,
    required: true
  },
  logoInBase64: {
    type: String,
    required: false
  }
});

onMounted(() => {
  console.info("LOOOGO " + props.logoInBase64)
})

function openOrganizationPage(page) {
  window.open(page, '_blank')

}

const $q = useQuasar()

function copyLinkToOffer() {
  setClipboardText(process.env.VUE_APP_DOMAIN + '/job-offers-main-board/' + props.id)
  NotificationService.showNotificationWithPosition($q, 'positive', 'Skopiowano link do oferty', 'top-right')
}

function setClipboardText(text) {
  // Create a text area element to temporarily hold the text
  const textArea = document.createElement("textarea");
  textArea.value = text;

  // Set the text area to be invisible
  textArea.style.position = "fixed";
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.opacity = "0";

  // Append the text area to the DOM
  document.body.appendChild(textArea);

  // Select the text inside the text area
  textArea.select();

  try {
    // Copy the selected text to the clipboard
    document.execCommand("copy");
  } catch (err) {
    console.error("Failed to copy text: ", err);
  }

  // Remove the temporary text area from the DOM
  document.body.removeChild(textArea);
}

function calculateBackgroundGradient() {
  return {
    background: `
      linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1)),
      linear-gradient(45deg, ${props.branding.primaryColor}, ${props.branding.secondaryColor})
    `,
    backgroundSize: '100% 100%, 100% 100%',
    backgroundPosition: 'bottom, top',
    backgroundRepeat: 'no-repeat'
  }
}

</script>
<style scoped lang="scss">
//.my-card {
//  margin: 5px auto;
//}

my-text {
  color: white;
}

company-text {
  color: white;
}

.contracts-bg {
  background: linear-gradient(45deg, $primary, $light-blue-11)
}

.bg-company-gradient {
  background: linear-gradient(90deg, $primary, $light-blue-11); /* Use your primary and accent colors here */
  color: white; /* Text color */
}
</style>