<template>
  <q-dialog v-model="noResultFound" persistent>
    <q-card style="width: 350px; border-radius: 9px">
      <q-card-section class="justify-center q-pb-none">
        <div class="q-pt-none q-pb-sm text-center items-center">
          <q-icon color="negative" name="fa-solid fa-circle-info" size="40px"/>
        </div>
        <div class="items-center text-center text-h7 text-grey-9 q-pb-md">Nie znaleziono ofert dla podanych kryteriów.
        </div>
      </q-card-section>
      <q-card-section class="text-center q-pt-xs">
        <q-btn no-caps padding="3px xl" type="submit" color="primary" label="Ok"
               @click="initOffers()"></q-btn>
      </q-card-section>
    </q-card>
  </q-dialog>
  <div class="row col q-pt-none q-pl-none column">
    <div class="row q-mr-none q-ml-xs">
      <job-offers-filter
          ref="filterComponent"
          class="col-8" @filter-values="receiveFilterOffersEvent"
          @observed="val => filterObserved(val)"
      ></job-offers-filter>
    </div>
    <div class="row col q-pb-none q-mt-sm">
      <div class="q-pl-xs q-mb-sm q-pr-none q-pt-none q-pb-none col row q-scrollarea" style="overflow-y: auto">
        <div v-if="!isReady || noResultFound" class="col row column q-mr-sm">
          <card-skeleton class="q-mr-xs q-ml-none q-mt-none col row column"></card-skeleton>
          <card-skeleton class="q-mr-xs q-ml-none q-mt-sm col row column"></card-skeleton>
          <card-skeleton class="q-mr-xs q-ml-none q-mt-sm col row column"></card-skeleton>
          <card-skeleton class="q-mr-xs q-ml-none q-mt-sm col row column"></card-skeleton>
          <card-skeleton class="q-mr-xs q-ml-none q-mt-sm col row column"></card-skeleton>
          <card-skeleton class="q-mr-xs q-ml-none q-mt-sm col row column"></card-skeleton>
          <card-skeleton class="q-mr-xs q-ml-none q-mt-sm col row column"></card-skeleton>
          <card-skeleton class="q-mr-xs q-ml-none q-mt-sm col row column"></card-skeleton>
          <card-skeleton class="q-mr-xs q-ml-none q-mt-sm col row column"></card-skeleton>
          <card-skeleton class="q-mr-xs q-ml-none q-mt-sm col row column"></card-skeleton>
        </div>
        <div v-else ref="scrollTargetRef" class="col row" style="overflow-y: auto;">
          <div v-if="isReady" :key="resetCounter" ref="infinitiveScroll"
               style="overflow-y: auto"
               :scroll-target="scrollTargetRef"
               class="q-mr-none q-ml-none q-mt-none col row column">
            <div v-for="jobOffer in offers" :key="getJobOfferId(jobOffer)" class="q-mr-xs"
                 :scroll-target="scrollTargetRef">
              <offer-overview
                  style="max-height: 160px"
                  :job-offer="jobOffer"
                  :is-fav="isFavForLoggedUser(jobOffer.id)"
                  :is-selected="!offerFromUrlNotFound && selectedJobOffer.id === jobOffer.id && shouldShowHighlightedOverview"
                  @jobOffer:id="value => updateCurrentJobOffer(value)"
                  @fav:remove="id => removeFav(id)"
                  @fav:add="id => addFav(id)"
                  class="bg-white">

              </offer-overview>
            </div>
          </div>
        </div>
      </div>
      <div
          class="q-mt-none q-pb-sm col row column q-ml-sm"
          style="max-width: 45%">
        <job-offer-details-skeleton
            class="q-pr-md col"
            v-if="isLoading || noResultFound || offers.length  === 0"
        >
        </job-offer-details-skeleton>
        <job-offer-details
            v-else-if="!offerFromUrlNotFound && offers.length  !== 0"
            id="jobOfferDetailsRef"
            ref="jobOfferDetailsRef"
            class="col row column"
            :show-apply="true"
            :padding-top="0"
            :job-offer="selectedJobOffer">
        </job-offer-details>
        <q-card v-else class="q-mx-md q-mt-xs" style="border-radius: 9px">
          <q-card-section>
            <q-icon color="red-5" class="q-mr-md" size="lg" name="fa-solid fa-triangle-exclamation"></q-icon>
            <span style="font-size: 15px" class="text-grey-9">Oferta wygasła lub została usunięta. Wybierz inną ofertę z listy.</span>
          </q-card-section>
        </q-card>
      </div>
    </div>
  </div>
</template>


<script setup>

import {onMounted, ref,} from 'vue';
import OfferOverview from "@/components/common/job-offers/OfferOverview";
import JobOfferService from "@/services/JobOfferService";
import JobOfferDetails from "@/components/common/job-offers/JobOfferDetails";
import JobOffersFilter from "@/components/common/job-offers/job-offers-filter/JobOffersFilter";
import {useRouter} from 'vue-router';
import CardSkeleton from "@/components/common/job-offers/skeletons/JobOfferOverviewSkeleton";
import JobOfferDetailsSkeleton from "@/components/common/job-offers/skeletons/JobOfferDetailsSkeleton";
import OfferCounterService from "@/services/offer/OfferCounterService";
import {currentUser} from '@/global-variables/currentUser';

const filterComponent = ref(null)
const shouldShowHighlightedOverview = ref(true)
const isLoading = ref(true)
const scrollTargetRef = ref(null)
const offers = ref([])
const currentNumberOfElements = ref(null)
const totalNumberOfElements = ref(null)
const infinitiveScroll = ref();
const currentPage = ref(0);
const resetCounter = ref(0);
const noResultFound = ref(false)
const sorting = ref('DEFAULT')
const isOnlyObserved = ref(false)
const offerFromUrlNotFound = ref(false)
onMounted(() => {
  initComponentData();

  async function init() {
    await initOffers();
  }

  init()
})

async function initOffers() {
  await filterComponent.value.resetAllFilters()
  useNewParams();
  console.info("PARAMS " + JSON.stringify(globalParams.value))
  await Promise.all([
    fetchOffers2(false, true)
  ]);
}

const router = useRouter()

function getJobOfferId(jobOffer) {
  return jobOffer.id
}

function filterObserved(isObserved) {
  console.info("OBSERVED")
  isOnlyObserved.value = isObserved
  fetchOffers2(isOnlyObserved.value, false)
}

function addElementsToOffers(content, id) {
  console.info("ADD ELEMENTS TO OFFER " + id)
  offers.value.push(...content)
}

let abortAdditionalFetch;

async function fetchOffers2(isOnlyObserved, canBeOfferFromUrl) {
  if(currentUser.isAuthenticated()){
    observedOffersId.value = currentUser.favoriteOffersIds ? currentUser.favoriteOffersIds : [];
  }
  abortPreviousFetches()
  abortAdditionalFetch = new AbortController();
  const signal = abortAdditionalFetch.signal;
  isLoading.value = false;
  isReady.value = false;
  await clearJobOffers();
  try {
    // Fetch the first page to get total number of pages
    globalParams.value.set('page', '0');
    const response = await JobOfferService.getAllJobOffers(globalParams.value, signal);
    const {content, page} = await response.json();// Add the first page's offers
    addElementsToOffers(content, 1);
    makeOfferComponentReady()
    initSelectedOffer(canBeOfferFromUrl);
    // Store total pages
    const totalPages = page.totalPages;

    // Prepare an array to hold offers from all pages
    const allOffers = [];
    // Create an array of Promises for the remaining pages
    const fetchPromises = [];

    for (let currentPage = 1; currentPage < totalPages; currentPage++) {
      // Create a new URLSearchParams object for each request to avoid modifying the original
      const params = new URLSearchParams(globalParams.value);
      params.set('page', currentPage.toString());

      // Create a Promise for fetching this page
      const fetchPromise = JobOfferService.getAllJobOffers(params, signal)
          .then(response => response.json())
          .then(data => {
            // Store the content at the correct index
            allOffers[currentPage] = data.content;
          })
          .catch(error => {
            if (error.name === 'AbortError') {
              console.info(`Fetch for page ${currentPage} aborted`);
            } else {
              console.error(`Error fetching page ${currentPage}:`, error);
            }
          });

      fetchPromises.push(fetchPromise);
    }

    // Wait for all pages to be fetched concurrently
    await Promise.all(fetchPromises);

    // Now flatten allOffers array into a single array of offers, in correct order
    const orderedOffers = allOffers.flat();

    // Add the offers to the offers list
    addElementsToOffers(orderedOffers, 2);

    // Handle selectedJobOffer logic as before
    if (offers.value.length === 0) {
      noResultFound.value = true;
    }
    makeOfferComponentReady();
  } catch (error) {
    if (error.name === 'AbortError') {
      console.info('Main fetch aborted');
    } else {
      console.error('Error fetching job offers:', error);
    }
  }
}

function abortPreviousFetches() {
  if (abortAdditionalFetch) {
    try {
      abortAdditionalFetch.abort(); // Abort the ongoing requests
    } catch (ex) {
      console.error('Abort error:', ex);
    }
  }
}

async function initSelectedOffer(canBeOfferFromUrl) {
  if (canBeOfferFromUrl && isOfferIdFromUrl()) {
    selectedJobOffer.value = findJobOfferById(router.currentRoute.value.params.id);
    const offerIndex = offers.value.findIndex(offer => offer.id === selectedJobOffer.value.id);
    if (offerIndex !== -1) {
      offers.value.splice(offerIndex, 1);
    }
    offers.value.unshift(selectedJobOffer.value);
    await OfferCounterService.increaseOfferView(selectedJobOffer.value.id);
  } else if (offers.value.length > 0) {
    selectedJobOffer.value = offers.value[0];
    await OfferCounterService.increaseOfferView(selectedJobOffer.value.id);
  }
}

function makeOfferComponentReady() {
  isLoading.value = false
  isReady.value = true
  noResultFound.value = false
}

// function fetchOffers() {
//   console.info("pobierz oferty")
//   useNewParams();
//
//   if ((currentNumberOfElements.value === null || currentNumberOfElements.value < totalNumberOfElements.value) && totalNumberOfElements.value !== 0) {
//     globalParams.value.delete('page')
//     globalParams.value.append('page', currentPage.value)
//     JobOfferService.getAllJobOffers(globalParams.value).then(response => {
//       return response.json().then((data) => {
//         if (data.content.length === 0) {
//           isLoading.value = true
//           if (noResultFound.value === false) {
//             noResultFound.value = true;
//             receiveFilterOffersEvent([], [], [], [], [], 0, 40000, [], [], [], 'DEFAULT', [])
//           }
//         } else {
//           if (isOnlyObserved.value) {
//             totalNumberOfElements.value = observedOffersId.value.length
//             offers.value.push(...data.content.filter(obj => observedOffersId.value.map(obj => obj.id).includes(obj.id)));
//           } else {
//             totalNumberOfElements.value = data.totalElements;
//             offers.value.push(...data.content)
//           }
//           currentNumberOfElements.value = offers.value.length;
//           currentPage.value = currentPage.value + 1;
//           if (isOfferIdFromUrl()) {
//             selectedJobOffer.value = findJobOfferById(router.currentRoute.value.params.id);
//             const offerIndex = offers.value.findIndex(offer => offer.id === selectedJobOffer.value.id);
//             if (offerIndex !== -1) {
//               offers.value.splice(offerIndex, 1);
//             }
//             offers.value.unshift(selectedJobOffer.value);
//             OfferCounterService.increaseOfferView(selectedJobOffer.value.id);
//           } else {
//             selectedJobOffer.value = offers.value[0]
//             OfferCounterService.increaseOfferView(selectedJobOffer.value.id)
//           }
//           isLoading.value = false
//           isReady.value = true;
//         }
//       }).catch((err) => {
//         console.log(err);
//       })
//     })
//   } else {
//     isLoading.value = false
//   }
// }

function useNewParams() {
  let params = new URLSearchParams({})
  params.append('page', "0")
  //TODO - test maximum pagge size that do not affect performance
  params.append('size', "30")
  params.append('sort', sorting.value)
  globalParams.value = params
}

async function clearJobOffers() {
  offers.value = []
  currentPage.value = 0;
}

const globalParams = ref(null)

function receiveFilterOffersEvent(jobPositions,
                                  skills,
                                  seniorityLevels,
                                  operatingModes,
                                  contractTypes,
                                  salaryFrom,
                                  salaryTo,
                                  locations,
                                  languages,
                                  industries,
                                  sorting,
                                  workTypes,
                                  isObserved,
) {
  let params = new URLSearchParams({})
  params.append('page', "0")
  params.append('size', "30")
  if (sorting !== 'Obserwowane') {
    params.append('sort', sorting)
  }
  if (skills) {
    params.append('skills', concatenateWithComma(skills));
  }

  if (workTypes) {
    params.append('workTypes', concatenateWithComma(workTypes))
  }

  if (jobPositions) {
    params.append('jobPositions', concatenateWithComma(jobPositions));
  }

  if (seniorityLevels) {
    params.append('seniorityLevels', concatenateWithComma(seniorityLevels));
  }

  if (contractTypes) {
    params.append('contractTypes', concatenateWithComma(contractTypes));
  }

  if (locations) {
    params.append('cities', concatenateWithComma(locations));
  }

  if (languages) {
    params.append('languages', concatenateWithComma(languages));
  }

  if (salaryFrom != null) {
    params.append('salaryFrom', salaryFrom);
  }

  if (salaryTo != null) {
    params.append('salaryTo', salaryTo);
  }

  if (operatingModes) {
    params.append('operatingModes', concatenateWithComma(operatingModes))
  }

  if (industries) {
    params.append('organizationIndustries', concatenateWithComma(industries))
  }

  if (isObserved) {
    params.append('favorite', true)
  }
  globalParams.value = params
  currentNumberOfElements.value = null
  resetInfiniteScroll()
  fetchOffers2(isObserved, false)
}

function concatenateWithComma(arr) {
  return arr.join(",");
}

function resetInfiniteScroll() {
  isLoading.value = false;
  noResultFound.value = false
  clearJobOffers();
  currentNumberOfElements.value = null;
  totalNumberOfElements.value = null;
  currentPage.value = 0
}

const selectedJobOffer = ref(null);
const jobOfferDetailsRef = ref(null)

function updateCurrentJobOffer(value) {
  if (router.currentRoute.value.params.id !== value) {
    OfferCounterService.increaseOfferView(value)
  }
  shouldShowHighlightedOverview.value = true
  offerFromUrlNotFound.value = false
  selectedJobOffer.value = findJobOfferById(value);
  jobOfferDetailsRef.value.scrollToTop()
}

function findJobOfferById(id) {
  return offers.value.find(job => job.id === id);
}

const observedOffersId = ref([])

const isReady = ref(false)

function initComponentData() {
  isReady.value = false;
  if (currentUser.isAuthenticated()) {
    observedOffersId.value = currentUser.favoriteOffersIds
  }
}

function isOfferIdFromUrl() {
  let id = router.currentRoute.value.params.id;
  return id !== undefined && id !== null && isNotBlank(id);
}

function isNotBlank(str) {
  if (str === null || str === undefined) {
    return false;
  }
  const trimmedStr = str.trim();
  return trimmedStr !== '';
}

function isFavForLoggedUser(jobOfferId) {
  //todo here is infinitive loop
  console.info("OBSERVED offers: " +  observedOffersId.value)
  return observedOffersId.value.some(id => id === jobOfferId);
}

</script>

<style lang="scss" scoped>
.apexcharts-tooltip {
  background-color: #80207b;
  color: $secondary;
  font-size: 12px;
}

.apexcharts-tooltip-title {
  background-color: #80207b;
  color: white;
  font-size: 12px;
}

.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
  background: $secondary !important;
}

.input-select {
  width: 150px;
  margin: 0;
  padding: 0
}

.q-field__native, .q-field__prefix, .q-field__suffix, .q-field__input {
  color: $secondary !important;
}

.q-field--auto-height.q-field--dense .q-field__control, .q-field--auto-height.q-field--dense .q-field__native {
  max-height: 34px;
  min-height: 10px;
  border-radius: 8px;
}


::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-button {
  background: #ffffff
}

::-webkit-scrollbar-track-piece {
  background: #ffffff
}

::-webkit-scrollbar-thumb {
  background: #8e959a
}

</style>

