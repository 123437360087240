<template>
  <div class="q-pa-xs col row column">
    <div class="q-mb-md row q-px-md q-mt-md full-width justify-between">
      <div class="q-gutter-sm">
        <q-btn class="bg-white" :class="getColorForIsActiveButton()" label="Aktywne" no-caps @click="fetchAccounts(true)"></q-btn>
        <q-btn class="bg-white" :class="getColorForIsInactiveButton()" label="Nieaktywne" @click="fetchAccounts(false)" no-caps></q-btn>
      </div>
      <organization-account-create @add:user="fetchAccounts(showActive.value)"></organization-account-create>
    </div>
    <div class="full-width q-scrollarea col q-px-sm" style="overflow-y: auto; display: flex; justify-content: center;">
      <div class="q-ma-md" style="width: 70%;"> <!-- Adjust width as needed -->
        <organization-account-overview
            class="q-ma-md"
            v-for="(account, index) in accounts"
            :key="account.id"
            @account:deactivate="removeDeactivated()"
            v-model:account="accounts[index]"
            @admin:pass="showAndHidePassAdminConfirmationDialog()"
        ></organization-account-overview>
      </div>
    </div>


    <q-dialog v-model="showConfirmationDialog">
      <q-card style="border-radius: 9px">
        <q-card-section class="row q-pb-sm">
          <div class="q-pr-md q-mt-xs">
            <q-avatar icon="key" color="accent" text-color="white"/>
          </div>
          <div class="col">
            <p class="q-ml-sm q-mb-none text-grey-8">Czy na pewno chcesz przekazać uprawnienia admina do <span
                style="color: #3bb6c4;font-size: 14px">{{ passAdminTo }}</span>? Potwierdzając wyrażasz zgodę na wylogowanie.</p>
          </div>
        </q-card-section>
        <div class="q-mx-md q-mb-sm">
          <q-separator></q-separator>
        </div>
        <q-card-actions align="right" class="q-pt-none q-mr-none">
          <q-btn class="q-mr-sm" outline flat label="Nie" color="primary" v-close-popup/>
          <q-btn class="q-mr-sm" flat label="Tak" color="primary" v-close-popup @click="passAdmin"/>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script setup>
import {onMounted, ref} from "vue";
import OrganizationAccountOverview from "@/components/organization/organization-accounts/OrganizationAccountOverview";
import OrganizationUsersService from "@/services/organization/OrganizationUsersService";
import OrganizationAccountCreate from "@/components/organization/organization-accounts/OrganizationAccountCreate";
import {useRouter} from "vue-router/dist/vue-router";

const showActive = ref(true)
const accounts = ref([]);
const selectedAccounts = ref({});
const passAdminTo = ref('');

// Fetch and populate the accounts
onMounted(() => {
  fetchAccounts(showActive.value);
});

function getColorForIsActiveButton() {
  if (showActive.value) {
    return 'text-accent'
  } else {
    return 'text-grey-9'
  }
}

function getColorForIsInactiveButton() {
  if (showActive.value) {
    return 'text-grey-9'
  } else {
    return 'text-accent'
  }
}

function fetchAccounts(isActive) {
  showActive.value = isActive
  OrganizationUsersService.getUsersWithCookie().then((data) => {
    accounts.value = data.content.filter(account => account.active === showActive.value);
    accounts.value.forEach(account => {
      selectedAccounts.value[account.id] = false;
    });
  });
}

function removeDeactivated(id) {
  accounts.value = accounts.value.filter(acc => acc.id !== id);
}

const showConfirmationDialog = ref(false);
const passAdminUserId = ref(null)

function showAndHidePassAdminConfirmationDialog(email, id) {
  passAdminTo.value = email
  passAdminUserId.value = id
  showConfirmationDialog.value = true;
}

const router = useRouter()

function passAdmin() {
  OrganizationUsersService.passAdmin(passAdminUserId.value)
  router.push({name: 'OrganizationLoginPage'});
}
</script>

<style scoped>
</style>
