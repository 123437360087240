class RegistrationService {
    constructor(url) {
        this.url = url;
    }

    async _sendPostRequest(endpoint, body) {
        try {
            const requestOptions = {
                method: 'POST',
                credentials: 'include',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(body),
            };
            const response = await fetch(this.url + endpoint, requestOptions);
            return response;
        } catch (error) {
            console.error('Error during request:', error);
            throw error;
        }
    }

    async registerOrganization(organizationName, email) {
        return this._sendPostRequest('/organizations', {email, organizationName});
    }

    async confirmOrganizationRegistration(organizationId, userId, token, password) {
        return this._sendPutRequest('/organizations/' + organizationId + '/users/' + userId + '/password', {
            token,
            password
        });
    }


    async _sendPutRequest(endpoint, body) {
        try {
            const requestOptions = {
                method: 'PUT',
                credentials: 'include',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(body),
            };
            const response = await fetch(this.url + endpoint, requestOptions);
            return response;
        } catch (error) {
            console.error('Error during request:', error);
            throw error;
        }
    }
}

export default new RegistrationService(process.env.VUE_APP_BACKEND_URL);
