<template>
  <div>
    <q-dialog v-model="showColorDialog">
      <q-card class="q-pa-sm" style="border-radius: 9px">
        <q-card-section class="q-pt-none">
          <div class="row q-gutter-md">
            <div>
              <q-chip :style="definePrimaryColorButton()" class="q-ml-none q-mb-sm full-width"  style="border-radius: 7px" label="kolor podstawowy"></q-chip>
              <q-color v-model="organization.branding.primaryColor"
                       no-header-tabs
                       no-footer
                       class="my-picker"/>
            </div>
            <div>
              <q-chip :style="defineSecondaryColorButton()" class="q-ml-none q-mb-sm full-width" style="border-radius: 7px" label="kolor dodatkowy"></q-chip>
              <q-color v-model="organization.branding.secondaryColor"
                       no-header-tabs
                       no-footer
                       class="my-picker"/>
            </div>
          </div>
        </q-card-section>
        <q-card-section class="q-pt-none">
          <q-btn class="full-width" style="border-radius: 7px" no-caps size="md" text-color="grey-9" label="Zamknij" @click="showColorDialog=false"></q-btn>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
  <q-form @submit="nextStep()" greedy>
<!--    <q-card flat class="q-mx-sm q-mb-lg full-width"-->
<!--            style="border-radius: 9px;background-color: rgba(229,237,246,0.82);">-->
<!--      <q-card-section>-->
<!--        <my-text class="text-white">-->
<!--          <span class="text-weight-medium text-grey-9" style="font-size: 15px">Adres e-mail admina - </span>-->
<!--          <span class="text-grey-8">-->
<!--          na ten adres zostanie założone konto pierwszego użytkownika organizacji.-->
<!--        </span>-->
<!--        </my-text>-->
<!--      </q-card-section>-->
<!--    </q-card>-->
    <q-input v-model="accountDetails.adminEmail" rounded label-color="grey-6" outlined dense
             class="col bg-white text-grey-9"
             :rules="[ val => !StringUtils.isStringEmpty(val) || 'Pole jest wymagane' ]"
             label="Adres e-mail admina organizacji">
    </q-input>
    <div class="q-mb-lg">
      <q-separator></q-separator>
    </div>
    <q-card style="border-radius: 9px" :style="defineCompanyColorsGradient()">
      <div class="row no-wrap q-pa-md">
        <div class="q-mt-sm q-pl-sm q-mr-sm"
             style="min-width: 168px; max-width: 168px;min-height: 168px; max-height: 168px">
          <q-card v-ripple class="my-box cursor-pointer q-hoverable my-card  full-width full-height"
                  @mouseover="handleMouseOver"
                  @mouseout="handleMouseOut"
                  @click="importData()"
                  style="border-radius: 9px">
            <div v-if="isBlank(organization.logoInBase64)">
              <q-card-section v-if="isMouseOnCard" class="text-center">
                <q-icon name="add_photo_alternate" size="100px" class="text-primary q-mt-sm"></q-icon>
              </q-card-section>
              <q-card-section v-else class="text-center">
                <q-icon name="add_photo_alternate" size="100px" class="text-grey-7 q-mt-sm"></q-icon>
              </q-card-section>
            </div>
            <div v-else class="q-pa-sm full-width full-height" style=" display: flex;
  flex-direction: column;
  justify-content: center;  /* Centering y-axis */
  align-items :center">
              <q-img
                  :src="organization.logoInBase64"
                  fit='contain'/>
            </div>

            <span class="q-focus-helper">

        </span>
          </q-card>
        </div>
        <div class="full-width">
          <div class="full-width">
            <q-input class="q-pa-sm q-pb-md" color="primary" stack-label outlined v-model="organization.name"
                     label="Nazwa firmy widoczna w ogłoszeniu"
                     bg-color="white"
                     :rules="[ val => val.length > 2 || 'Podaj nazwę firmy',
                    val => val.length < 40 || 'Zbyt długa nazwa firmy']" dense>
              <template v-slot:append>
                <q-avatar>
                  <q-icon
                      name="sym_o_title"
                      size="1rem"/>
                </q-avatar>
              </template>
            </q-input>
            <q-input class="q-pa-sm q-pb-md" color="primary" stack-label outlined
                     v-model="organization.webPageUrl"
                     label="Strona firmy (opcjonalne)"
                     bg-color="white"
                     :rules="[ val => val.length <= 40 || 'Zbyt długi adres strony www' ]" dense>
              <template v-slot:append>
                <q-avatar>
                  <q-icon
                      name="sym_o_title"
                      size="1rem"/>
                </q-avatar>
              </template>
            </q-input>
          </div>

          <div class="row">
            <q-select class="col q-pa-sm" color="primary" stack-label outlined
                      v-model="organization.type"
                      bg-color="white"
                      :options="OrganizationDataTranslator.translateCompanyTypeToPolish()"
                      :rules="[ val => val.length >0 || 'Wybierz rodzaj firmy']"
                      label="Rodzaj firmy" dense>
            </q-select>
            <q-select class="col q-pa-sm" color="primary" stack-label outlined
                      v-model="organization.industries"
                      multiple
                      bg-color="white"
                      :options="OrganizationDataTranslator.translateCompanyIndustriesToPolish()"
                      dense
                      :rules="[ val => val.length >0 || 'Wybierz branżę']"
                      label="Branża">
            </q-select>
            <q-select class="col q-pa-sm" color="primary" stack-label outlined
                      v-model="organization.size"
                      bg-color="white"
                      :options="OrganizationDataTranslator.translateCompanySizesToPolish()"
                      dense
                      :rules="[ val => val.length >0 || 'Podaj wielkość firmy']"
                      label="Liczba pracowników">
            </q-select>
          </div>
        </div>
      </div>
    </q-card>
    <div>
      <div class="q-my-lg">
        <q-card flat bordered style="border-radius: 9px">
          <q-card-section class="q-pa-md">
            <div class="row justify-between">
              <div>
                <my-text><span class="text-bold">Branding</span> - wybierz dwa główne kolory definiujące Waszą markę
                </my-text>
              </div>
              <div class="row q-gutter-md">
                <q-btn @click="showColorDialog=true" size="sm" :style="definePrimaryColorButton()"></q-btn>
                <q-btn @click="showColorDialog=true" size="sm" :style="defineSecondaryColorButton()"></q-btn>
              </div>
            </div>
          </q-card-section>
        </q-card>
      </div>
      <div>
        <q-field
            class="q-pa-none"
            ref="fieldRef"
            v-model="organization.description"
            label-slot
            borderless
            dense
            :rules="[val => val && val.length < 4000 || 'Zbyt długi opis - max 4000 znaków.']"
        >
          <my-text class="q-pa-none" style="width: 100%">
            <q-editor
                style="border-radius: 9px"
                class="q-pa-none"
                v-model="organization.description"
                min-height="10rem"
                max-height="10rem"
                toolbar-color="primary"
                :toolbar="[['bold', 'unordered']]"
                :style="fieldRef && fieldRef.hasError ? 'border-color: #C10015' : ''"
            />
          </my-text>
        </q-field>
      </div>

    </div>
    <div class="q-pt-lg q-pb-md q-pa-xs">
      <q-separator></q-separator>
    </div>
    <q-stepper-navigation class="q-pa-sm">
      <q-btn type="submit" class="q-px-md" outline color="cyan-7" dense no-caps label="Dalej"/>
      <q-btn flat @click="previousStep" color="primary" dense no-caps label="Powrót" class="q-ml-md"/>
    </q-stepper-navigation>
  </q-form>
</template>

<script setup>
import {computed, defineEmits, defineProps, ref} from 'vue'
import OrganizationDataTranslator from "@/services/organization/OrganizationDataTranslator";
import NotificationService from "@/services/notification/NotificationService";
import {useQuasar} from "quasar";
import StringUtils from "@/services/common/StringUtils";

const showColorDialog = ref(false)

function isBlank(str) {
  return /^\s*$/.test(str);
}

const props = defineProps({
  formTemplateData: Object,
  modelValue: Object,
  accountDetails: Object
})

const organization = computed({
  get: () => props.modelValue,
  set: (val) => emit('update:modelValue', val),
});

const accountDetails = computed({
  get: () => props.accountDetails,
  set: (val) => emit('update:accountDetails', val),
});

const emit = defineEmits(['update:modelValue', 'step:next', 'step:previous'])

async function nextStep() {
  if (validateLogo()) {
    emit('step:next')
  }
}

const $q = useQuasar()

function validateLogo() {
  if (isStringEmpty(organization.value.logoInBase64)) {
    NotificationService.showNotification($q, 'negative', 'Dodaj logo firmy');
    return false;
  }
  return true;
}

function previousStep() {
  emit('step:previous')
}

function isStringEmpty(str) {
  const regex = /^\s*$/; // Regular expression for empty or whitespace-only string
  return regex.test(str);
}

const isMouseOnCard = ref(false);

function handleMouseOver() {
  isMouseOnCard.value = true;
}

function handleMouseOut() {
  isMouseOnCard.value = false;
}

const organizationLogo = ref(null);

function importData() {
  let input = document.createElement('input');
  input.type = 'file';
  input.accept = '.jpg, .jpeg, .png'; // Specify accepted file extensions

  input.onchange = () => {
    let files = Array.from(input.files);
    if (files.length > 0) {
      const file = files[0];

      // Check the file extension
      const allowedExtensions = ["jpg", "jpeg", "png"];
      const fileExtension = file.name.split('.').pop().toLowerCase();

      if (allowedExtensions.includes(fileExtension)) {
        const reader = new FileReader();

        reader.onload = () => {
          // FileReader's result contains the Base64 data URL of the file
          organizationLogo.value = reader.result;
          organization.value.logoInBase64 = reader.result;
        };

        reader.readAsDataURL(file);
      } else {
        NotificationService.showNotification($q, 'negative', 'Błędny format logo. Wspierane formaty .jpg .jpeg .png');
      }
    }
  };

  input.click();
}

function defineCompanyColorsGradient() {
  return 'background: linear-gradient(45deg,' + organization.value.branding.primaryColor + ', ' +  organization.value.branding.secondaryColor + ')'
}

function definePrimaryColorButton() {
  return 'background:' + organization.value.branding.primaryColor
}

function defineSecondaryColorButton() {
  return 'background:' + organization.value.branding.secondaryColor
}
</script>
<style lang="scss" scoped>
.my-picker {
  max-width: 200px
}
//
//:deep(.q-field--auto-height.q-field--dense.q-field--labeled .q-field__control-container) {
//  padding-top: 3px;
//}
</style>