<template>
  <div style="max-height:100%; width: 100%; position:fixed; flex:none; overflow-y:hidden; display: flex; flex-direction: column">
    <menu-bar></menu-bar>
    <add-job-offer-as-logged-user-steps></add-job-offer-as-logged-user-steps>
  </div>
</template>

<script>
import MenuBar from "@/components/common/MenuBar";
import AddJobOfferAsLoggedUserSteps from "@/components/organization/job-offers/adding/AddJobOfferAsLoggedUserSteps";
export default {
  created() {
    document.body.style.backgroundColor = 'rgb(217,225,238)'
  },
  components: {MenuBar, AddJobOfferAsLoggedUserSteps }
}
</script>

<style lang="scss" scoped>
.my-card {
  width: 55%;
}
</style>
