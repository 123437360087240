<template>
  <div>
    <div class="row justify-between">
      <q-btn @click="showDialog = true" class="bg-white" size="md" style="border-radius: 7px"
             no-caps label="Utwórz konto" text-color="primary" color="primary"/>
    </div>
    <q-dialog v-model="showDialog" style="width: 1000px">
      <q-card style="max-width: 100%; border-radius: 9px; width: 600px">
        <div class="q-ma-sm">
          <p style="font-size: 15px" class="text-bold q-mb-none q-pl-sm text-primary">Utwórz konto</p>
        </div>
        <q-form greedy @submit="addUser()">
          <div class="q-mx-md q-mb-md">
            <q-separator></q-separator>
          </div>
          <q-card-section class="q-pt-none q-pb-sm">
            <q-input color="primary" :rules="[ val => val.length !== 0 || 'E-mail jest wymagany' ]"
                     v-model="email" label="E-mail *" dense outlined/>
          </q-card-section>
          <q-card-section class="row q-gutter-md q-pt-none q-pb-sm">
            <q-input  color="primary"
                      class="col"
                      v-model="name"
                      label="Imię"
                      dense
                      :rules="[ val => val.length !== 0 || 'Imię jest wymagane' ]"
                      outlined/>
            <q-input color="primary"
                     class="col"
                     v-model="lastName"
                     label="Nazwisko"
                     dense
                     outlined
                     :rules="[ val => val.length !== 0 || 'Nazwisko jest wymagane' ]"
            />
          </q-card-section>
          <q-card-section class="row q-gutter-md q-pt-none q-pb-none">
            <q-input color="primary"
                     class="col"
                     v-model="phoneNumber"
                     label="Numer telefonu (opcjonalny)"
                     dense
                     outlined
                     :rules="[ val => val.length === 0 || !PhoneNumberValidator.isValidPolishPhoneNumber(val.length) || 'Podaj numer w formacie +48xxxxxxxxx' ]"
            />
            <q-input color="primary" class="col" v-model="position"
                     :rules="[ val => val.length !== 0 || 'Stanowisko jest wymagane' ]"
                     label="Stanowisko"
                     dense
                     outlined/>
          </q-card-section>
          <q-card-section class="full-width q-pb-sm">
            <!--          <div class="row justify-between">-->
            <!--            <q-checkbox dense v-model="teal" label="Teal" color="teal" />-->
            <!--            <q-checkbox dense v-model="orange" label="Orange" color="orange" />-->
            <!--            <q-checkbox dense v-model="red" label="Red" color="red" />-->
            <!--            <q-checkbox dense v-model="cyan" label="Cyan" color="cyan" />-->
            <!--          </div>-->
            <div>
              <q-table
                  flat bordered
                  hide-bottom
                  :rows="rows"
                  :columns="columns"
                  row-key="name"
                  class="text-primary"
              >
                <template v-slot:body="props">
                  <q-tr :props="props">
                    <q-td style="padding-left: 7px" key="name" class="q-pl-none" :props="props">
                      <q-chip style="border-radius: 5px" dense class="text-grey-9" :color="props.row.color"
                              :label="props.row.name"></q-chip>
                    </q-td>
                    <q-td key="tak" :props="props">
                      <q-checkbox size="sm" v-model="props.row.tak" color="primary"
                                  @click="checkIfPermissionsAreEmpty(rows)"
                      >
                      </q-checkbox>
                    </q-td>
                  </q-tr>
                </template>
              </q-table>
            </div>
          </q-card-section>
          <q-card-actions class="row justify-between q-pa-md">
            <q-btn style="border-radius: 5px" outline dense label="Anuluj" color="negative"
                   @click="showDialog = false"/>
            <q-btn style="border-radius: 5px" label="Utwórz" type="submit" dense color="primary"/>
          </q-card-actions>
        </q-form>
      </q-card>
    </q-dialog>
  </div>
  <q-dialog v-model="showConfirmationDialog" persistent>
    <q-card style="border-radius: 9px">
      <q-card-section class="row q-pb-none">
        <div class="q-ml-sm q-mr-md q-pt-xs">
          <q-icon name="fa-solid fa-user-plus" color="green-5" text-color="white" size="lg"/>
        </div>
        <div class="col">
          <p class="q-ml-sm text-grey-9">Dodano nowe konto. Link do ustawienia hasła został wysłany na podany adres
            e-mail.
            {{ email.value }}</p>
        </div>
      </q-card-section>
      <div class="q-mx-md q-mb-none">
        <q-separator></q-separator>
      </div>
      <q-card-actions align="right" class="q-pt-xs q-mr-none q-pb-xs">
        <q-btn class="q-mr-sm" outline flat label="Ok" color="primary" v-close-popup/>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup>
import {defineEmits, ref} from 'vue';

const showDialog = ref(false); // Show dialog by default
const email = ref('');
const name = ref('');
const lastName = ref('');
const phoneNumber = ref('');
const position = ref('');
const columns = ref([
  {
    name: 'name',
    required: true,
    label: 'Uprawnienia',
    align: 'left',
    field: row => row.name,
    format: val => `${val}`,
    sortable: true
  },
  {name: 'tak', align: 'center', label: 'przyznane', sortable: false, field: 'tak'},
])

import PhoneNumberValidator from "@/services/common/PhoneNumberValidator";
// ORGANIZATION,
//     USERS,
//     USERS_PERMISSIONS,
//     OFFERS,
//     APPLICATIONS,
//     MEETINGS,
const rows = ref([
  {
    name: 'Zrządzanie danymi organizacji',
    id: 'ORGANIZATION',
    tak: false,
    color: 'white'
  },
  {
    name: 'Zarządzanie kontami użytkowników',
    tak: false,
    id: 'USERS',
    color: 'white'
  },
  {
    name: 'Odczyt ofert organizacji',
    id: 'OFFERS_READ',
    tak: false,
    nie: true,
    color: 'white'
  },
  {
    name: 'Zarządzanie ofertami',
    id: 'OFFERS_MANAGEMENT',
    tak: false,
    color: 'white'

  },
  {
    name: 'Zarządzanie aplikacjami kandydatów',
    id: 'APPLICATIONS',
    tak: true,
    color: 'white'
  }
])

import organizationUsersService from "@/services/organization/OrganizationUsersService";
import {useQuasar} from "quasar";

const $q = useQuasar();

async function addUser() {
  const newUserDto = {
    email: email.value,
    permissions: [
      {
        name: 'ORGANIZATION',
        actions: getPermissionForOrganizationIfAdded()
      }, {
        name: 'USERS',
        actions: getPermissionForUsersIfAdded()
      }, {
        name: 'USERS_PERMISSIONS',
        actions: getPermissionForUsersPermissionsIfAdded()
      }, {
        name: 'OFFERS',
        actions: getPermissionForOffersIfAdded()
      }, {
        name: 'APPLICATIONS',
        actions: getPermissionForApplicationsIfAdded()
      }, {
        name: 'MEETINGS',
        actions: getPermissionForMeetingsIfAdded()
      }
    ],
    firstName: name.value,
    lastName: lastName.value,
    phoneNumber: phoneNumber.value,
    position: position.value
  };
  let response = await organizationUsersService.addUser(newUserDto)
  if (response.status === 201) {
    showAndHideConfirmationDialog()
    showDialog.value = false;
    emit('add:user')
  } else if (response.status === 400) {
    const json = await response.json()
    if (json.errorCode.includes('EMAIL_ALREADY_TAKEN')) {
      $q.notify({
        type: 'negative',
        message: 'Konto o podanym adresie e-mail już istnieje'
      })
    } else if (json.detailedMessage.includes('email=must be a well-formed email address')) {
      $q.notify({
        type: 'negative',
        message: 'Niepoprawny format adresu e-mail'
      })
    } else if (json.message.includes('Validation failed on fields: [phoneNumber].')) {
      $q.notify({
        type: 'negative',
        message: 'Niepoprawny numer telefonu.'
      })
    }
  } else {
    $q.notify({
      type: 'negative',
      message: 'Błąd podczas logowania - skontaktuj się z supportem.'
    })
  }
}

function showAndHideConfirmationDialog() {
  showConfirmationDialog.value = true;
  setTimeout(() => {
    showConfirmationDialog.value = false;
  }, 4900);
}

const emit = defineEmits(['add:user'])

function getPermissionForOrganizationIfAdded() {
  let uiPermission = rows.value.filter(permission => permission.id === 'ORGANIZATION')
  if (uiPermission[0].tak) {
    return ['UPDATE']
  }
}

function getPermissionForUsersIfAdded() {
  let uiPermission = rows.value.filter(permission => permission.id === 'USERS')
  if (uiPermission[0].tak) {
    return ['CREATE', 'READ', 'UPDATE', 'DEACTIVATE']
  }
}

function getPermissionForUsersPermissionsIfAdded() {
  let uiPermission = rows.value.filter(permission => permission.id === 'USERS')
  if (uiPermission[0].tak) {
    return ['UPDATE']
  }
}

function getPermissionForOffersIfAdded() {
  let uiOffersManagementPermission = rows.value.filter(permission => permission.id === 'OFFERS_MANAGEMENT')
  let permissions = ['CREATE', 'READ', 'UPDATE', 'DELETE'];
  if (uiOffersManagementPermission[0].tak) {
    return permissions
  }

  let uiOffersReadPermission = rows.value.filter(permission => permission.id === 'OFFERS_READ')
  if (uiOffersReadPermission[0].tak) {
    return permissions.filter(permission => permission === 'READ');
  }
}

function getPermissionForApplicationsIfAdded() {
  let uiPermission = rows.value.filter(permission => permission.id === 'APPLICATIONS')
  if (uiPermission[0].tak) {
    return ['READ', 'UPDATE', 'DELETE']
  }
}

function getPermissionForMeetingsIfAdded() {
  let uiPermission = rows.value.filter(permission => permission.id === 'APPLICATIONS')
  if (uiPermission[0].tak) {
    return ['READ', 'CREATE', 'UPDATE', 'DELETE']
  }
}

const showConfirmationDialog = ref(false);


function checkIfPermissionsAreEmpty(rows) {
  if (rows.every(row => row.tak === false)) {
    $q.notify({
      type: 'negative',
      message: 'Nie można stworzyć użytkownika bez uprawnień.'
    })
  }
}
</script>
<style scoped>
.my-table-details {
  font-size: 0.85em;
  font-style: italic;
  max-width: 200px;
  white-space: normal;
  color: #555;
  margin-top: 4px;
}
</style>