import router from "@/router/index.js";

class OrganizationLogoutService {
    constructor(url) {
        this.url = url;
    }


    async logout() {
        const requestOptions = {
            method: "POST",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
            },
        };
        return fetch(process.env.VUE_APP_BACKEND_URL + "/logout", requestOptions)
            .then((response) => {
                if (response.status === 204) {
                    localStorage.clear();
                    router.push({name: 'OrganizationLoginPage'})
                }
            })
    }
}

export default new OrganizationLogoutService(process.env.VUE_APP_BACKEND_URL);
