<template>
  <q-card bordered class="q-mt-none q-mb-sm" style="border-radius: 9px; border-width: 1px"
          @click="returnId(props.jobOffer.id)">
    <span style="color: #456380"></span>
    <q-card-section class="q-px-xs q-pt-xs q-pb-none">
      <div class="row">
        <div class="q-pa-xs" style=" display: flex;   flex-direction: column;
  justify-content: center;
  align-items :center"
        >
          <q-img
              style="width: 65px;height: 65px; border-radius: 6px;"
              v-bind:src="props.jobOffer.organization.logoUrl"
              fit='fill'
              :ratio="1"
          />
        </div>
        <div class="col">
          <div class="row col">
            <div class="col q-pl-xs q-pt-sm q-pr-xs">
              <div class="justify-between col">
                <div class="q-pa-none row justify-between q-mr-md">
                  <div class="row col-auto q-pr-sm">
                    <p
                       style="font-size: 16px; z-index: 10"
                       id="title" ref="jobTitle"
                       class="q-pl-sm q-mb-sm ellipsis col"
                   >
                      <q-tooltip v-if="jobTitle !== null && isEllipsis" class="bg-white text-black">
                        {{ props.jobOffer.title }}
                      </q-tooltip>
                      {{ props.jobOffer.title }}
                    </p>
                  </div>
                  <div class="col">
                    <q-icon
                        v-if="jobOffer.highlighted"
                        name="fa-solid fa-fire"
                        class="col"
                        size="20px"
                        style="border-radius: 6px"
                        outline color="accent"
                    >
                    </q-icon>
                  </div>
                </div>
              </div>
            </div>

            <div class="q-mr-sm q-mb-none row q-pt-sm q-ml-md">
              <p class="q-mb-none text-weight-bold" v-if="isSelected"
                 style="border-width: 1px; max-height: 23px; font-size: 14px; font-weight: 800"
              >
                {{ salaryToString() }}
              </p>
              <p class="q-mb-none" v-else
                 style="border-width: 1px; max-height: 23px; font-size: 14px; font-weight: 400"
              >
                {{ salaryToString() }}
              </p>
            </div>
            <div style="z-index: 10000">
              <q-btn v-if="isFav" flat @click="removeFromObserver()"
                     no-caps
                     class="q-mt-none q-px-xs q-px-xs q-mr-sm q-ml-xs"
                     color="accent">
                <q-icon size="xs" name="fa-solid fa-star" class="q-py-none q-px-sm q-pl-none"/>
              </q-btn>
              <q-btn id="myElement" v-else flat
                     class="q-mt-none q-px-xs q-ml-xs q-mr-sm"
                     no-caps
                     color="grey-5">
                <q-icon size="xs" name="fa-solid fa-star" class="q-py-none q-px-sm q-pl-none"/>
              </q-btn>
            </div>
          </div>
          <div class="col-auto row q-pl-sm col">
            <!--            <div class="col row">-->
            <!--              <q-icon class="q-pt-xs" name="fa-regular fa-building" :color="getColorBasedOnIfSelected()" size="16px"/>-->
            <!--            </div>-->
            <div class="col-auto row">
              <p class="q-pl-xs q-pa-xs q-ma-none ellipsis col"
                 style="font-size: small;">
                {{ props.jobOffer.organization.name }}</p>
              <div class="col">
                <locations-info-dropdown
                    class="q-ml-md col"
                    :is-selected="true"
                    style="padding-top: 2px"
                    :locations="props.jobOffer.locations">
                </locations-info-dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
    </q-card-section>

    <q-separator/>
    <q-card-actions class="justify-between q-pa-sm">
      <div class="q-gutter-sm">
        <q-badge v-for="(skill) in props.jobOffer.skills.slice(0, 6)"
                 :key="skill.name"
                 :label="skill.name"
                 outline
                 color="grey-9"
                 style="font-weight: 400;border-radius: 4px; font-size: 10px"/>
      </div>

      <div v-if="isSelected" class="q-pa-none q-gutter-sm">
        <q-badge :label="props.jobOffer.seniority"
                 outline color="grey-9"
                 style="font-weight: 800;border-radius: 4px; font-size: 10px"/>
      </div>
      <div v-else class="q-pa-none q-gutter-sm ">
        <q-badge :label="props.jobOffer.seniority" outline color="accent" style="font-weight: 400;border-radius: 4px"/>
      </div>
    </q-card-actions>
  </q-card>
</template>

<script setup>

import {defineProps, defineEmits, ref, onMounted, onUnmounted} from "vue";
import LocationsInfoDropdown from "@/components/common/job-offers/LocationsInfoDropdown";
// import {useRouter} from 'vue-router'

// const router = useRouter()

const props = defineProps({
  jobOffer: Object,
  isSelected: Boolean,
  borderColor: String,
  isFav: Boolean
})

const emit = defineEmits(['jobOffer:id'])

function returnId(id) {
  emit('jobOffer:id', id)
}

function salaryToString() {
  if (props.jobOffer.salary !== null) {
    return props.jobOffer.salary.from.toString() + ' - ' + props.jobOffer.salary.to.toString()
  } else {
    return 'BRAK STAWKI'
  }
}

const isEllipsis = ref(false)
const resizeDetector = ref(true)
const jobTitle = ref(null);
const isFav = ref(false)

onMounted(() => {
  window.addEventListener("resize", myEventHandler);
  isFav.value = props.isFav
  isEllipsis.value = isEllipsisActive()
})

onUnmounted(() => {
  window.removeEventListener("resize", myEventHandler);
})

function myEventHandler() {
  isEllipsis.value = isEllipsisActive()
}

function isEllipsisActive() {
  let e = jobTitle.value
  if (e != null) {
    return e.offsetWidth < e.scrollWidth && resizeDetector.value
  } else {
    return false;
  }
}

function removeFromObserver(){
  emit('observed:remove', props.jobOffer.id)
}
// function removeFromObserver(){
//   emit('observed:remove', props.jobOffer.id)
// }
//
// function getCurrency() {
//   if (props.jobOffer.salary !== null) {
//     return ' ' + props.jobOffer.salary.currency
//   } else {
//     return ''
//   }
// }


</script>

<style lang="sass" scoped>

</style>