import script from "./JobOfferPayment.vue?vue&type=script&setup=true&lang=js"
export * from "./JobOfferPayment.vue?vue&type=script&setup=true&lang=js"

import "./JobOfferPayment.vue?vue&type=style&index=0&id=407de998&lang=sass"

const __exports__ = script;

export default __exports__
import QForm from 'quasar/src/components/form/QForm.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QForm,QCard,QCardSection,QInput,QSeparator,QBtn});
