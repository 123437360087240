<template>
  <div style="height: 96vh">
    <q-layout view="hHh Lpr lff" container style="height: 100vh">
      <q-header>
        <div class="bg-white">
          <menu-bar class="q-pl-sm q-pr-sm"></menu-bar>
        </div>
      </q-header>
      <q-drawer
          v-model="drawerLeft"
          show-if-above
          @mouseover="miniStateLeft = false"
          @mouseout="miniStateLeft = true"
          :width="140"
          :breakpoint="500"
          bordered
          :class="$q.dark.isActive ? 'bg-white' : 'bg-white'"
      >
        <q-list bordered style="max-width: 140px">
          <p class="text-center text-grey-9 q-pb-xs q-mb-none">Oferty</p>
          <q-btn @click="router.push({ name: 'OrganizationJobOffersBoard' });" square dense flat size="12px" class="full-width text-grey-8" color="primary" label="Aktywne" no-caps/>
          <q-btn square dense flat size="12px" class="full-width" color="primary text-accent" label="Archiwalne" no-caps/>
          <q-btn @click="router.push({ name: 'OrganizationPlannedJobOffersBoard' });" square dense flat size="12px" class="full-width" color="primary text-grey-8" label="Zaplanowane" no-caps/>
        </q-list>
        <q-list bordered>
          <p class="text-center text-grey-9 q-pb-xs q-mb-none">Konta</p>
          <q-btn square dense flat size="12px" class="full-width text-grey-8" color="primary" label="Aktywne" @click="router.push({ name: 'OrganizationAccountManagement' });" no-caps/>
          <q-btn square dense flat size="12px" class="full-width" color="primary text-grey-8" label="Nieaktywne" no-caps/>
        </q-list>
        <q-list bordered>
          <p class="text-center text-grey-9 q-pb-xs q-mb-none">Organizacja</p>
          <q-btn square dense flat size="12px" class="full-width text-grey-8" color="primary" label="Dane" no-caps @click="router.push({ name: 'OrganizationDataPage' });"/>
          <q-btn square dense flat size="12px" class="full-width" color="primary text-grey-8" label="Faktury" no-caps/>
        </q-list>
      </q-drawer>
      <q-page-container style="background-color: rgb(217,225,238);">
        <q-page class="q-pt-sm">
          <employer-offers :is-active="false" :is-planned="false"></employer-offers>
        </q-page>
      </q-page-container>
    </q-layout>
  </div>
</template>

<script setup>
import {onMounted, ref} from 'vue'
import MenuBar from "@/components/common/MenuBar";
import EmployerOffers from "@/components/organization/job-offers/EmployerOffers";
import {useRouter} from "vue-router/dist/vue-router";
const router = useRouter()
const drawerLeft = ref(false)
const miniStateLeft = ref(true)

onMounted(() => {
})


</script>

<style lang="scss">
.bg-my {
  background: linear-gradient(45deg, $primary, $secondary)
}

.bg-my-right {
  background: linear-gradient(180deg, $primary, $secondary)
}

.bg-my-bottom {
  background: linear-gradient(45deg, $secondary, $primary)
}
</style>