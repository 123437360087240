import {createRouter, createWebHistory} from 'vue-router'
import AddJobOffer from '../views/AddJobOffer.vue'
import JobOffersMainBoard from "@/views/JobOffersMainBoard";
import OfferDetailsPage from "@/views/OfferDetailsPage";
import OrganizationJobOffersBoard from "@/components/organization/job-offers/OrganizationJobOffersBoard.vue"
import OrganizationPlannedJobOffersBoard
    from "@/components/organization/job-offers/OrganizationPlannedJobOffersBoard.vue"
import OrganizationInactiveJobOffersBoard
    from "@/components/organization/job-offers/OrganizationInactiveJobOffersBoard.vue"
import OrganizationJobOfferDetailsBoard
    from "@/components/organization/job-offers/details/OrganizationJobOfferDetailsBoard.vue"
import OrganizationJobOfferDetailsEditPage
    from "@/components/organization/job-offers/details/edit/OrganizationJobOfferDetailsEditPage.vue"
import ApplicantLoginPage from "@/components/common/login/ApplicantLoginPage"
import OrganizationLoginPage from "@/components/common/login/OrganizationLoginPage"
import OrganizationApplicationsBoard from "@/components/organization/applications/OrganizationApplicationsBoard.vue";
import EmployeeRegistration from "@/components/applicant/registration/EmployeeRegistration";
import OrganizationRegistration from "@/components/organization/registration/OrganizationRegistration";
import OrganizationRegistrationConfirmation
    from "@/components/organization/registration/OrganizationRegistrationConfirmation";
import OrganizationAccountManagement
    from "@/components/organization/organization-accounts/OrganizationAccountManagement";
import EmailsBoard from "@/components/organization/emails/EmailsBoard.vue";
import OrganizationDataPage from "@/components/organization/data/OrganizationDataPage";
import ApplicantRegistrationConfirmation
    from "@/components/applicant/registration/ApplicantRegistrationConfirmation.vue"
import OrganizationPasswordReset from "@/components/organization/organization-accounts/OrganizationPasswordReset"
import AddJobOfferAsLoggedUser from "@/components/organization/job-offers/adding/AddJobOfferAsLoggedUser"
import ApplicantPasswordReset from "@/components/applicant/profile/ApplicantPasswordReset"
import PlannedJobOfferEdit from "@/components/organization/job-offers/planned/PlannedJobOfferEdit.vue"
import ApplicantProfilePage from "@/components/applicant/profile/ApplicantProfilePage.vue"
import ApplicantApplications from "@/components/applicant/applications/ApplicantApplications.vue"
import ObservedApplicationsPage from "@/components/applicant/observed/ObservedApplicationsPage.vue"
import OrganizationAccountPage
    from "@/components/organization/organization-accounts/profile/OrganizationAccountPage.vue"
import InvoicesMainBoard from "@/components/organization/invoices/InvoicesMainBoard.vue"
import AuthenticationService from "@/services/AuthenticationService";
import {currentUser} from '@/global-variables/currentUser.js';

const routes = [
    {
        path: '/adding-job-offer',
        name: 'AddJobOffer',
        component: AddJobOffer
    },
    {
        path: '/employee-login-page',
        name: 'ApplicantLoginPage',
        component: ApplicantLoginPage
    },
    {
        path: '/organization-registration',
        name: 'OrganizationRegistration',
        component: OrganizationRegistration
    },
    {
        path: '/organization-login-page',
        name: 'OrganizationLoginPage',
        component: OrganizationLoginPage
    },
    {
        path: '/employee-registration',
        name: 'EmployeeRegistration',
        component: EmployeeRegistration
    },
    {
        path: '/job-offers-main-board/:id?',
        name: 'JobOffersMainBoard',
        params: 'id',
        component: JobOffersMainBoard
    },
    {
        path: '/',
        name: 'JobOfferMainBoard',
        component: JobOffersMainBoard
    },
    {
        path: '/offer-details/:id',
        params: 'id',
        name: 'OfferDetailsPage',
        component: OfferDetailsPage
    },
    {
        path: '/organization-board/job-offers-management/active',
        name: 'OrganizationJobOffersBoard',
        component: OrganizationJobOffersBoard,
        meta: {
            requiresOrganizationAuth: true
        }
    },
    {
        path: '/organization-board/job-offers-management/active',
        name: 'OrganizationPlannedJobOffersBoard',
        component: OrganizationPlannedJobOffersBoard,
        meta: {
            requiresOrganizationAuth: true
        }
    },
    {
        path: '/organization-board/job-offers-management/active/:id',
        params: 'id',
        name: 'OrganizationJobOfferDetailsBoard',
        component: OrganizationJobOfferDetailsBoard,
        meta: {
            requiresOrganizationAuth: true
        }
    },
    {
        path: '/organization-board/job-offers-management/active/edit/:id',
        params: 'id',
        name: 'OrganizationJobOfferDetailsEditPage',
        component: OrganizationJobOfferDetailsEditPage,
        meta: {
            requiresOrganizationAuth: true
        }
    },
    {
        path: '/organization-board/job-offers-management/inactive',
        name: 'OrganizationInactiveJobOffersBoard',
        component: OrganizationInactiveJobOffersBoard,
        meta: {
            requiresOrganizationAuth: true
        }
    },
    {
        path: '/offer-details/:id/applications/:activity',
        params: ['id', 'activity'],
        name: 'OrganizationApplicationsBoard',
        component: OrganizationApplicationsBoard,
        meta: {
            requiresOrganizationAuth: true,
        }
    },
    {
        path: '/organization-board/account-management',
        name: 'OrganizationAccountManagement',
        component: OrganizationAccountManagement,
        meta: {
            requiresOrganizationAuth: true
        }
    }, {
        path: '/organization-board/invoices',
        name: 'InvoicesMainBoard',
        component: InvoicesMainBoard,
        meta: {
            requiresOrganizationAuth: true
        }
    },
    {
        path: '/organization-registration/confirmation',
        name: 'OrganizationRegistrationConfirmation',
        component: OrganizationRegistrationConfirmation
    },
    {
        path: '/applicant-registration/confirmation',
        name: 'ApplicantRegistrationConfirmation',
        component: ApplicantRegistrationConfirmation
    },
    {
        path: '/organization-board/emails-management',
        name: 'EmailsBoard',
        component: EmailsBoard,
        meta: {
            requiresOrganizationAuth: true
        }
    },
    {
        path: '/organization-board/data',
        name: 'OrganizationDataPage',
        component: OrganizationDataPage,
        meta: {
            requiresOrganizationAuth: true
        }
    },
    {
        path: '/organization-password-reset',
        name: 'OrganizationPasswordReset',
        component: OrganizationPasswordReset
    },
    {
        path: '/applicant-password-reset',
        name: 'ApplicantPasswordReset',
        component: ApplicantPasswordReset
    },
    {
        path: '/organization-board/adding-job-offer/:id?',
        params: ['id'],
        name: 'AddJobOfferAsLoggedUser',
        component: AddJobOfferAsLoggedUser,
        meta: {
            requiresOrganizationAuth: true,
        }
    },
    {
        path: '/organization-board/job-offers-management/active/:id/edit',
        params: 'id',
        name: 'PlannedJobOfferEdit',
        component: PlannedJobOfferEdit,
        meta: {
            requiresOrganizationAuth: true
        }
    },
    {
        path: '/applicant/profile',
        name: 'ApplicantProfilePage',
        component: ApplicantProfilePage,
        meta: {
            requiresEmployeeAuth: true
        }
    },
    {
        path: '/applicant/applications',
        name: 'ApplicantApplications',
        component: ApplicantApplications,
        meta: {
            requiresEmployeeAuth: true
        }
    },
    {
        path: '/applicant/observed',
        name: 'ObservedApplicationsPage',
        component: ObservedApplicationsPage,
        meta: {
            requiresEmployeeAuth: true
        }
    },
    {
        path: '/organization/current-user-account',
        name: 'OrganizationAccountPage',
        component: OrganizationAccountPage,
        meta: {
            requiresOrganizationAuth: true
        }
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})
router.beforeEach((to, from, next) => {
    AuthenticationService.getAuthenticatedUser().then(response => {
        if (response.ok) {
            response.json().then(userBody => {
                Object.assign(currentUser, userBody);
                if (to.meta.requiresOrganizationAuth) {
                    if (currentUser.isApplicant()) {
                        sessionStorage.setItem('redirectPath', router.options.history.location);
                        next('/organization-login-page');
                    } else {
                        next();
                    }
                } else {
                    next()
                }
            })
        } else if (to.meta.requiresOrganizationAuth) {
            sessionStorage.setItem('redirectPath', router.options.history.location);
            next('/organization-login-page');
        } else {
            next()
        }
    })
});


router.beforeEach((to, from, next) => {
    AuthenticationService.getAuthenticatedUser().then(response => {
        if (response.ok) {
            response.json().then(userBody => {
                Object.assign(currentUser, userBody);
                if (to.meta.requiresEmployeeAuth) {
                    if (!currentUser.isApplicant()) {
                        sessionStorage.setItem('redirectPath', router.options.history.location);
                        next('/employee-login-page');
                    } else {
                        next();
                    }
                } else {
                    next()
                }
            })
        } else if (to.meta.requiresEmployeeAuth) {
            sessionStorage.setItem('redirectPath', router.options.history.location);
            next('/employee-login-page');
        } else {
            next()
        }
    })
});

export default router
