<template>
  <q-dialog v-model="showFavButtonNotAvailable">
    <q-card v-if="currentUser.isApplicant()" style="width: 250px; border-radius: 9px;">
      <q-card-section class="row justify-center q-px-none q-pt-md q-pb-md">
        <div class="text-center text-h7 text-grey-9">
          <div class="q-pt-none q-pb-sm q-mb-xs">
            <q-icon size="md" name="fa-solid fa-circle-info" color="positive"></q-icon>
          </div>
          <span style="font-size: 12px">Aby filtrować ulubione oferty</span>
          <div class="row">
            <div class="text-center q-pt-none q-pb-none q-mt-xs q-px-sm"> <!-- Add the text-center class to this div -->
              <q-btn size="13px" flat color="accent" @click="router.push({ name: 'ApplicantLoginPage' });"
                     label="Zaloguj się"
                     dense
                     no-caps/>
            </div>
            <span class="q-mx-xs" style="font-size: 12px; padding-top: 10px">lub</span>
            <q-btn size="13px" flat color="primary" @click="router.push({ name: 'EmployeeRegistration' });"
                   label="Zarejestruj"
                   class="text-center q-pt-none q-pb-none q-mt-xs q-px-sm"
                   dense
                   no-caps/>
          </div>
        </div>
      </q-card-section>
    </q-card>
    <q-card v-else style="width: 280px; border-radius: 9px;">
      <q-card-section class="row justify-center q-px-none q-pt-md q-pb-md">
        <div class="text-center text-h7 text-grey-9">
          <div class="q-pt-none q-pb-sm q-mb-xs">
            <q-icon size="md" name="fa-solid fa-circle-info" color="positive"></q-icon>
          </div>
          <span style="font-size: 12px">Aby filtrować ulubione oferty</span>
          <div class="row">
            <div class="text-center q-pt-none q-pb-none q-mt-xs q-px-sm"> <!-- Add the text-center class to this div -->
              <q-btn size="13px" flat color="accent" @click="router.push({ name: 'ApplicantLoginPage' });"
                     label="Zaloguj się"
                     dense
                     no-caps/>
            </div>
            <span class="q-mx-xs" style="font-size: 12px; padding-top: 10px">lub</span>
            <q-btn size="13px" flat color="primary" @click="router.push({ name: 'EmployeeRegistration' });"
                   label="zarejestruj"
                   class="text-center q-pt-none q-pb-none q-mt-xs q-px-sm"
                   dense
                   no-caps/>
          </div>
          <span style="font-size: 12px">używając konta aplikanta</span>

        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
  <div class="row full-width justify-between q-pr-none q-mb-sm q-mt-md">
    <q-btn style="border-radius: 7px;font-size: 13px; height: 39px; border-width: 1px;"
           align="left"
           flat
           no-caps
           class="bg-white text-grey-9 q-mr-md" @click="openFilters()">
      <q-icon name="tune" color="accent"></q-icon>
      <p v-if="!isMobile()" class="q-ma-none q-pl-sm">Filtruj ogłosznia</p>
    </q-btn>
    <q-btn style="border-radius: 7px;font-size: 13px; height: 39px; border-width: 1px;"
           align="left"
           flat
           no-caps
           :class="getColorsForRemoteWorkButton()" @click="addRemoteWorkToFilter()">
      <q-icon name="fa-solid fa-house-laptop" :color="getRemoteWorkIconColor()"></q-icon>
      <p v-if="!isMobile()" class="q-ma-none q-pl-sm">Praca zdalna</p>
    </q-btn>
    <q-select class="col q-px-md text-grey-9"
              v-model="all"
              :options="filteredAll"
              label-color="grey-9"
              bg-color="white"
              color="accent"
              standout="bg-white text-grey-9"
              popup-content-class="limiting"
              stack-label
              :input-style="{color:'#212121'}"
              :popup-content-style="{ maxHeight: '200px',height: '200px', borderRadius: '0 0 7px 7px',fontSize: '12px' }"
              use-input
              style="border-radius: 7px;"
              clear-icon="cancel"
              clearable
              menu-shrink
              multiple
              use-chips
              dense
              @clear="clearSearchValues()"
              label="Szukaj po słowach kluczowych"
              transition-show="scale"
              transition-hide="scale"
              @update:model-value="(value) => addValueToFilter(value)"
              @filter="(val, update) => filterAll(val, update, allOptions)"
    >
      <template v-slot:option="{ itemProps, opt}">
        <q-item v-bind="itemProps">
          <q-item-section>
            <q-item-label v-html="opt.label"/>
          </q-item-section>
          <q-item-section side>
            <q-badge outline :color="opt.color" :label="opt.name"/>
          </q-item-section>
        </q-item>
      </template>
    </q-select>

    <q-select dense
              style="font-size: 10px; border-radius: 9px"
              transition-show="scale"
              transition-hide="scale"
              borderless
              color="accent"
              v-model="sorting"
              class="bg-white q-pl-sm"
              rounded
              :popup-content-style="{borderRadius: '0 0 7px 7px', fontSize: '12px',width: '200px' }"
              :options="sortingOptions"
              :options-dense="true"
              @update:model-value="emitFilterValues()"/>
    <q-btn
        v-if="isObserved"
        color="accent"
        @click="changeObservedValueAndEmit()"
        class="q-ml-md bg-white" flat icon="fa-solid fa-star"></q-btn>
    <q-btn
        @click="changeObservedValueAndEmit()"
        v-else
        flat
        color="white"
        text-color="grey-5"
        class="q-ml-md bg-white" icon="fa-solid fa-star"></q-btn>
    <q-dialog v-model="card" position="left" class="full-height">
      <q-card style="border-radius:0px 15px 15px 0px; width: 450px">
        <q-card-section class="q-pt-sm">
          <div class="q-pb-sm">
            <p style="font-size: 20px" class="text-bold q-mb-none q-pl-sm text-primary">Filtrowanie ofert</p>
          </div>
          <div>
            <q-separator></q-separator>
          </div>
          <div class="q-pt-md">
            <q-select class="q-px-sm q-pt-xs" color="primary" outlined
                      v-model="jobPositions"
                      :options="filteredJobPositions"
                      label-color="primary"
                      stack-label
                      use-input
                      clear-icon="cancel"
                      clearable
                      multiple
                      :popup-content-style="{ maxHeight: '200px',height: '200px', borderRadius: '0 0 7px 7px',fontSize: '12px' }"
                      use-chips
                      input-debounce="0"
                      dense
                      label="Stanowisko"
                      popup-content-class="limiting"
                      transition-show="scale"
                      transition-hide="scale"
                      @filter="(val, update) => filterPositions(val, update, jobPositionsOptions)"
            >
              <template v-slot:option="{ itemProps, opt}">
                <q-item v-bind="itemProps">
                  <q-item-section>
                    <q-item-label v-html="opt"/>
                  </q-item-section>
                </q-item>
              </template>
            </q-select>
          </div>
          <div class="q-pt-md q-pb-md">
            <q-select class="q-px-sm q-pt-xs" color="primary"
                      outlined
                      dense
                      v-model="skills"
                      stack-label
                      :options="filteredSkills"
                      label="Technologie"
                      popup-content-class="limiting"
                      label-color="primary"
                      use-input
                      clear-icon="cancel"
                      clearable
                      multiple
                      :popup-content-style="{ maxHeight: '200px',height: '200px', borderRadius: '0 0 7px 7px',fontSize: '12px' }"
                      use-chips transition-show="scale"
                      transition-hide="scale"
                      @filter="(val,update) => filterSkills(val,update,skillsOptions)"
            >
              <template v-slot:option="{ itemProps, opt}">
                <q-item v-bind="itemProps">
                  <q-item-section>
                    <q-item-label v-html="opt"/>
                  </q-item-section>
                </q-item>
              </template>
            </q-select>
          </div>
          <div>
            <q-select class="q-px-sm q-pt-xs" color="primary" outlined
                      v-model="localizations"
                      :options="filteredLocalizations"
                      label-color="primary"
                      stack-label
                      use-input
                      dense
                      label="Lokalizacja"
                      use-chips
                      clear-icon="cancel"
                      clearable
                      popup-content-class="limiting"
                      multiple
                      :popup-content-style="{ maxHeight: '200px',height: '200px', borderRadius: '0 0 7px 7px',fontSize: '12px' }"
                      transition-show="scale"
                      transition-hide="scale"
                      @filter="(val,update) => filterLocalizations(val,update,localizationOptions)"
            >
              <template v-slot:option="{ itemProps, opt}">
                <q-item v-bind="itemProps">
                  <q-item-section>
                    <q-item-label v-html="opt"/>
                  </q-item-section>
                </q-item>
              </template>
            </q-select>
          </div>
          <div class="q-pt-md">
            <q-select class="q-px-sm q-pt-xs" color="primary" outlined
                      v-model="languages"
                      :options="filteredLanguages"
                      label-color="primary"
                      stack-label
                      use-input
                      dense
                      label="Języki"
                      use-chips
                      :popup-content-style="{ maxHeight: '200px',height: '200px', borderRadius: '0 0 7px 7px',fontSize: '12px' }"
                      clear-icon="cancel"
                      clearable
                      multiple
                      transition-show="scale"
                      transition-hide="scale"
                      @filter="(val,update) => filterLanguages(val,update,languageOptions)"
            >
              <template v-slot:option="{ itemProps, opt}">
                <q-item v-bind="itemProps">
                  <q-item-section>
                    <q-item-label v-html="opt"/>
                  </q-item-section>
                </q-item>
              </template>
            </q-select>
          </div>
          <div class="q-pt-md">
            <q-select class="q-px-sm q-pt-xs" color="primary" outlined
                      v-model="industries"
                      :options="filteredIndustries"
                      label-color="primary"
                      stack-label
                      use-input
                      dense
                      label="Firma - branża"
                      use-chips
                      :popup-content-style="{ maxHeight: '200px',height: '200px', borderRadius: '0 0 7px 7px',fontSize: '12px' }"
                      clear-icon="cancel"
                      clearable
                      multiple
                      transition-show="scale"
                      transition-hide="scale"
                      @filter="(val,update) => filterIndustries(val,update,industryOptions)"
            >
              <template v-slot:option="{ itemProps, opt}">
                <q-item v-bind="itemProps">
                  <q-item-section>
                    <q-item-label v-html="opt"/>
                  </q-item-section>
                </q-item>
              </template>
            </q-select>
          </div>
          <div class="q-px-sm q-pt-md q-pb-sm">
            <p class="q-mb-none text-primary text-bold">Wynagrodzenie</p>
          </div>
          <div class="q-px-md q-pt-lg">
            <q-range
                v-model="range"
                :inner-min="0"
                color="primary"
                label-always
                :left-label-value="range.min + ' zł'"
                :right-label-value="evaluateRightSalaryLabel()"
                :min="0"
                :popup-content-style="{ maxHeight: '200px',height: '200px', borderRadius: '0 0 7px 7px',fontSize: '12px' }"
                style="max-width: 98%"
                :step="1000"
                :max="40000"
                :inner-max="41000"
                drag-rang
            />
          </div>
          <div class="q-px-sm q-pt-xs">
            <p class="q-mb-none text-primary text-bold">Doświadczenie</p>
            <div class="q-pt-sm full-width">
              <q-btn-group outline :v-model="experience" class="full-width">
                <q-btn outline
                       @click="setInternTo(false)"
                       v-if="isIntern" style="font-size: 10px"
                       class="text-primary q-px-sm col"
                       label="STAŻ"/>
                <q-btn outline
                       @click="setInternTo(true)"
                       v-else style="font-size: 10px"
                       class="text-grey-6 q-px-sm col"
                       label="STAŻ"/>
                <q-btn outline
                       @click="setJuniorTo(false)"
                       v-if="isJunior"
                       style="font-size: 10px"
                       class="text-primary q-px-sm col"
                       label="JUNIOR"/>
                <q-btn outline
                       @click="setJuniorTo(true)"
                       v-else
                       style="font-size: 10px"
                       class="text-grey-6 q-px-sm col"
                       label="JUNIOR"/>
                <q-btn outline
                       @click="setMidTo(false)"
                       v-if="isMid" style="font-size: 10px"
                       class="text-primary q-px-sm col"
                       label="MID"/>
                <q-btn outline
                       @click="setMidTo(true)"
                       v-else
                       style="font-size: 10px"
                       class="text-grey-6 q-px-sm col"
                       label="MID"/>
                <q-btn outline
                       @click="setSeniorTo(false)"
                       v-if="isSenior"
                       style="font-size: 10px"
                       class="text-primary q-px-sm col"
                       label="SENIOR"/>
                <q-btn outline
                       @click="setSeniorTo(true)"
                       v-else style="font-size: 10px"
                       class="text-grey-6 q-px-sm col"
                       label="SENIOR"/>
                <q-btn outline
                       @click="setArchitectTo(false)"
                       v-if="isArchitect"
                       style="font-size: 10px"
                       class="text-primary q-px-sm col" label="ARCHITEKT"/>
                <q-btn outline
                       @click="setArchitectTo(true)"
                       v-else
                       style="font-size: 10px"
                       class="text-grey-6 q-px-sm col"
                       label="ARCHITEKT"/>
              </q-btn-group>
            </div>
          </div>

          <div class="q-px-sm q-pt-xs">
            <p class="q-mb-none text-primary text-bold">Tryb pracy</p>
            <div class="q-pt-sm full-width">
              <q-btn-group outline :v-model="experience" class="full-width">
                <q-btn outline @click="setRemoteTo(false)" v-if="isRemote" style="font-size: 10px"
                       class="full-width text-primary"
                       label="ZDALNA"/>
                <q-btn outline @click="setRemoteTo(true)" v-else style="font-size: 10px" class="full-width text-grey-6"
                       label="ZDALNA"/>
                <q-btn outline @click="setIsHybrid(false)" v-if="isHybrid" style="font-size: 10px"
                       class="full-width text-primary" label="HYBRYDOWA"/>
                <q-btn outline @click="setIsHybrid(true)" v-else style="font-size: 10px" class="text-grey-6 full-width"
                       label="HYBRYDOWA"/>
                <q-btn outline @click="setInOffice(false)" v-if="isInOffice" style="font-size: 10px"
                       class="text-primary full-width" label="STACJONARNA"/>
                <q-btn outline @click="setInOffice(true)" v-else style="font-size: 10px" class="text-grey-6 full-width"
                       label="STACJONARNA"/>
              </q-btn-group>
            </div>
          </div>

          <div class="q-px-sm q-pt-xs">
            <p class="q-mb-none text-primary text-bold">Zatrudnienie</p>
            <div class="q-pt-sm full-width">
              <q-btn-group outline :v-model="experience" class="full-width">
                <q-btn outline @click="setFullTime(false)" v-if="isFullTime" style="font-size: 10px"
                       class="full-width text-primary"
                       label="PEŁNY ETAT"/>
                <q-btn outline @click="setFullTime(true)" v-else style="font-size: 10px" class="full-width text-grey-6"
                       label="PEŁNY ETAT"/>
                <q-btn outline @click="setPartTime(false)" v-if="isPartTime" style="font-size: 10px"
                       class="full-width text-primary" label="CZĘŚĆ ETATU"/>
                <q-btn outline @click="setPartTime(true)" v-else style="font-size: 10px" class="text-grey-6 full-width"
                       label="CZĘŚĆ ETATU"/>
              </q-btn-group>
            </div>
          </div>

          <div class="q-px-sm q-pt-xs">
            <p class="q-mb-none text-primary text-bold">Typ umowy</p>
            <div class="q-pt-sm full-width">
              <q-btn-group outline :v-model="experience" class="full-width">
                <q-btn outline @click="setB2BTo(false)" v-if="isB2B" style="font-size: 10px"
                       class="full-width text-primary"
                       label="B2B"/>
                <q-btn outline @click="setB2BTo(true)" v-else style="font-size: 10px" class="full-width text-grey-6"
                       label="B2B"/>
                <q-btn outline @click="setUOPTo(false)" v-if="isUOP" style="font-size: 10px"
                       class="full-width text-primary" label="UoP"/>
                <q-btn outline @click="setUOPTo(true)" v-else style="font-size: 10px" class="text-grey-6 full-width"
                       label="UoP"/>
                <q-btn outline @click="setZlecenieTo(false)" v-if="isZlecenie" style="font-size: 10px"
                       class="text-primary full-width" label="Zlecenie"/>
                <q-btn outline @click="setZlecenieTo(true)" v-else style="font-size: 10px"
                       class="text-grey-6 full-width"
                       label="Zlecenie"/>
              </q-btn-group>
            </div>
          </div>
        </q-card-section>
        <q-card-section>
          <div class="row q-pt-md justify-between q-px-sm q-pb-none">
            <q-btn size="sm" color="primary" outline label="Resetuj filtry" @click="resetFiltersAndEmitEvent()"
                   style="border-radius: 7px"/>
            <q-btn size="sm" color="dark" label="Szukaj" @click="searchUsingFilters()"
                   style="border-radius: 7px"/>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>
<script setup>
import {defineEmits, defineExpose, onMounted, ref} from 'vue'
import JobPositionTranslator from "@/services/offer/JobPositionTranslator";
import {useQuasar} from 'quasar'

const card = ref(false)
const formTemplateData = ref(null)

const emit = defineEmits(['filterValues', 'observed'])
const jobPositions = ref([])
const jobPositionsOptions = ref([])
const skillsOptions = ref([])
const localizations = ref([])
const localizationOptions = ref([])
const skills = ref([])
const languages = ref([])
const industries = ref([])
const languageOptions = ref([])
const industryOptions = ref([])
const all = ref([])
const allOptions = ref([])
const isObserved = ref(false)
const showFavButtonNotAvailable = ref(false)
import {currentUser} from "@/global-variables/currentUser";

onMounted(() => {
  getFormtTemplateData().then(data => {
    formTemplateData.value = data;
    jobPositionsOptions.value = formTemplateData.value.jobOffer.jobPositions.map(jobPosition => JobPositionTranslator.translateConstant(jobPosition));
    skillsOptions.value = formTemplateData.value.jobOffer.skills.map(skill => skill.name);
    localizationOptions.value = formTemplateData.value.jobOffer.cities
    languageOptions.value = translateLanguagesToPolish(formTemplateData.value.jobOffer.languages)
    industryOptions.value = translateIndustriesToPolish(formTemplateData.value.organization.industries)
    allOptions.value = initializeAllOptions();
  });
})

function resetFiltersAndEmitEvent(){
  resetAllFilters()
  emitFilterValues()
}
function initializeAllOptions() {
  const jobPositionsOptions = formTemplateData.value.jobOffer.jobPositions.map(jobPosition => ({
    label: jobPosition,
    name: 'Stanowisko',
    color: 'primary'
  }));

  const skillsOptions = formTemplateData.value.jobOffer.skills.map(skill => ({
    label: skill.name,
    name: 'Technologia',
    color: 'accent'
  }));

  const localizationOptions = formTemplateData.value.jobOffer.cities.map(city => ({
    label: city,
    name: 'Lokalizacja',
    color: 'blue-grey-5'
  }));

  const languageOptions = translateLanguagesToPolish(formTemplateData.value.jobOffer.languages).map(language => ({
    label: language,
    name: 'Język',
    color: 'cyan-4'
  }));

  const industryOptions = translateIndustriesToPolish(formTemplateData.value.organization.industries).map(industry => ({
    label: industry,
    name: 'Branża',
    color: 'cyan-4'
  }));

  const seniorityLevels = translateSeniorityLevelsToPolish(formTemplateData.value.jobOffer.seniorityLevels).map(seniority => ({
    label: seniority,
    name: 'Doświadczenie',
    color: 'blue-8'
  }));

  const contractTypes = translateContractTypesToPolish(formTemplateData.value.jobOffer.contractTypes).map(type => ({
    label: type,
    name: 'Typ umowy',
    color: 'red-4'
  }));

  const operatingModes = translateOperatingModesToPolish(formTemplateData.value.jobOffer.operatingModes).map(mode => ({
    label: mode,
    name: 'Tryb pracy',
    color: 'purple-5'
  }));

  const allOptions = [...jobPositionsOptions,
    ...skillsOptions,
    ...localizationOptions,
    ...languageOptions,
    ...industryOptions,
    ...contractTypes,
    ...seniorityLevels,
    ...operatingModes];
  allOptions.sort((a, b) => a.name.localeCompare(b.name));
  return allOptions;
}

function changeObservedValueAndEmit() {
  if (currentUser.isAuthenticated() && currentUser.isApplicant()) {
    if (isObserved.value) {
      isObserved.value = false;
      emitFilterValues()
    } else {
      isObserved.value = true;
      emitFilterValues()
    }
  } else {
    showFavButtonNotAvailable.value = true
  }
}

function getFormtTemplateData() {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json"
    },
  };
  return fetch(process.env.VUE_APP_BACKEND_URL + "/job-offers/form-template-data", requestOptions)
      .then((response) => {
        return response.json().then((data) => {
          return data
        }).catch(() => {
          // console.log(err);
        })
      })
}

const experience = ref([])

const isIntern = ref(false)
const isJunior = ref(false)
const isMid = ref(false)
const isSenior = ref(false)
const isArchitect = ref(false)


function setInternTo(boolean) {
  isIntern.value = boolean;
}

function setJuniorTo(boolean) {
  isJunior.value = boolean;
}

function setMidTo(boolean) {
  isMid.value = boolean;
}

function setSeniorTo(boolean) {
  isSenior.value = boolean;
}

function setArchitectTo(boolean) {
  isArchitect.value = boolean;
}

const isB2B = ref(false)
const isUOP = ref(false)
const isZlecenie = ref(false)

function setB2BTo(boolean) {
  isB2B.value = boolean;
}

function setUOPTo(boolean) {
  isUOP.value = boolean;
}

function setZlecenieTo(boolean) {
  isZlecenie.value = boolean;
}

const range = ref({
  min: 0,
  max: 40000
})

function evaluateRightSalaryLabel() {
  if (range.value.max >= 40000) {
    return range.value.max + '+ zł'
  } else {
    return range.value.max + ' zł'
  }
}

const filteredJobPositions = ref([])

function filterPositions(val, update, options) {
  filter(val, update, options, filteredJobPositions)
}

function filter(val, update, options, filtered) {
  update(() => {
    if (val === "") {
      filtered.value = options
    } else {
      const needle = val.toLowerCase()
      filtered.value = options.filter(
          v => v.toLowerCase().indexOf(needle) > -1
      )
    }
  })
}

const filteredSkills = ref([])

function filterSkills(val, update, options) {
  filter(val, update, options, filteredSkills)
}

const filteredLocalizations = ref([])

function filterLocalizations(val, update, options) {
  filter(val, update, options, filteredLocalizations)
}

const filteredLanguages = ref([])

function filterLanguages(val, update, options) {
  filter(val, update, options, filteredLanguages)
}

const filteredIndustries = ref([])

function filterIndustries(val, update, options) {
  filter(val, update, options, filteredIndustries)
}

const filteredAll = ref([])

function filterAll(val, update, options) {
  update(() => {
    if (val === "") {
      filteredAll.value = options;
    } else {
      let needle = val.toLowerCase();
      filteredAll.value = options.filter(
          v => v.label.toLowerCase().indexOf(needle) > -1
      );
    }
  });
}

// function toContractTypes(isB2B, isZlecenie, isUoP) {
//   const values = [];
//
//   if (isB2B.value) {
//     values.push("B2B");
//   }
//
//   if (isZlecenie.value) {
//     values.push("ZLECENIE");
//   }
//
//   if (isUoP.value) {
//     values.push("UOP");
//   }
//
//   return values;
// }
// const emit = defineEmits(['filterValues'])

function searchUsingFilters(){
  clearMapWithAllOptions()
  all.value = []
  emitFilterValues()
}
function emitFilterValues() {
  if (!isBarSearchNotUsed()) {
    emit('filterValues',
        optionsMap['Stanowisko'].value,
        optionsMap['Technologia'].value,
        seniorityLevelToSearchConstantForSearch(optionsMap['Doświadczenie'].value),
        jobOperatingModeToSearchConstantForSearch(optionsMap['Tryb pracy'].value),
        contractTypesToSearchConstantForSearch(optionsMap['Typ umowy'].value),
        range.value.min,
        range.value.max,
        optionsMap['Lokalizacja'].value,
        translateLanguagesToConstants(optionsMap['Język'].value),
        translateIndustriesToConstants(optionsMap['Branża'].value),
        mapSortingOptionToConstant(sorting.value),
        ['FULL_TIME'],
        isObserved.value,
    )
  } else {
    card.value = false;
    console.info("INDUSTRIES " + industries.value)
    emit('filterValues',
        translatePositionsToModel(),
        skills.value,
        seniorityLevelToSearchConstantForFilter(),
        jobOperatingModeToSearchConstantForFilter(),
        contractTypesToSearchConstantForFilter(),
        range.value.min,
        range.value.max,
        localizations.value,
        translateLanguagesToConstants(languages.value),
        translateIndustriesToConstants(industries.value),
        mapSortingOptionToConstant(sorting.value),
        jobWorkTypesToSearchConstantForFilter(),
        isObserved.value,
    )
  }
}

function translatePositionsToModel() {
  return jobPositions.value.map(position => JobPositionTranslator.translateToModel(position))
}

//TODO expose this method to reset filters when result not found
function resetAllFilters() {
  jobPositions.value = []
  skills.value = []
  localizations.value = []
  languages.value = []
  range.value.min = 0
  range.value.max = 40000
  isIntern.value = false
  isJunior.value = false
  isMid.value = false
  isSenior.value = false
  isArchitect.value = false
  isRemote.value = false
  isHybrid.value = false
  isInOffice.value = false
  isB2B.value = false
  isUOP.value = false
  isZlecenie.value = false
  isFullTime.value = false
  isPartTime.value = false
  isObserved.value = false
  all.value = []
  clearMapWithAllOptions()
}

function resetDialogFilters() {
  jobPositions.value = []
  skills.value = []
  localizations.value = []
  languages.value = []
  range.value.min = 0
  range.value.max = 40000
  isIntern.value = false
  isJunior.value = false
  isMid.value = false
  isSenior.value = false
  isArchitect.value = false
  isRemote.value = false
  isHybrid.value = false
  isInOffice.value = false
  isB2B.value = false
  isUOP.value = false
  isZlecenie.value = false
  isFullTime.value = false
  isPartTime.value = false
  isObserved.value = false
}

function seniorityLevelToSearchConstantForFilter() {
  const constants = [];

  if (isIntern.value) {
    constants.push('TRAINEE');
  }
  if (isJunior.value) {
    constants.push('JUNIOR');
  }
  if (isMid.value) {
    constants.push('MID');
  }
  if (isSenior.value) {
    constants.push('SENIOR');
  }
  if (isArchitect.value) {
    constants.push('ARCHITECT');
  }
  return constants;
}


const isRemote = ref(false)
const isHybrid = ref(false)
const isInOffice = ref(false)

const isFullTime = ref(false)
const isPartTime = ref(false)

function setRemoteTo(boolean) {
  isRemote.value = boolean;
}

function setIsHybrid(boolean) {
  isHybrid.value = boolean;
}

function setInOffice(boolean) {
  isInOffice.value = boolean;
}

function setFullTime(boolean) {
  isFullTime.value = boolean;
}

function setPartTime(boolean) {
  isPartTime.value = boolean;
}

function jobOperatingModeToSearchConstantForFilter() {
  const constants = [];

  if (isHybrid.value) {
    constants.push('HYBRID');
  }
  if (isRemote.value) {
    constants.push('REMOTE');
  }
  if (isInOffice.value) {
    constants.push('IN_OFFICE');
  }

  return constants;
}

function jobWorkTypesToSearchConstantForFilter() {
  const constants = [];

  if (isFullTime.value) {
    constants.push('FULL_TIME');
  }
  if (isPartTime.value) {
    constants.push('PART_TIME');
  }
  return constants;
}

function contractTypesToSearchConstantForFilter() {
  const constants = [];

  if (isB2B.value) {
    constants.push('B2B');
  }
  if (isUOP.value) {
    constants.push('UOP');
  }
  if (isZlecenie.value) {
    constants.push('UZ');
  }

  return constants;
}

function translateLanguagesToPolish(constantsArray) {
  const languageTranslations = {
    'POLISH': 'Polski',
    'ENGLISH': 'Angielski',
    'GERMAN': 'Niemiecki',
    'SPANISH': 'Hiszpański',
    'FRENCH': 'Francuski',
    'ITALIAN': 'Włoski',
    'RUSSIAN': 'Rosyjski',
    'PORTUGUESE': 'Portugalski',
    'UKRAINIAN': 'Ukraiński',
    'ALBANIAN': 'Albański',
    'BOSNIAN': 'Bośniacki',
    'BULGARIAN': 'Bułgarski',
    'BELARUSIAN': 'Białoruski',
    'CHINESE': 'Chiński',
    'CROATIAN': 'Chorwacki',
    'CZECH': 'Czeski',
    'DANISH': 'Duński',
    'DUTCH': 'Holenderski',
    'ESTONIAN': 'Estoński',
    'FINNISH': 'Fiński',
    'GREEK': 'Grecki',
    'HUNGARIAN': 'Węgierski',
    'JAPANESE': 'Japoński',
    'KOREAN': 'Koreański',
    'LATVIAN': 'Łotewski',
    'LITHUANIAN': 'Litewski',
    'NORWEGIAN': 'Norweski',
    'FILIPINO': 'Filipiński',
    'ROMANIAN': 'Rumuński',
    'SERBIAN': 'Serbski',
    'SLOVAK': 'Słowacki',
    'SLOVENE': 'Słoweński',
    'SWEDISH': 'Szwedzki',
    'TURKISH': 'Turecki',
    'VIETNAMESE': 'Wietnamski',
    'WELSH': 'Walijski'
  };

  return constantsArray.map((language) => {
    return languageTranslations[language] || language;
  });
}

function translateIndustriesToPolish(constantsArray) {
  const industryTranslations = {
    "ACCOUNTING": "Księgowość",
    "ADVERTISING": "Reklama",
    "ADTECH": "Technologie reklamowe",
    "AEROSPACE": "Przemysł lotniczy",
    "AGRICULTURE": "Rolnictwo",
    "ARTIFICIAL_INTELLIGENCE": "Sztuczna inteligencja",
    "AUTOMOTIVE": "Motoryzacja",
    "AVIATION": "Lotnictwo",
    "BANKING": "Bankowość",
    "BIG_DATA": "Big Data",
    "BIOTECHNOLOGY": "Biotechnologia",
    "BLOCKCHAIN": "Blockchain",
    "BUSINESS_INTELLIGENCE": "Inteligencja biznesowa",
    "BUSINESS_PROCESS_AUTOMATION": "Automatyzacja procesów biznesowych",
    "BUSINESS_SERVICES": "Usługi biznesowe",
    "CLOUD_SERVICES": "Usługi chmurowe",
    "COMPUTER_HARDWARE": "Sprzęt komputerowy",
    "COMPUTER_NETWORKS": "Sieci komputerowe",
    "CONSULTING": "Doradztwo",
    "CYBER_SECURITY": "Cyberbezpieczeństwo",
    "DATA_ANALYTICS": "Analiza danych",
    "DATA_STORAGE": "Przechowywanie danych",
    "DESIGN": "Projektowanie",
    "DIGITAL_MARKETING": "Marketing cyfrowy",
    "DISTRIBUTION": "Dystrybucja",
    "E_COMMERCE": "E-commerce",
    "E_LEARNING": "E-learning",
    "EDTECH": "Technologie edukacyjne",
    "EDUCATION": "Edukacja",
    "ELECTRONICS": "Elektronika",
    "ENERGY": "Energia",
    "ENGINEERING": "Inżynieria",
    "ENTERTAINMENT": "Rozrywka",
    "FILM": "Film",
    "FINANCIAL": "Finanse",
    "FINTECH": "Fintech",
    "GAMING": "Gry komputerowe",
    "GRAPHIC_DESIGN": "Projektowanie graficzne",
    "HEALTH": "Zdrowie",
    "HEALTHCARE": "Opieka zdrowotna",
    "HEALTHTECH": "Technologie medyczne",
    "HIGH_TECH": "Technologia wysokich technologii",
    "INFORMATION_TECHNOLOGY": "Technologia informacyjna",
    "INSURANCE": "Ubezpieczenia",
    "IOT": "Internet Rzeczy (IoT)",
    "IT_INFRASTRUCTURE": "Infrastruktura informatyczna",
    "LEGAL": "Prawny",
    "LOGISTICS": "Logistyka",
    "MACHINE_LEARNING": "Uczenie maszynowe",
    "MANUFACTURING": "Produkcja",
    "MARKETING": "Marketing",
    "MEDIA": "Media",
    "MEDICAL": "Medyczny",
    "MILITARY": "Wojskowy",
    "MUSIC": "Muzyka",
    "NANOTECHNOLOGY": "Nanotechnologia",
    "NETWORK_SECURITY": "Bezpieczeństwo sieci",
    "NON_PROFIT": "Non-profit",
    "OUTSOURCING": "Outsourcing",
    "QUANTUM_COMPUTING": "Obliczenia kwantowe",
    "PHARMACEUTICAL": "Farmacja",
    "PUBLIC_SECTOR": "Sektor publiczny",
    "REAL_ESTATE": "Nieruchomości",
    "RECRUITING": "Rekrutacja",
    "RETAIL": "Handel detaliczny",
    "ROBOTICS": "Robotyka",
    "SALES": "Sprzedaż",
    "SMART_CITY": "Miasto inteligentne",
    "SOCIAL_NETWORKING": "Sieci społecznościowe",
    "SOFTWARE_DEVELOPMENT": "Rozwój oprogramowania",
    "SOFTWARE_HOUSE": "Dom oprogramowania",
    "SPACE_TECHNOLOGY": "Technologia kosmiczna",
    "TELECOMMUNICATIONS": "Telekomunikacja",
    "TOURISM": "Turystyka",
    "TRANSPORT": "Transport",
    "VR_AR": "Rzeczywistość wirtualna",
    "WEB_SERVICES": "Usługi internetowe",
    "WEB_TOOLS": "Narzędzia internetowe",
    "OTHER": "Inny"
    // Add translations for other industries as needed
  };

  return constantsArray.map((industry) => {
    return industryTranslations[industry] || industry;
  });
}

function translateIndustriesToConstants(polishIndustriesArray) {
  const industryConstants = {
    'Księgowość': 'ACCOUNTING',
    'Reklama': 'ADVERTISING',
    'Technologie reklamowe': 'ADTECH',
    'Przemysł lotniczy': 'AEROSPACE',
    'Rolnictwo': 'AGRICULTURE',
    'Sztuczna inteligencja': 'ARTIFICIAL_INTELLIGENCE',
    'Motoryzacja': 'AUTOMOTIVE',
    'Lotnictwo': 'AVIATION',
    'Bankowość': 'BANKING',
    'Big Data': 'BIG_DATA',
    'Biotechnologia': 'BIOTECHNOLOGY',
    'Blockchain': 'BLOCKCHAIN',
    'Inteligencja biznesowa': 'BUSINESS_INTELLIGENCE',
    'Automatyzacja procesów biznesowych': 'BUSINESS_PROCESS_AUTOMATION',
    'Usługi biznesowe': 'BUSINESS_SERVICES',
    'Usługi chmurowe': 'CLOUD_SERVICES',
    'Sprzęt komputerowy': 'COMPUTER_HARDWARE',
    'Sieci komputerowe': 'COMPUTER_NETWORKS',
    'Doradztwo': 'CONSULTING',
    'Cyberbezpieczeństwo': 'CYBER_SECURITY',
    'Analiza danych': 'DATA_ANALYTICS',
    'Przechowywanie danych': 'DATA_STORAGE',
    'Projektowanie': 'DESIGN',
    'Marketing cyfrowy': 'DIGITAL_MARKETING',
    'Dystrybucja': 'DISTRIBUTION',
    'E-commerce': 'E_COMMERCE',
    'E-learning': 'E_LEARNING',
    'Technologie edukacyjne': 'EDTECH',
    'Edukacja': 'EDUCATION',
    'Elektronika': 'ELECTRONICS',
    'Energia': 'ENERGY',
    'Inżynieria': 'ENGINEERING',
    'Rozrywka': 'ENTERTAINMENT',
    'Film': 'FILM',
    'Finanse': 'FINANCIAL',
    'Fintech': 'FINTECH',
    'Gry komputerowe': 'GAMING',
    'Projektowanie graficzne': 'GRAPHIC_DESIGN',
    'Zdrowie': 'HEALTH',
    'Opieka zdrowotna': 'HEALTHCARE',
    'Technologie medyczne': 'HEALTHTECH',
    'Technologia wysokich technologii': 'HIGH_TECH',
    'Technologia informacyjna': 'INFORMATION_TECHNOLOGY',
    'Ubezpieczenia': 'INSURANCE',
    'Internet Rzeczy (IoT)': 'IOT',
    'Infrastruktura informatyczna': 'IT_INFRASTRUCTURE',
    'Prawny': 'LEGAL',
    'Logistyka': 'LOGISTICS',
    'Uczenie maszynowe': 'MACHINE_LEARNING',
    'Produkcja': 'MANUFACTURING',
    'Marketing': 'MARKETING',
    'Media': 'MEDIA',
    'Medyczny': 'MEDICAL',
    'Wojskowy': 'MILITARY',
    'Muzyka': 'MUSIC',
    'Nanotechnologia': 'NANOTECHNOLOGY',
    'Bezpieczeństwo sieci': 'NETWORK_SECURITY',
    'Non-profit': 'NON_PROFIT',
    'Outsourcing': 'OUTSOURCING',
    'Obliczenia kwantowe': 'QUANTUM_COMPUTING',
    'Farmacja': 'PHARMACEUTICAL',
    'Sektor publiczny': 'PUBLIC_SECTOR',
    'Nieruchomości': 'REAL_ESTATE',
    'Rekrutacja': 'RECRUITING',
    'Handel detaliczny': 'RETAIL',
    'Robotyka': 'ROBOTICS',
    'Sprzedaż': 'SALES',
    'Miasto inteligentne': 'SMART_CITY',
    'Sieci społecznościowe': 'SOCIAL_NETWORKING',
    'Rozwój oprogramowania': 'SOFTWARE_DEVELOPMENT',
    'Dom oprogramowania': 'SOFTWARE_HOUSE',
    'Technologia kosmiczna': 'SPACE_TECHNOLOGY',
    'Telekomunikacja': 'TELECOMMUNICATIONS',
    'Turystyka': 'TOURISM',
    'Transport': 'TRANSPORT',
    'Rzeczywistość wirtualna (VR) / Rozszerzona rzeczywistość (AR)': 'VR_AR',
    'Usługi internetowe': 'WEB_SERVICES',
    'Narzędzia internetowe': 'WEB_TOOLS',
    'Inny': 'OTHER'
    // Add translations for other industries as needed
  };

  return polishIndustriesArray.map((polishIndustry) => {
    return industryConstants[polishIndustry] || polishIndustry;
  });
}


function translateLanguagesToConstants(polishLanguagesArray) {
  const languageConstants = {
    'Angielski': 'ENGLISH',
    'Hiszpański': 'SPANISH',
    'Francuski': 'FRENCH',
    'Niemiecki': 'GERMAN',
    'Włoski': 'ITALIAN',
    'Polski': 'POLISH',
    'Rosyjski': 'RUSSIAN',
    'Chiński': 'CHINESE',
    'Japoński': 'JAPANESE',
    'Koreański': 'KOREAN'
    // Add translations for other languages as needed
  };

  return polishLanguagesArray.map((polishLanguage) => {
    return languageConstants[polishLanguage] || polishLanguage;
  });
}

const sorting = ref('Domyślne');
const sortingOptions = ref([
  'Najwyższe wynagrodzenie', 'Najniższe wynagrodzenie', 'Domyślne'
])

function mapSortingOptionToConstant(sortingOption) {
  switch (sortingOption) {
    case 'Najwyższe wynagrodzenie':
      return 'HIGHEST_SALARY';
    case 'Najniższe wynagrodzenie':
      return 'LOWEST_SALARY';
    case 'Domyślne':
      return 'DEFAULT';
    case 'Najstarsze':
      return 'OLDEST';
    case 'Obserwowane':
      return 'Obserwowane';
    default:
      return 'DEFAULT'; // Or throw an error, depending on your use case
  }
}

function translateSeniorityLevelsToPolish(levels) {
  const seniorityTranslations = {
    TRAINEE: "Staż",
    JUNIOR: "Junior",
    MID: "Mid",
    SENIOR: "Senior",
    ARCHITECT: "Architekt"
  };
  return levels.map(level => seniorityTranslations[level]);
}

function translateContractTypesToPolish(types) {
  const contractTypes = {
    B2B: "B2B",
    UOP: "UoP",
    UZ: "Umowa zlecenie",
  };
  return types.map(type => contractTypes[type]);
}

function translateOperatingModesToPolish(modes) {
  const contractTypes = {
    IN_OFFICE: "Stacjonarna",
    HYBRID: "Hybrydowa",
    REMOTE: "Zdalna",
  };
  return modes.map(type => contractTypes[type]);
}


const optionsMap = {
  'Stanowisko': ref([]),
  'Technologia': ref([]),
  'Lokalizacja': ref([]),
  'Język': ref([]),
  'Branża': ref([]),
  'Doświadczenie': ref([]),
  'Typ umowy': ref([]),
  'Tryb pracy': ref([]),
};

function isBarSearchNotUsed() {
  return Object.values(optionsMap).every(refItem => refItem.value.length === 0);
}

function addValueToFilter(values) {
  resetDialogFilters()
  clearMapWithAllOptions();
  addValuesToAllOptions(values);
  emitFilterValues()
}

function addValuesToAllOptions(values) {
  if (values !== null) {
    values.forEach(obj => {
      const {name, label} = obj;
      optionsMap[name].value.push(label);
    });
  }
}

function clearMapWithAllOptions() {
  for (const key in optionsMap) {
    if (Object.prototype.hasOwnProperty.call(optionsMap, key)) {
      optionsMap[key].value = [];
    }
  }
}

function clearSearchValues() {
  clearMapWithAllOptions()
  emit('filterValues',
      optionsMap['Stanowisko'].value,
      optionsMap['Technologia'].value,
      seniorityLevelToSearchConstantForSearch(optionsMap['Doświadczenie'].value),
      jobOperatingModeToSearchConstantForSearch(optionsMap['Tryb pracy'].value),
      contractTypesToSearchConstantForSearch(optionsMap['Typ umowy'].value),
      range.value.min,
      range.value.max,
      optionsMap['Lokalizacja'].value,
      translateLanguagesToConstants(optionsMap['Język'].value),
      translateIndustriesToConstants(optionsMap['Branża'].value),
      mapSortingOptionToConstant(sortingOptions));
}

function seniorityLevelToSearchConstantForSearch(searchValues) {
  const constants = []
  if (searchValues.includes('Staż')) {
    constants.push('TRAINEE');
  }
  if (searchValues.includes('Junior')) {
    constants.push('JUNIOR');
  }
  if (searchValues.includes('Mid')) {
    constants.push('MID');
  }
  if (searchValues.includes('Senior')) {
    constants.push('SENIOR');
  }
  if (searchValues.includes('Architekt')) {
    constants.push('ARCHITECT');
  }
  return constants;
}

function contractTypesToSearchConstantForSearch(values) {
  const constants = [];

  if (values.includes('B2B')) {
    constants.push('B2B');
  }
  if (values.includes('UoP')) {
    constants.push('UOP');
  }
  if (values.includes('Umowa zlecenie')) {
    constants.push('UZ');
  }
  return constants;
}

function jobOperatingModeToSearchConstantForSearch(values) {
  const constants = [];
  console.info("OPERATION MODES " + values)
  if (values.includes('Hybrydowa')) {
    constants.push('HYBRID');
  }
  if (values.includes('Zdalna')) {
    constants.push('REMOTE');
  }
  if (values.includes('Stacjonarna')) {
    constants.push('IN_OFFICE');
  }

  return constants;
}

function showFilterDialog() {
  card.value = true;
}

const $q = useQuasar()

function isMobile() {
  return $q.platform.is.mobile
}

function addRemoteWorkToFilter() {
  if (isRemote.value) {
    setRemoteTo(false)
  } else {
    setRemoteTo(true)
  }
  if (!isBarSearchNotUsed()) {
    optionsMap['Tryb pracy'].value.push("Zdalna");
  }
  emitFilterValues();
}

function getColorsForRemoteWorkButton() {
  if (isRemote.value) {
    return 'text-accent bg-white'
  } else {
    return 'text-grey-6 bg-white'
  }
}

function getRemoteWorkIconColor() {
  if (isRemote.value) {
    return 'accent'
  } else {
    return 'grey-6'
  }
}

defineExpose({
  showFilterDialog,
  resetAllFilters
})

function openFilters() {
  card.value = true
}</script>

<style lang="scss" scoped>
//to hide horizontally
//:deep(.q-select .q-field__native row) {
//  white-space: nowrap;
//  overflow: hidden;
//  text-overflow: ellipsis;
//  width: 100%;
//}

:deep(.q-chip) {
  font-size: 10px;
}

:deep(.q-field--auto-height.q-field--dense.q-field--labeled .q-field__control-container) {
  padding-top: 10px;
}

:deep(.q-field--outlined .q-field__control) {
  border-radius: 7px;
}

:deep(.q-field--outlined .q-field__control::before) {
  border-color: $primary;
}

.q-btn-group {
  border-radius: 5px;
}
</style>