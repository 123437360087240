<template>
  <div>
    <q-dialog v-model="showColorDialog">
      <q-card class="q-pa-sm" style="border-radius: 9px">
        <q-card-section class="q-pt-none">
          <div class="row q-gutter-md">
            <div>
              <q-chip :style="definePrimaryColorButton()" class="q-ml-none q-mb-sm full-width"
                      style="border-radius: 7px" label="kolor podstawowy"></q-chip>
              <q-color v-model="organizationData.branding.primaryColor"
                       no-header-tabs
                       no-footer
                       class="my-picker"/>
            </div>
            <div>
              <q-chip :style="defineSecondaryColorButton()" class="q-ml-none q-mb-sm full-width"
                      style="border-radius: 7px" label="kolor dodatkowy"></q-chip>
              <q-color v-model="organizationData.branding.secondaryColor"
                       no-header-tabs
                       no-footer
                       class="my-picker"/>
            </div>
          </div>
        </q-card-section>
        <q-card-section class="q-pt-none">
          <q-btn class="full-width" style="border-radius: 7px" no-caps size="md" text-color="grey-9" label="Zamknij"
                 @click="showColorDialog=false"></q-btn>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
  <div style="height: 100%" class="column">
    <div class="q-mx-sm">
      <q-card bordered flat class="q-mt-sm q-mb-lg full-width"
              style="border-radius: 9px;background-color: white; border-width: 0.2px;">
        <q-card-section>
          <my-text class="text-white">
            <span class="text-weight-bold text-primary" style="font-size: 15px">Podstawowe informacje</span> <span
              class="text-grey-9">
                o firmie widoczne w ogłoszeniu. Dane z tej sekcji będą automatycznie dodawane do każdego ogłoszenia.
      </span>
          </my-text>
        </q-card-section>
      </q-card>
    </div>

    <q-card class="q-pa-sm q-ma-sm" style="border-radius: 9px" :style="calculateBackgroundGradient()">
      <div class="row">
        <div class="q-mt-sm q-ml-sm">
          <organization-data-logo v-model="organizationData"></organization-data-logo>
        </div>
        <div class="col q-ml-md">
          <div>
            <q-input class="q-pa-sm q-pb-md"
                     v-model="organizationData.name"
                     outlined
                     bg-color="white"
                     label="Nazwa firmy widoczna w ogłoszeniu"
                     :rules="[  val => val.length > 0 || 'Nazwa firmy jest wymagana' ]"
                     dense>
            </q-input>
          </div>
          <div class="row">
            <div class="col">
              <q-select class="col q-pa-sm q-pb-md"
                        :autofocus="false"
                        bg-color="white"
                        outlined
                        v-model="organizationData.type"
                        :options="organizationTypeOptions"
                        label="Rodzaj firmy"
                        dense>
              </q-select>
            </div>
            <div class="col">
              <q-select class="col q-pa-sm q-pb-md" color="primary"
                        outlined
                        stack-label
                        bg-color="white"
                        :autofocus="false"
                        v-model="organizationData.size"
                        :options="companySizeOptions"
                        dense
                        label="Liczba pracowników">
              </q-select>
            </div>
          </div>
          <div>
            <q-select class="col q-pa-sm" color="primary"
                      outlined
                      bg-color="white"
                      :autofocus="false"
                      v-model="organizationData.industries"
                      :options="industryOptions"
                      dense
                      label="Branża">
            </q-select>
          </div>
        </div>
      </div>
    </q-card>
    <div class="q-my-lg q-ma-sm">
      <q-card flat bordered style="border-radius: 9px">
        <q-card-section class="q-pa-md">
          <div class="row justify-between">
            <div>
              <my-text><span class="text-bold text-primary">Branding</span> - wybierz dwa główne kolory definiujące
                Waszą markę
              </my-text>
            </div>
            <div class="row q-gutter-md">
              <q-btn @click="showColorDialog=true" size="sm" :style="definePrimaryColorButton()"></q-btn>
              <q-btn @click="showColorDialog=true" size="sm" :style="defineSecondaryColorButton()"></q-btn>
            </div>
          </div>
        </q-card-section>
      </q-card>
    </div>
    <q-card flat bordered class="q-mx-sm col row column">
      <q-editor
          class="column col row q-pb-md q-mt-sm q-mx-sm text-grey-9 full-height"
          color="accent"
          stack-label
          style="font-size: 12px; border-width: 0px; border-radius: 9px"
          outlined
          bg-color="dark"
          v-model="organizationData.description"
          toolbar-color="primary"
          dense
      >
      </q-editor>
    </q-card>
  </div>
</template>
<script setup>
import OrganizationDataLogo from "@/components/organization/data/OrganizationDataLogo";
import {computed, defineEmits, defineProps, onMounted, ref} from "vue";
import OrganizationDataTranslator from "@/services/organization/OrganizationDataTranslator";


const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  formTemplateData: Object,
  modelValue: Object
})

const industryOptions = ref([])
const organizationTypeOptions = ref([])
const companySizeOptions = ref([])

const organizationData = computed({
  get: () => props.modelValue,
  set: (val) => emit('update:modelValue', val),
});

onMounted(() => {
  industryOptions.value = OrganizationDataTranslator.translateCompanyIndustriesToPolish()
  organizationTypeOptions.value = OrganizationDataTranslator.translateCompanyTypeToPolish()
  companySizeOptions.value = OrganizationDataTranslator.translateCompanySizesToPolish()
})

const showColorDialog = ref(false)


function calculateBackgroundGradient() {
  return {
    background: 'linear-gradient(45deg,' + organizationData.value.branding.primaryColor + ',' + organizationData.value.branding.secondaryColor + ')'
  }
}

function definePrimaryColorButton() {
  return 'background:' + organizationData.value.branding.primaryColor
}

function defineSecondaryColorButton() {
  return 'background:' + organizationData.value.branding.secondaryColor
}
</script>