class JsonBlankFieldsCleaner {
    removeEmptyFields(obj) {
        for (let key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
                if (obj[key] === null || obj[key] === undefined || obj[key] === '' ||
                    (typeof obj[key] === 'object' && !Array.isArray(obj[key]) && Object.keys(obj[key]).length === 0) ||
                    (Array.isArray(obj[key]) && obj[key].length === 0)) {
                    delete obj[key];
                } else if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
                    this.removeEmptyFields(obj[key]);
                    if (Object.keys(obj[key]).length === 0) {
                        delete obj[key];
                    }
                }
            }
        }
        return obj;
    }
}

export default new JsonBlankFieldsCleaner();