class JobOfferPlansService {
    constructor(url) {
        this.url = url;
    }

     getBasicPlan(plans) {
       return plans.find(item => item.type === 'BASIC');
    }

     getStandardPlan(plans) {
       return plans.find(item => item.type === 'STANDARD');
    }

     getPremiumPlan(plans) {
        return plans.find(item => item.type === 'PREMIUM');
    }
}

export default new JobOfferPlansService(process.env.VUE_APP_BACKEND_URL);
