import StringUtils from "@/services/common/StringUtils";

class OrganizationUsersService {
    constructor(url) {
        this.url = url;
    }

// Create a JavaScript method using fetch to get users from the API
    async getUsersWithCookie() {
        const url = this.url + '/organizations/' + localStorage.getItem("organizationId") + '/users?size=200';

        try {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json', // Add any other headers if required
                },
                credentials: 'include', // Include credentials to send cookies
            };

            const response = await fetch(url, requestOptions);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            return await response.json(); // Assuming the server returns a JSON response
        } catch (error) {
            console.error('Error during organization user GET method:', error);
            throw error;
        }
    }

    async addUser(newUserDto) {
        if (StringUtils.isStringEmpty(newUserDto.firstName)) {
            console.info("DE:LETE")
            delete newUserDto.firstName; // This deletes the property from the object
        }
        if (StringUtils.isStringEmpty(newUserDto.lastName)) {
            delete newUserDto.lastName;
        }
        if (StringUtils.isStringEmpty(newUserDto.phoneNumber)) {
            delete newUserDto.phoneNumber;
        }
        console.info(JSON.stringify(newUserDto))
        try {
            const requestOptions = {
                method: 'POST',
                credentials: 'include',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(newUserDto),
            };
            const response = await fetch(this.url + '/organizations/' + localStorage.getItem("organizationId") + '/users', requestOptions);
            return response;
        } catch (error) {
            console.error('Error during request:', error);
            throw error;
        }
    }


    async changeUserAccountActivity(active, userId) {
        try {
            const requestOptions = {
                method: 'PUT',
                credentials: 'include',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({active: active}),
            };
            const response = await fetch(this.url + '/organizations/' + localStorage.getItem("organizationId") + '/users/' + userId + '/status', requestOptions);
            return response;
        } catch (error) {
            console.error('Error during request:', error);
            throw error;
        }
    }

    async getCurrentUser() {
        const url = this.url + '/organizations/' + localStorage.getItem("organizationId") + '/users?size=200';

        try {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json', // Add any other headers if required
                },
                credentials: 'include', // Include credentials to send cookies
            };

            const response = await fetch(url, requestOptions);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            let json = await response.json()
            return json.content.filter(account => account.email === localStorage.getItem('username'))[0];
        } catch (error) {
            console.error('Error during organization user GET method:', error);
            throw error;
        }
    }

    async passAdmin(userId) {
        try {
            const requestOptions = {
                method: 'PUT',
                credentials: 'include',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({userId: userId}),
            };
            const response = await fetch(this.url + '/organizations/' + localStorage.getItem("organizationId") + '/users/admin', requestOptions);
            localStorage.clear()
            console.info("clearing 14")
            return response;
        } catch (error) {
            console.error('Error during request:', error);
            throw error;
        }
    }

    async updateUserData(user) {
        try {
            const requestOptions = {
                method: 'PUT',
                credentials: 'include',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    firstName: user.firstName,
                    lastName: user.lastName,
                    phoneNumber: user.phoneNumber,
                    position: user.position
                }),
            };
            const response = await fetch(this.url + '/organizations/' + localStorage.getItem("organizationId") + '/users/' + user.id, requestOptions);
            return response;
        } catch (error) {
            console.error('Error during request:', error);
            throw error;
        }
    }

    async getUser(userId) {
        const url = this.url + '/organizations/' + localStorage.getItem("organizationId") + '/users/' + userId;

        try {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json', // Add any other headers if required
                },
                credentials: 'include', // Include credentials to send cookies
            };

            const response = await fetch(url, requestOptions);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return await response.json(); // Assuming the server returns a JSON response
        } catch (error) {
            console.error('Error during organization user GET method:', error);
            throw error;
        }
    }

    async updateUserPermissions(userId, permissinos) {
        try {
            const requestOptions = {
                method: 'PUT',
                credentials: 'include',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(permissinos),
            };
            return await fetch(this.url + '/organizations/' + localStorage.getItem("organizationId") + '/users/' + userId + '/permissions', requestOptions);
        } catch (error) {
            console.error('Error during request:', error);
            throw error;
        }
    }

    async changeAvatar(userId, fileInBase64, fileName) {
        try {
            const cacheKey = `avatar-${userId}`;
            const cacheName = 'avatar-cache';
            const cache = await caches.open(cacheName);
            await cache.delete(cacheKey);
            const requestOptions = {
                method: 'PUT',
                credentials: 'include',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({fileInBase64, fileName}),
            };
            await fetch(this.url + '/organizations/' + localStorage.getItem("organizationId") + '/users/' + userId + '/avatar', requestOptions);
        } catch (error) {
            console.error('Error during request:', error);
            throw error;
        }
    }

    async getAvatar(userId) {
        try {
            const requestOptions = {
                method: 'GET',
                credentials: 'include',
                headers: {
                    "Content-Type": "application/json",
                },
            };
            return await fetch(this.url + '/organizations/' + localStorage.getItem("organizationId") + '/users/' + userId + '/avatar', requestOptions);
        } catch (error) {
            console.error('Error during request:', error);
            throw error;
        }
    }

    async deleteAvatar(userId) {
        try {
            const cacheKey = `avatar-${userId}`;
            const cacheName = 'avatar-cache';
            const cache = await caches.open(cacheName);
            await cache.delete(cacheKey);
            const requestOptions = {
                method: 'DELETE',
                credentials: 'include',
                headers: {
                    "Content-Type": "application/json",
                },
            };
            return await fetch(this.url + '/organizations/' + localStorage.getItem("organizationId") + '/users/' + userId + '/avatar', requestOptions);
        } catch (error) {
            console.error('Error during request:', error);
            throw error;
        }
    }

    async changePassword(userId, oldPassword, newPassword) {
        try {
            const requestOptions = {
                method: 'PUT',
                credentials: 'include',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({newPassword, oldPassword}),
            };
            return await fetch(this.url + '/organizations/' + localStorage.getItem("organizationId") + '/users/' + userId + '/change-password', requestOptions);
        } catch (error) {
            console.error('Error during request:', error);
            throw error;
        }
    }

    async resetPassword(email) {
        try {
            const requestOptions = {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(email),

            };
            return await fetch(this.url + '/organizations/password-reset', requestOptions);
        } catch (error) {
            console.error('Error during request:', error);
            throw error;
        }
    }

    async resetSetupForPassword(userId,organizationId,password, token) {
        try {
            const requestOptions = {
                method: 'PUT',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    password: password,
                    token: token
                })
            };
            return await fetch(this.url + '/organizations/' + organizationId+ '/users/' + userId + '/password', requestOptions);
        } catch (error) {
            console.error('Error during request:', error);
            throw error;
        }
    }

}

export default new OrganizationUsersService(process.env.VUE_APP_BACKEND_URL);
