<template>
  <div>
    <q-btn-dropdown style="border-radius: 5px"
                    no-caps
                    flat
                    icon="fa-solid fa-earth-europe"
                    dense
                    :disable-main-btn="props.locations.length <= 1"
                    :disable-dropdown="props.locations.length <= 1"
                    :content-style="{maxHeight:'200px', borderRadius: '0 0 7px 7px', fontSize: '10px' }"
                    class="q-pr-none" :color="getColor()" size="xs"
                    :label="getLabelWithFirstCityAndCount()">
      <q-list>
        <q-item v-for="location in props.locations" :key="location">
          <q-item-section>
            <q-item-label>{{ location.city }}</q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </q-btn-dropdown>
  </div>
</template>
<script setup>
import {defineProps} from "vue";

const props = defineProps({
  locations: Array,
  isSelected: Boolean
})

function getLabelWithFirstCityAndCount() {
  return props.locations[0].city + ' ' + '+' + (props.locations.length - 1);
}

function getColor() {
  if (props.isSelected) {
    return 'grey-9'
  } else {
    return 'grey-6'
  }
}

// Example usage within a Vue component's template:
// <template>
//   <div>{{ getLabelWithFirstCityAndCount() }}</div>
// </template>

</script>
<style lang="scss" scoped>
:deep(.q-btn--dense .on-left) {
  margin-left: 3px !important;
  margin-right: 8px !important;
}
</style>