import script from "./Language.vue?vue&type=script&setup=true&lang=js"
export * from "./Language.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QRating from 'quasar/src/components/rating/QRating.js';
import qInstall from "../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBadge,QRating});
