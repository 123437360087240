
import './styles/quasar.scss'
import '@quasar/extras/material-symbols-outlined/material-symbols-outlined.css'
import '@quasar/extras/material-icons/material-icons.css'
import '@quasar/extras/mdi-v7/mdi-v7.css'
import '@quasar/extras/material-icons-outlined/material-icons-outlined.css'
import '@fortawesome/fontawesome-free/css/all.css'

import Notify from 'quasar/src/plugins/Notify.js';;
import Dialog from 'quasar/src/plugins/Dialog.js';;

// To be used on app.use(Quasar, { ... })
export default {
  config: {},
  plugins: {
    Notify,
    Dialog
  }
}