class OfferCounterService {
    constructor(url) {
        this.url = url;
    }


    async increaseOfferView(offerId) {
        try {
            const endpoint = '/job-offers/' + offerId + '/views';
            return (await fetch(this.url + endpoint, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },
            }));
        } catch (error) {
            console.error('Error during request:', error);
            throw error;
        }
    }

}

export default new OfferCounterService(process.env.VUE_APP_BACKEND_URL);
