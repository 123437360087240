class OrganizatioOfferManagementService {
    constructor(url) {
        this.url = url;
    }

    async addJobOffer(jobOffer) {
        const organizationId = localStorage.getItem("organizationId");
        return this._saveJobOffer(jobOffer, "POST", "/organizations/" + organizationId + "/job-offers");
    }

    async updateJobOffer(jobOffer) {
        const organizationId = localStorage.getItem("organizationId");
        const cleanedJobOffer = this.removeNullEmptyBlankSalaryFields(jobOffer);
        return this._saveJobOffer(cleanedJobOffer, "PUT", "/organizations/" + organizationId + "/job-offers/" + jobOffer.id);
    }

    removeNullEmptyBlankSalaryFields(jobOffer) {
        const cleanedJobOffer = {...jobOffer}; // Clone the jobOffer object to avoid mutating the original object
        if (Array.isArray(cleanedJobOffer.contracts)) {
            cleanedJobOffer.contracts.forEach(contract => {
                if (contract.salary !== null && typeof contract.salary === 'object') {
                    const {from, to, currency} = contract.salary;
                    if (this.isNullOrWhiteSpace(from) || this.isNullOrWhiteSpace(to) || this.isNullOrWhiteSpace(currency)) {
                        delete contract.salary; // Remove entire salary field if any of its fields is null, empty, or blank
                    }
                }
            });
        }
        return cleanedJobOffer;
    }

    isNullOrWhiteSpace(str) {
        return str === null || str === undefined || this.isBlank(str);
    }

    isBlank(str) {
        return /^\s*$/.test(str);
    }


    async extendPublication(jobOfferId, publicationExtension) {
        const organizationId = localStorage.getItem("organizationId");
        return this._saveJobOffer(publicationExtension, "POST", "/organizations/" + organizationId + "/job-offers/" + jobOfferId + '/publications');
    }

    async _saveJobOffer(object, methodType, endpoint) {
        const requestOptions = {
            method: methodType,
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify(object),
        };

        const url = process.env.VUE_APP_BACKEND_URL + endpoint;

        return fetch(url, requestOptions)
            .then((response) => {
                return response;
            });
    }
}


export default new OrganizatioOfferManagementService(process.env.VUE_APP_BACKEND_URL);

