<template>
  <div class="">
    <q-dialog v-model="infoMessageDialog">
      <q-card style="width: 350px;border-radius: 9px">
        <q-card-section class="row justify-center q-pb-none">
          <div class="text-center text-h7 text-grey-9">
            <div class="q-pt-none q-pb-sm q-mb-xs">
              <q-icon size="md" name="fa-solid fa-circle-info" color="positive"></q-icon>
            </div>
            Aby przeglądać i aplikować na oferty pracy uzupełnij dane.
            <span style="font-size: 14px" class="text-bold">Imię</span>,
            <span style="font-size: 14px" class="text-bold">nazwisko</span>
            i
            <span style="font-size: 14px" class="text-bold">Cv</span>
            są wymagane.
          </div>
        </q-card-section>
        <q-card-section class="text-center q-mt-xs">
          <q-btn no-caps padding="3px xl" color="primary" label="Ok" @click="infoMessageDialog=false"></q-btn>
        </q-card-section>
      </q-card>
    </q-dialog>
    <q-card bordered class="q-mb-lg col row" style="border-radius: 9px; border-color: #c9d7ec">
      <q-card-section>
        <my-text class="q-mx-sm" style="font-size: 12px"><span class="text-weight-bold text-primary">Dane profilu</span>
          są używane w procesie aplikacji na wybraną ofertę.
        </my-text>
      </q-card-section>
      <div class="q-mx-lg q-mb-sm">
        <q-separator></q-separator>
      </div>
      <div class="row full-width">
        <q-card-section style="max-width: 170px" class="col q-mb-md">
          <div class="text-center">
            <q-avatar v-if="isAvatarReady" class="text-center q-mt-none q-ma-xs q-ml-md" size="85px"
                      style="background-color: #c9d7ec">
              <q-img v-if="userAvatar && userAvatar.contentType && userAvatar.file" fit="fill"
                     v-bind:src="'data:' + userAvatar.contentType + ';base64,' + userAvatar.file"
                     style="width: 100%; height: 100%;"
              />
              <q-img v-else fit="contain"
                     v-bind:src="require('@/assets/blank-avatar-4.png')"
                     style="width: 100%; height: 100%"/>
            </q-avatar>
            <q-avatar v-else class="text-center q-mt-none q-ma-xs q-ml-md" size="85px"
                      style="background-color: #c9d7ec">
              <q-img fit="contain"
                     style="width: 100%; height: 100%"/>
            </q-avatar>
          </div>
          <div class="row q-mt-xs">
            <q-btn class="q-mt-xs col q-pl-none q-pr-none" flat size="sm" color="primary" @click="changeAvatar()"
                   label="zmień"/>
            <q-btn class="q-mt-xs col q-pl-none q-pr-none" flat size="sm" color="red-4" @click="deleteAvatar()"
                   label="usuń"/>
          </div>
        </q-card-section>

        <div class="col">
          <div class="row">
            <q-card-section class="row col q-pb-sm">
              <q-form ref="childForm" class="row full-width" greedy>
                <div class="full-width col">
                  <q-input class="q-pa-sm q-pb-md col" color="primary" stack-label outlined bg-color="white"
                           v-model="user.firstName"
                           label-color="primary"
                           label="Imię"
                           :rules="[ val => val !== null && val.length !== 0 || 'Imię jest wymagane' ]"
                           dense>
                    <template v-slot:append>
                      <q-avatar>
                        <q-icon
                            name="sym_o_title"
                            size="1rem"/>
                      </q-avatar>
                    </template>
                  </q-input>
                </div>
                <div class="full-width col">
                  <q-input class="q-pa-sm q-pb-md col"
                           color="primary" stack-label outlined bg-color="white"
                           v-model="user.lastName"
                           label-color="primary"
                           label="Nazwisko"
                           :rules="[ val =>  val !== null && val.length !== 0 || 'Nazwisko jest wymagane' ]"
                           dense>
                    <template v-slot:append>
                      <q-avatar>
                        <q-icon
                            name="sym_o_title"
                            size="1rem"/>
                      </q-avatar>
                    </template>
                  </q-input>
                </div>
              </q-form>
            </q-card-section>
          </div>
          <div class="row q-mx-md">
            <div class="col">
              <q-input class="q-pa-sm q-pb-md col"
                       color="primary" stack-label outlined bg-color="white"
                       v-model="user.phoneNumber"
                       label-color="primary"
                       label="Numer telefonu"
                       dense>
                <template v-slot:append>
                  <q-avatar>
                    <q-icon
                        name="sym_o_title"
                        size="1rem"/>
                  </q-avatar>
                </template>
              </q-input>
            </div>
            <div class="full-width col">
              <q-input class="q-pa-sm q-pb-md col"
                       color="primary" stack-label outlined bg-color="white"
                       v-model="user.email"
                       disable
                       label-color="primary"
                       label="E-mail"
                       :rules="[ val => val.length <= 40 || 'Please use maximum 40 characters' ]" dense>
                <template v-slot:append>
                  <q-avatar>
                    <q-icon
                        name="sym_o_title"
                        size="1rem"/>
                  </q-avatar>
                </template>
              </q-input>
            </div>
          </div>
        </div>
      </div>

    </q-card>


    <q-card bordered style="border-radius: 9px; border-color: #c9d7ec">
      <q-card-section>
        <my-text class="q-mx-sm" style="font-size: 12px"><span class="text-weight-bold text-primary">Preferowany czas kontaktu</span>
          informuje pracodawcę o przedziale czasowym w którym może się z Tobą skontktować.
        </my-text>
        <div class="q-mx-sm q-my-md">
          <q-separator>
          </q-separator>
        </div>
        <div class="col-8">
          <div style="margin-top: 1px" class="q-mb-xs q-ml-sm">
            <my-text style="font-size: 12px" color="white" class="text-grey-9">
              Preferowane godziny kontaktu: od <span style="font-weight: bold" class="text-primary">{{
                user.contactInSpecificHours.min
              }}:00</span> do <span style="font-weight: bold" class="text-primary">{{
                user.contactInSpecificHours.max
              }}:00</span>
            </my-text>
          </div>
          <div class="q-px-sm">
            <q-range
                color="primary"
                v-model="user.contactInSpecificHours"
                :min="7"
                :max="18"
                :step="1"
                thumb-size="12px"
            />
          </div>
        </div>
      </q-card-section>
    </q-card>

  </div>

</template>
<script setup>
import {computed, defineEmits, defineExpose, defineProps, onBeforeMount, ref} from "vue";
import AuthenticationService from "@/services/AuthenticationService";
import ApplicantDataService from "@/services/applicant/ApplicantDataService";
import NotificationService from "@/services/notification/NotificationService";
import {useQuasar} from "quasar";
import {useRouter} from "vue-router/dist/vue-router";
import StringUtils from "@/services/common/StringUtils";

const userAvatar = ref(null)

const props = defineProps({
  formTemplateData: Object,
  modelValue: Object
})

const user = computed({
  get: () => props.modelValue,
  set: (val) => emit('update:modelValue', val),
});

const emit = defineEmits(['update:modelValue'])

const $q = useQuasar()
const message = ref(null)
const infoMessageDialog = ref(false)
const router = useRouter()
onBeforeMount(() => {
  initUserAvatar();
  message.value = router.currentRoute.value.query.infoMessage;
  if (!StringUtils.isStringEmpty(message.value)) {
    infoMessageDialog.value = true;
  }
});


const avatar = ref(null);
const isAvatarReady = ref(false)

function changeAvatar() {
  let input = document.createElement('input');
  input.type = 'file';
  input.accept = '.jpg, .jpeg, .png,'; // Specify accepted file extensions
  input.onchange = () => {
    let files = Array.from(input.files);
    console.info(files[0])
    if (files.length > 0) {
      const file = files[0];
      const allowedExtensions = ["jpg", "jpeg", "png"];
      const fileExtension = file.name.split('.').pop().toLowerCase();

      if (allowedExtensions.includes(fileExtension)) {
        const reader = new FileReader();

        reader.onload = () => {
          var parts = reader.result.split(',');
          avatar.value = parts[1].trim(); //
          ApplicantDataService.changeAvatar(user.value.id, avatar.value, file.name).then(() => {
            user.value = AuthenticationService.getAuthenticatedUser().then(response => {
              response.json().then(data => {
                if (response.ok) {
                  user.value = data
                  NotificationService.showNotification($q, 'positive', 'Zmieniono avatar')
                  initUserAvatar();
                } else if (response.status === 400 && data.errorCode === "VALIDATION_FAILED") {
                  NotificationService.showNotification($q, 'negative', 'Błędny format pliku. Wspierane rozszerzenia: .jpg .jpeg .png')
                }
              })
            })
          })
        };

        reader.readAsDataURL(file);
      } else {
        NotificationService.showNotification($q, 'negative', 'Błędny format logo. Wspierane formaty .jpg .jpeg .png .svg');
      }
    }
  }

  input.click();
}

async function initUserAvatar() {
  await ApplicantDataService.getAvatar(user.value.id).then(response => {
    response.json().then(json => {
      userAvatar.value = json
    });
  })
  isAvatarReady.value = true
}

function deleteAvatar() {
  ApplicantDataService.deleteAvatar(user.value.id)
      .then(response => {
        if (response.status === 204) {
          NotificationService.showNotification($q, 'negative', 'Usunięto avatar')
          userAvatar.value = null
        }
      })
}

defineExpose({
  validate
})

const childForm = ref(null)

async function validate() {
  let validate = await childForm.value.validate();
  return validate;
}
</script>