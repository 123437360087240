<template>
  <div class="q-pt-xs q-pb-md">
    <q-card class="my-card" style="border-radius: 9px">
      <div class="q-pt-sm q-pl-md">
        <my-text class="text-grey-9" style="font-size: 14px">
          <strong> Podobne oferty
          </strong>
        </my-text>
      </div>
      <div class="q-px-md q-pt-sm q-pb-md">
        <q-separator></q-separator>
      </div>
      <q-card-section>
        <div class="q-col-gutter-md">
          <div v-for="jobOffer in offers" :key="jobOffer.id">
            <similar-offer-overview
                style="border-color: #afafaf"
                :job-offer="jobOffer"
                :is-fav="false"
                :is-selected="true"
                class="bg-white">
            </similar-offer-overview>
          </div>
        </div>
      </q-card-section>
    </q-card>
  </div>
</template>
<script setup>
import {defineProps, onMounted, ref,} from 'vue';
import JobOfferService from "@/services/JobOfferService";
import SimilarOfferOverview from "@/components/common/job-offers/SimilarOfferOverview";
const offers = ref([])

const props = defineProps({
  jobOfferId: String,
});
//
// import {useRouter} from 'vue-router';
// const router = useRouter()
onMounted(() => {
  fetchSimilarOffers()
})

function fetchSimilarOffers() {
  JobOfferService.getSimilarJobOffers(props.jobOfferId).then(data => {
    offers.value = data
  })
}


</script>