import App from './JobHuntApp.vue'
import {createApp} from 'vue'
import Quasar from 'quasar/src/vue-plugin.js';
import VueApexCharts from "vue3-apexcharts";
// import router from '@/router/router.js'
import quasarUserOptions from './quasar-user-options'
import vue3GoogleLogin from 'vue3-google-login'
import router from './router'

const app = createApp(App).use(router).use(router).use(Quasar, quasarUserOptions);
// app.use(router)

app.use(VueApexCharts, vue3GoogleLogin, {clientId: 'YOUR_GOOGLE_CLIENT_ID'}, Quasar, quasarUserOptions, {data: {q: "quasar"}})
    .mount('#app')
