<template>
  <q-layout>
    <q-page-container style="background-color: #dde5ee;">
      <q-page class="column">
        <menu-bar></menu-bar>
        <adding-job-offer-steps></adding-job-offer-steps>
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<script>
import AddingJobOfferSteps from "@/components/common/add-offer/AddingJobOfferSteps";
import MenuBar from "@/components/common/MenuBar";
export default {

  components: { MenuBar, AddingJobOfferSteps }
}
</script>

<style lang="scss" scoped>
.my-card {
  width: 55%;
}
</style>
