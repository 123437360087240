<template style="background-color: #0864a9;">
  <html>
  <body>
  <q-layout>
    <menu-bar class="fixedElementTop"></menu-bar>
    <div v-if="isLoading">Loading...</div>
    <job-offer-details
        v-else
        :show-apply="true"
        :job-offer="jobOffer"
        :curren-user="props.currentUser"></job-offer-details>
    <div>
      <q-footer elevated class=" text-white">
        <q-toolbar>
          <div class="row justify-center full-width q-gutter-md"> <!-- Updated class: justify-center -->
            <div class="column">
              <q-btn class="q-pl-md" dense color="primary" size="10px" icon="work_o" @click="toggleUserCard">
                <my-text class="q-pl-sm q-pr-lg" style="font-size: 15px; color: white">
                  Aplikuj
                </my-text>
              </q-btn>
            </div>
          </div>
        </q-toolbar>
      </q-footer>
      <q-dialog v-model="showUserCard">
        <user-card :job-offer-id="jobOfferId" @close-modal="closeModal()"></user-card>
      </q-dialog>
    </div>
  </q-layout>
  </body>
  </html>
</template>

<script setup>

import MenuBar from "@/components/common/MenuBar";
import {useRouter} from 'vue-router';

import {defineProps, onMounted, ref} from 'vue';
import UserCard from "@/components/common/job-offers/applications/JobApplication";
import JobOfferDetails from "@/components/common/job-offers/JobOfferDetails";

const router = useRouter()
const jobOfferId = router.currentRoute.value.params.id

const isLoading = ref(true);
const jobOffer = ref(null);

const props = defineProps({
  currentUser: Object,
});

const fetchJobOffer = async () => {
  try {
    const response = await fetch(process.env.VUE_APP_BACKEND_URL + `/job-offers/${jobOfferId}`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    jobOffer.value = data;
    console.info(jobOffer)
  } catch (error) {
    console.error(error);
  } finally {
    isLoading.value = false;
  }
};

onMounted(fetchJobOffer);

const showUserCard = ref(false);
// TODO IMPROVE IN DIFFERENT STORY
const toggleUserCard = () => {
  if (jobOffer.value.jobApplicationInfo.applicationType === 'EXTERNAL') {
    window.open(jobOffer.value.jobApplicationInfo.externalApplicationLink, '_blank')
  } else {
    showUserCard.value = !showUserCard.value;
  }
};

function closeModal() {
  showUserCard.value = false;
}
</script>
<style lang="scss" scoped>
.q-chip {
  background: linear-gradient(45deg, $primary, $primary)
}

.q-footer {
  background: linear-gradient(45deg, $secondary, $primary)
}

my-text {
  color: $accent
}

.my-card {
  max-width: 550px;
  margin: 0 auto;
}

.q-chip {
  margin-left: 0px;
}

.fixed-bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.fixedElementTop {
  background-color: rgb(217, 225, 238);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}

.fixedElementBottom {
  background-color: rgb(217, 225, 238);
  //position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 100;
}

html, body {
  background-color: rgb(217, 225, 238);
  height: 100vh;
}

.scrollable-area {
  height: 91vh;
  overflow-y: auto;
}

</style>
