<template>
  <q-layout>
    <q-drawer
        v-model="drawerLeft"
        show-if-above
        @mouseover="miniStateLeft = false"
        @mouseout="miniStateLeft = true"
        :breakpoint="500"
        :width="250"
        class="bg-gradient"
        bordered
    >
      <div>
        <div class="text-center q-mt-lg">
          <q-img fit="contain" :src="logo" style="width: 100px; height: 100px"/>
        </div>

        <div class="text-center">
          <my-text class="text-bold text-white" style="font-size: 30px; font-family: monospace">Byte Job</my-text>
        </div>
      </div>

      <q-list class="column q-col-gutter-sm" style="margin-top: 30px">
        <div class="q-mx-md">
          <q-btn
              rounded
              @click="router.push({ name: 'OrganizationJobOffersBoard' });"
              dense
              flat
              style="border-radius: 6px"
              size="15px"
              icon="fa-solid fa-briefcase"
              align="left"
              class="q-mx-lg full-width text-white"
              color="primary"
              label="Ogłoszenia"
              no-caps
          />
        </div>
        <div class="q-mx-md">
          <q-btn
              rounded
              @click="router.push({ name: 'OrganizationAccountManagement' });"
              dense
              flat
              style="border-radius: 6px"
              size="15px"
              icon="fa-solid fa-address-book"
              align="left"
              class="q-mx-md full-width text-white"
              color="white"
              label="Użytkowicy"
              no-caps
          />
        </div>
        <div class="q-mx-md">
          <q-btn
              rounded
              @click="router.push({ name: 'OrganizationDataPage' });"
              dense
              flat
              style="border-radius: 6px"
              size="15px"
              icon="fa-solid fa-building"
              align="left"
              class="q-mx-md full-width text-white"
              color="white"
              label="Dane firmy"
              no-caps
          />
        </div>
        <div class="q-mx-md">
          <q-btn
              rounded
              @click="router.push({ name: 'InvoicesMainBoard' });"
              dense
              flat
              style="border-radius: 6px"
              size="15px"
              icon="fa-solid fa-file-lines"
              align="left"
              class="q-mx-md full-width text-white bg-dark"
              color="white"
              label="Faktury"
              no-caps
          />
        </div>
        <div class="q-mx-md">
          <q-btn
              rounded
              @click="OrganizationLogoutService.logout()"
              dense
              flat
              style="border-radius: 6px"
              size="15px"
              icon="fa-solid fa-arrow-right-from-bracket"
              align="left"
              class="q-mx-md full-width text-white q-mt-lg"
              color="primary"
              label="Wyloguj"
              no-caps
          />
        </div>
      </q-list>
    </q-drawer>
    <q-page-container class="column col row" style="background-color: rgb(217,225,238);">
      <q-page class="column">
        <menu-bar></menu-bar>
        <invoices></invoices>
      </q-page>
    </q-page-container>
  </q-layout>
</template>
<script setup>
import MenuBar from "@/components/common/MenuBar";
import Invoices from "@/components/organization/invoices/Invoices";

import {useRouter} from "vue-router/dist/vue-router";
const router = useRouter()

import OrganizationLogoutService from "@/services/organization/authorization/OrganizationLogoutService";
</script>
