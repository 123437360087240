class OrganizationApplicationService {
    constructor(url) {
        this.url = url;
    }

    async getApplications(jobOfferId, status) {
        const params = {
            status: status,
            jobOfferId: jobOfferId
        };
        const url = `${this.url}/organizations/${localStorage.getItem('organizationId')}/applications`;

        const options = {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(params)
        };

        fetch(url, options)
            .then(response => response.json())
            .then(data => {
                // handle the response data
                console.log(data);
            })
            .catch(error => {
                // handle the error
                console.error('Error:', error);
            });


        const response = await fetch(url, options);
        const applications = await response.json();
        return applications;
    }

    async patchApplication(applicationId, payload) {
        const url = `${this.url}/organizations/${localStorage.getItem('organizationId')}/applications/${applicationId}`;
        const options = {
            method: 'PATCH',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        };

        const response = await fetch(url, options);
        if (!response.ok) {
            localStorage.clear()
            console.info("clearing 4")
            const error = await response.text();
            console.error(`Error closing notes: ${error}`);
        }
    }

    async patchApplicationStatus(applicationId, status) {
        const payload = {status};
        await this.patchApplication(applicationId, payload);
    }

    async patchApplicationRating(applicationId, rating) {
        const payload = {result: rating};
        await this.patchApplication(applicationId, payload);
    }

    async patchApplicationNotes(applicationId, notes) {
        const payload = {notes: notes};
        await this.patchApplication(applicationId, payload);
    }


    async downloadCV(applicationId) {
        const response = await this.getCV(applicationId);
        const json = await response.json();
        const byteCharacters = atob(json.file);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        // create a Blob object from the binary data
        const blob = new Blob([byteArray], {type: 'application/pdf'});

        // generate a URL for the Blob object
        const url = URL.createObjectURL(blob);

        // create a temporary link element to download the file
        const link = document.createElement('a');
        link.href = url;
        link.download = json.fileName;

        // trigger a click event on the link element to start the download
        document.body.appendChild(link);
        link.click();

        // cleanup the temporary link element and URL
        console.info(link)
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    }

    async getCV(applicationId) {
        const response = await fetch(process.env.VUE_APP_BACKEND_URL + `/organizations/` + localStorage.organizationId + '/applications/' + applicationId + '/resume', {
            credentials: "include"
        });
        if (!response.ok) {
            localStorage.clear()
            console.info("clearing 6")
            const error = await response.text();
            console.error(`Error closing notes: ${error}`);
        }
        return response;
    }

    async deleteApplication(applicationId) {
        const url = process.env.VUE_APP_BACKEND_URL + `/organizations/` + localStorage.organizationId + `/applications/${applicationId}`
        const options = {
            credentials: 'include',
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
        };
        await fetch(url, options);
    }
}

export default new OrganizationApplicationService(process.env.VUE_APP_BACKEND_URL);
