class FormTemplateDataService {
    constructor(url) {
        this.url = url;
    }

    async getFormTemplateData() {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
        };
        return fetch(process.env.VUE_APP_BACKEND_URL + "/job-offers/form-template-data", requestOptions)
            .then((response) => {
                return response.json().then((data) => {
                    return data
                }).catch((error) => {
                    throw error
                })
            })
    }
}

export default new FormTemplateDataService(process.env.VUE_APP_BACKEND_URL);
