<template>
  <div class="q-scrollarea column col row q-mb-none"
       style="overflow-y: auto">
    <div v-for="observedOffer in observedOffers" :key="observedOffer.id" class="full-width" style="margin-top: 15px">
      <observed-offer-overview v-if="observedOffers !== null"
                               :is-fav="true"
                               :job-offer="observedOffer"
                               @observed:remove="id => removeObservedById(id)"
                               @jobOffer:id="id => goToJobOffer(id)"
                               class="bg-white">
      </observed-offer-overview>
    </div>
  </div>
</template>

<script setup>

import {onMounted, ref} from "vue";
import ApplicantObservedOffers from "@/services/applicant/ApplicantObservedOffers";
import ObservedOfferOverview from "@/components/applicant/observed/ObservedOfferOverview";

const observedOffers = ref([])

onMounted(() => {
  let userId = localStorage.getItem('userId');
  ApplicantObservedOffers.getObservedOffers(userId)
      .then(response => {
        if (response.status === 200) {
          response.json().then(body => {
            observedOffers.value = body
          })
        }
      })
})

function removeObservedById(idToRemove) {
  let userId = localStorage.getItem('userId');
  ApplicantObservedOffers.deleteObservedOffers(userId, idToRemove)
      .then(response => {
        if (response.ok) {
          observedOffers.value = observedOffers.value.filter(offer => offer.id !== idToRemove);
        }
      })
}

import {useRouter} from "vue-router/dist/vue-router";

const router = useRouter()
function goToJobOffer(id){
  router.push({
    name: 'JobOfferMainBoard',
    params: { id: id }
  });
}
</script>