<template>
  <q-form ref="childForm" greedy>
    <div class="row q-mb-sm q-mt-sm justify-between">
      <q-checkbox
          v-model="isSalaryRequired"
          icon="alarm"
          size="md"
          color="primary"
          style="font-size: 13px"
          class="text-grey-8 q-mt-none q-mx-none q-mr-sm"
          label="Stawka"
      />
      <q-select class="col q-pa-sm" color="primary" v-model="typeC"
                clear-icon="cancel"
                :options="OfferDataTranslator.translateContractTypesToPolish()" dense
                label="Umowa" outlined stack-label
                :rules="[val => val !== null || 'Wybierz typ umowy']"
      >
      </q-select>
      <div v-if="isSalaryRequired" class="row" style="width: 60%">
        <q-input class="col q-pa-sm" outlined color="primary" v-model="salary.from"
                 label="Stawka od" stack-label dense
                 :rules="[ val => val >=4000 || 'Minimalna stawka wynosi 4000' ,
                  val => val !== null || 'Podaj minimalną stawkę']">
        </q-input>
        <q-input class="col q-pa-sm" color="primary" v-model="salary.to" label="Stawka do" outlined
                 stack-label dense
                 :rules="[ val => val <= salary.from * 2 || 'Max. stawka większa 2X od minimalnej stawki',
                  val => val !== null || 'Podaj maksymalną stawkę',
                  val => val >= salary.from || 'Stawka nie może być mniejsza od minimalnej' ]">
        </q-input>
        <q-select class="col q-pa-sm" color="primary" v-model="salary.currency"
                  :options="props.formTemplateData.jobOffer.currencies" dense
                  label="Waluta" outlined stack-label
                  :rules="[val => val != null || 'Wybierz walutę']">
        </q-select>
        <div v-if="props.vForKey === 0" class="q-pa-sm">
          <q-btn color="primary" style="height: 40px; width: 40px; font-size: 10px" @click="emitAddContractInfo()">
            <q-icon
                name="sym_o_add"
                size="2rem"
            />
          </q-btn>
        </div>
      </div>
      <div v-if="props.vForKey !== 0" class="q-pa-sm">
        <q-btn color="primary" outline style="height: 40px; width: 40px; font-size: 10px"
               @click="emitRemoveContractInfo">
          <q-icon
              name="sym_o_remove"
              size="2rem"
          />
        </q-btn>
      </div>

    </div>
  </q-form>
</template>

<script setup>
import {defineEmits, defineProps, computed, defineExpose, ref, onMounted} from 'vue'
import OfferDataTranslator from "@/services/organization/offer/OfferDataTranslator";

const props = defineProps({
  formTemplateData: Object,
  vForKey: String,
  type: String,
  salary: Object,
})

const typeC = computed({
  get: () => props.type,
  set: (val) => emit('update:type', val),
});

const salary = computed({
  get: () => props.salary,
  set: (val) => emit('update:salary', val),
});

onMounted(() => {
  isSalaryRequired.value = props.salary !== null
  if(props.salary === null){
    salary.value = {from:0,to:0, currency:'PLN'}
  }
})
const isSalaryRequired = ref(false)


const emit = defineEmits(['addContract', 'update:type', 'update:salaryFrom', 'update:salaryTo', 'update:currency'])

function emitAddContractInfo() {
  emit('addContract')
}

function emitRemoveContractInfo() {
  emit('removeContractInfo', props.vForKey)
}

defineExpose({
  validate
})

const childForm = ref(null)

function validate() {
  return childForm.value.validate().then(function(resolvedValue) {
    // Use resolvedValue here
    console.info("resolved " + resolvedValue)
    return resolvedValue; // return the resolved value if needed
  }).catch(function(error) {
    // Handle any errors that might occur during validation
    console.error('Validation error:', error);
    throw error; // optional: rethrow the error if needed
  });
}


// function salaryToString() {
//   if (this.jobOffer.salary !== null) {
//     return this.jobOffer.salary.from.toString() + ' - ' + this.jobOffer.salary.to.toString() + ' ' + this.jobOffer.salary.currency
//   } else{
//     return 'BRAK STAWKI'
//   }
// }
</script>