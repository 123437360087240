<template>
  <div align="right">
    <q-btn style="border-radius: 7px"
           class="q-px-md text-secondary"
           outline
           dense
           rounded align="left"
           label="Dodaj ogłoszenie"
           @click="toAddingOffer()"/>
  </div>
</template>

<script setup>

import {useRouter} from 'vue-router'
import OrganizationDataService from "@/services/organization/OrganizationDataService";
import NotificationService from "@/services/notification/NotificationService";
import {useQuasar} from "quasar";
import AuthenticationService from "@/services/AuthenticationService";

const router = useRouter()
const $q = useQuasar();

async function toAddingOffer() {
  let isLoggedOrganizationUser = await AuthenticationService.isAuthenticatedOrganizationUser();
  if (isLoggedOrganizationUser) {
    OrganizationDataService.getOrganizationInfo().then((organizationDataResponse) => {
      organizationDataResponse.json().then(body => {
        if (OrganizationDataService.areMandatoryFieldsMissing(body)) {
          router.push({name: 'OrganizationDataPage'});
          NotificationService.showNotification($q, 'negative', 'Dodaj wymagane dane przed publikacją oferty: logo, rodzaj firmy, branża, liczba pracowników')
        } else {
          router.push({name: 'AddJobOfferAsLoggedUser'})
        }
      })
    })

  } else {
    router.push('/adding-job-offer')
  }
}


</script>

<style lang="sass" scoped>

p
  font-size: 15px
  color: #575757

.btn-fixed-size
  width: 100%
  height: 56px

.my-card
  width: 100%
  max-width: 350px
  background-color: rgb(217, 225, 238)

.separator
  width: 100%
  margin: 20px
</style>