class ApplicantPasswordResetService {
    constructor(url) {
        this.url = url;
    }

    async resetPassword(email) {
        try {
            const requestOptions = {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(email)

                //TODO move email passing to body
                // body: JSON.stringify({email}),
            };
            return await fetch(this.url + '/applicants/password-reset', requestOptions);
        } catch (error) {
            console.error('Error during request:', error);
            throw error;
        }
    }

    async resetSetupForPassword(userId,password, token) {
        try {
            const requestOptions = {
                method: 'PUT',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    password: password,
                    token: token
                })
            };
            return await fetch(this.url + '/applicants/' + userId + '/password', requestOptions);
        } catch (error) {
            console.error('Error during request:', error);
            throw error;
        }
    }
}

export default new ApplicantPasswordResetService(process.env.VUE_APP_BACKEND_URL);