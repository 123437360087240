<template>
  <div class="q-pb-md">
    <my-text class="text-weight-bold text-primary" style="font-size: 13px">Pytania</my-text>
  </div>
  <q-form ref="childForm" greedy>
    <div v-for="answer in answers" v-bind:key="answer.questionId">
      <div>
        <my-text>{{ answer.question }}</my-text>
        <q-input v-if="answer.required"
                 v-model="answer.answer"
                 class="q-pt-sm q-mb-sm"
                 outlined
                 :rules="[
    val => val.length !== 0 || 'Odpowiedź jest wymagana',
    val => (answer.type !== 'NUMBER' || !isNaN(Number(val))) || 'Odpowiedź musi być liczbą'
  ]"
                 dense
                 label="Wymagane">
        </q-input>

        <q-input v-else
                 v-model="answer.answer"
                 class="q-pt-sm q-mb-lg"
                 outlined
                 dense
                 label="Opcjonalne">
        </q-input>

      </div>
    </div>
  </q-form>
</template>

<script setup>
import {computed, defineEmits, defineExpose, defineProps, onMounted, ref} from 'vue';

const props = defineProps({
  modelValue: Object
});

const answers = computed({
  get: () => props.modelValue,
  set: (val) => emit('update:modelValue', val),
});

const emit = defineEmits(['update:modelValue'])
onMounted(() => {

})

defineExpose({
  validate
})

const childForm = ref(null)


async function validate() {
  childForm.value.submit()
  let validate = await childForm.value.validate();
  return validate;
}
</script>

<style scoped>

</style>