<template>
  <div align="center" style="margin-bottom: 10px">
    <GoogleLogin :callback="callback" class="q-pa-xs q-pt-md">
      <q-btn no-caps outline class="google-button" color="dark" size=17px>
        <img class="google-icon" src="@/assets/google.svg" alt="google logo"/>
        <label>Register with Google</label>
      </q-btn>
    </GoogleLogin>
  </div>

</template>

<script setup>
const callback = (response) => {
  // This callback will be triggered when the user selects or login to
  // his Google account from the popup
  console.log("Handle the response", response)
}
</script>
<style lang="sass" scoped>
.input-fixed-size
  width: 100%
  height: 56px
  padding-bottom: 5px
  margin-bottom: 9px

.google-icon
  width: 20px
  height: 20px
  margin-right: 5px

.google-button
  border-radius: 30px
  width: 100%
  height: 45px
</style>