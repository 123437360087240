class QuestionFilterTranslator {
    mathOperators = {
        "=": "EQUAL",
        "!=": "NOT_EQUAL",
        "<": "LESS_THAN",
        ">": "MORE_THAN",
        "<=": "LESS_THAN_OR_EQUAL",
        ">=": "MORE_THAN_OR_EQUAL"
    };

    translateToModel(translated) {
        return this.mathOperators[translated];
    }
}

export default new QuestionFilterTranslator();