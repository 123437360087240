class CookieService {

    setCookie(name, value, days) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
    }

    getCookieValue(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }

    removeFromCookie(name, valueToRemove) {
        // Get the current value of the cookie
        const currentValue = this.getCookieValue(name);

        // If the cookie has a value, remove the specified value
        if (currentValue) {
            const updatedValue = currentValue
                .split(';')
                .filter(item => item.trim() !== valueToRemove.trim())
                .join(';');

            // Update the cookie with the updated value
            this.setCookie(name, updatedValue);
        }
    }

    eraseCookie(name) {
        document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }

    appendToCookieValue(name, additionalValue) {
        const currentValue = this.getCookieValue(name);
        const newValue = currentValue ? currentValue + ';' + additionalValue : additionalValue;
        this.eraseCookie(name)
        this.setCookie(name, newValue);
    }
}

export default new CookieService();
