class JobOfferSkillsTranslator {

    constructor() {
    }

    translateLevelFromEnumToIntegerLevel(skills) {
        skills.forEach(skill => {
            skill.level = this.mapToLevel(skill.level)
        });
        return skills
    }

    mapToLevel(value) {
        const levelMap = {
            "NICE_TO_HAVE": 1,
            "JUNIOR": 2,
            "REGULAR": 3,
            "SENIOR": 4,
            "ARCHITECT": 5
        };

        return levelMap[value] || 0; // Return 0 if the value is not found
    }

}


export default new JobOfferSkillsTranslator();
