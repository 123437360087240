<template>
  <q-dialog v-model="showDialog" persistent>
    <q-card class="full-width" style="border-radius: 9px">
      <q-card-section>
        <span
            class="q-ml-sm">Zostanie otwartych {{ checkedAppsCount }} okien do wysłania maila z poniższymi danymi</span>
      </q-card-section>
      <q-card-section class="q-pt-none">
        <div class="row q-gutter-md items-center">
          <div class="col">
            <q-select outlined v-model="name" :options="options" label="Szablon maila" />
          </div>
        </div>
      </q-card-section>
      <q-card-section>
        <q-input outlined v-model="subject" label="Temat maila"/>
      </q-card-section>

      <q-card-section class="row items-center">
        <my-text class="full-width full-height">
          <div class="full-width">
            <q-editor
                class="full-width full-height q-px-md"
                v-model="editor"
                min-height="200px"
                max-height="200px"
                toolbar-color="accent"
                :toolbar="[
            ['bold', 'unordered', 'undo', 'redo'],
          ]"
            />
          </div>
        </my-text>
      </q-card-section>

      <q-card-actions align="right">
        <q-btn flat label="Anuluj" color="primary" v-close-popup/>
        <q-btn flat label="Otwórz maila" color="secondary" v-close-popup @click="sendOpenEmit()"/>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup>
import {computed, defineEmits, defineProps, ref} from "vue";

const props = defineProps({
  checkedAppsCount: String,
  showDialog: Boolean
})

const showDialog = computed({
  get: () => props.showDialog,
  set: (val) => emit('update:showDialog', val),
});
const emit = defineEmits(['show:mails'])

const editor = ref('Udało Ci się dostać do kolejnego etapu rekrutacji. Proszę potwierdź chęć udziału w kolejnej rozmowie.')
const subject = ref('Wynik rekrutacji')
const greetings = ref('Cześć')
const name = ref('Imię')
const options = [
  'Imię', 'Imię i nazwisko'
]

function sendOpenEmit() {
  emit('show:mails', {
    subject: subject.value,
    greetings: greetings.value,
    name: name.value,
    editor: editor.value
  });
}
</script>
<style lang="scss" scoped>
</style>