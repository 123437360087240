<template>
  <q-card flat bordered style="width: 30%; border-radius: 9px;">
    <q-card-section>
      <div class="row">
        <div class="q-mb-md">
          <my-text style="font-size: 18px" class="text-bold text-grey-8">Basic</my-text>
        </div>
        <div>
          <q-chip outline icon-right="mdi-brightness-percent" size="sm" color="white"
                  class="bg-dark text-white q-ml-sm text-bold" style="border-radius: 5px">
            Promocja
          </q-chip>
        </div>
      </div>
      <div v-if="props.plan.promotionalPrice !== null">
        <my-text class="text-grey-5" style="font-size: 20px;">
          <span class="text-bold" style=" text-decoration: line-through; text-decoration-thickness: 1px">{{
              props.plan.originalPrice.amount
            }}</span>
          <span class="text-light-blue-7 text-bold">&nbsp;0 zł&nbsp;</span>
          <span style="font-size: 14px; font-weight: lighter" class="text-grey-7">netto/ogłoszenie</span>
        </my-text>
      </div>
      <div v-else>
        <my-text class="text-green-7" style="font-size: 20px;">
          <span class="text-bold text-light-blue-7">{{ props.plan.originalPrice.amount }}</span>
          <span class="text-light-blue-7 text-bold">&nbsp;zł&nbsp;</span>
          <span style="font-size: 14px; font-weight: lighter" class="text-grey-7">netto/ogłoszenie</span>
        </my-text>
      </div>
      <div class="q-my-lg">
        <q-btn size="12px" flat class="bg-gradient text-white" @click="emitNextStep()"
               style="border-radius: 8px;font-weight: bold; width:100%" no-caps>Wybierz plan
        </q-btn>
      </div>
      <div>
        <q-separator class="q-mx-xs q-my-md"></q-separator>
      </div>
      <div class="q-col-gutter-sm">
        <div class="row justify-between">
          <q-chip style="font-size: 13px" dense color="white" text-color="grey-9" icon="mdi-check">
            Okres publikacji &nbsp;<span class="text-bold">30</span>&nbsp;dni
          </q-chip>
          <q-btn size="sm" flat round color="black" icon="mdi-information-outline">
            <q-tooltip style="border-width: 2px" max-width="200px" anchor="top middle" self="bottom middle"
                       :offset="[10, 10]" class="bg-white text-grey-9">
              Oferta będzie widoczna przez 30 dni od daty publikacji.
            </q-tooltip>
          </q-btn>
        </div>
        <div class="row justify-between">
          <q-chip style="font-size: 13px" dense color="white" text-color="grey-9" icon="mdi-check">
            Liczba podbić &nbsp;<span class="text-bold">3</span>&nbsp;
          </q-chip>
          <q-btn size="sm" flat round color="black" icon="mdi-information-outline">
            <q-tooltip max-width="200px" anchor="top middle" self="bottom middle" :offset="[10, 10]"
                       class="bg-white text-grey-9">
              Liczba podbić pozwala umieścić ofertę na szczycie listy.
            </q-tooltip>
          </q-btn>
        </div>
        <div class="row justify-between">
          <q-chip style="font-size: 13px" dense color="white" text-color="grey-9" icon="mdi-check">
            Liczba lokalizacji &nbsp;<span class="text-bold text-grey-9">2</span>&nbsp;
          </q-chip>
          <q-btn size="sm" flat round color="black" icon="mdi-information-outline">
            <q-tooltip max-width="200px" anchor="top middle" self="bottom middle" :offset="[10, 10]"
                       class="bg-white text-grey-9">
              Liczba lokalizacji pozwala dodać jedną ofertę dla kilku miast.
            </q-tooltip>
          </q-btn>
        </div>
        <div class="row justify-between">
          <q-chip style="font-size: 13px" dense color="white" class="text-bold" text-color="primary" icon="mdi-check">
            Portal rekrutacyjny
          </q-chip>
          <q-btn size="sm" flat round color="black" icon="mdi-information-outline">
            <q-tooltip max-width="200px" anchor="top middle" self="bottom middle" :offset="[10, 10]"
                       class="bg-white text-grey-9">
              Portal rekrutacyjny przyspiesza rekrutacje pracowników - wspiera szybką analizę i ocenę kandydatów.
            </q-tooltip>
          </q-btn>
        </div>
        <div class="row justify-between">
          <q-chip style="font-size: 13px" dense color="white" text-color="grey-9" icon="mdi-check">
            Monitoring ogłoszeń
          </q-chip>
          <q-btn size="sm" flat round color="black" icon="mdi-information-outline">
            <q-tooltip max-width="200px" anchor="top middle" self="bottom middle" :offset="[10, 10]"
                       class="bg-white text-grey-9">
              Monitoring ogłoszeń pozwala określić trendy związane z ofertami oraz aplikacjami. Pozwala to na lepszą
              kontrolę ogłoszeń względem rynku.
            </q-tooltip>
          </q-btn>
        </div>
        <div class="row justify-between">
          <q-chip style="font-size: 13px" dense color="white" text-color="grey-9" icon="mdi-check">
            Wsparcie - &nbsp;<span class="text-bold">podstawowe</span>
          </q-chip>
          <q-btn size="sm" flat round color="grey-9" icon="mdi-information-outline">
            <q-tooltip max-width="200px" anchor="top middle" self="bottom middle" :offset="[10, 10]"
                       class="bg-white text-grey-9">
              Podstawowe wsparcie umożliwia rozwiązywanie zgłoszonych problemów, jednak pierwszeństwo będą miały oferty
              Premium i Stadard.
            </q-tooltip>
          </q-btn>
        </div>
        <div class="row justify-between">
          <q-chip style="font-size: 13px" dense color="white" text-color="grey-9" icon="mdi-check">
            Branding -&nbsp;<span class="text-bold">logo</span>&nbsp;
          </q-chip>
          <q-btn size="sm" flat round color="grey-9" icon="mdi-information-outline">
            <q-tooltip max-width="200px" anchor="top middle" self="bottom middle" :offset="[10, 10]"
                       class="bg-white text-grey-9">
              Branding - indywidualna kolorystyka ogłoszenia, pozwala dodać identyfikacje graficzną firmy.
            </q-tooltip>
          </q-btn>
        </div>
        <div class="row justify-between">
          <q-chip style="font-size: 13px" dense color="white" text-color="grey-6" icon="mdi-close">
            Reklama w social mediach
          </q-chip>
          <q-btn size="sm" flat round color="grey-5" icon="mdi-information-outline">
            <q-tooltip max-width="200px" anchor="top middle" self="bottom middle" :offset="[10, 10]"
                       class="bg-white text-grey-9">
              Reklama w social mediach pozwala umieścić ogłoszenie oferty w serwisach społecznościowych takich jak
              Instagram i Facebook.
            </q-tooltip>
          </q-btn>
        </div>
        <div class="row justify-between">
          <q-chip style="font-size: 13px" dense color="white" text-color="grey-6" icon="mdi-close">
            Wyróżnienie oferty
          </q-chip>
          <q-btn size="sm" flat round color="grey-5" icon="mdi-information-outline">
            <q-tooltip max-width="200px" anchor="top middle" self="bottom middle" :offset="[10, 10]"
                       class="bg-white text-grey-9">
              Dodatkowy znacznik przy ofercie, który wyróżnia ją spośród ogłoszeń Basic i Standard.
            </q-tooltip>
          </q-btn>
        </div>
      </div>
    </q-card-section>
  </q-card>
</template>
<script setup>
import {computed, defineEmits, defineProps} from "vue";

const props = defineProps({
  plan: Object,
  planForOffer: String
});

const emit = defineEmits(['step:next'])

const planForOffer = computed({
  get: () => props.plan,
  set: (val) => emit('update:planForOffer', val),
});

function emitNextStep() {
  planForOffer.value = props.plan
  emit('step:next')
}
</script>
<style lang="scss" scoped>
.bg-gradient {
  background: linear-gradient(20deg, $primary, $light-blue-11); /* Use your primary and accent colors here */
  color: white; /* Text color */
}
</style>