§ <template>
  <div class="q-mt-md q-mb-md">
    <q-card class="full-width column" bordered style="border-radius: 9px; border-color: #c9d7ec">
      <q-card-section>
        <my-text class="q-mx-sm q-pb-md" style="font-size: 12px"><span
            class="text-weight-bold text-primary">Twoje CV</span>
          - możesz dodać maksymalnie 3 pliki PDF. Podczas aplikowania na ofertę wybierz które CV przesłać pracodawcy.
        </my-text>
      </q-card-section>
      <div class="q-mx-lg q-mb-sm">
        <q-separator></q-separator>
      </div>
      <div class="row col q-mx-lg q-my-md justify-between">

        <applicant-cv-card v-if="firstResume"
                           v-model="firstResume"
                           @resumes:update="initResumes()"
                           :applicant-id="props.applicantId"
                           :resume-index="0"
                           style="max-width: 31%">
        </applicant-cv-card>


        <applicant-cv-card v-if="secondResume"
                           v-model="secondResume"
                           @resumes:update="initResumes()"
                           :applicant-id="props.applicantId"
                           style="max-width: 31%">
        </applicant-cv-card>


        <applicant-cv-card v-if="thirdResume"
                           v-model="thirdResume"
                           @resumes:update="initResumes()"
                           :applicant-id="props.applicantId"
                           :resume-index="2"
                           style="max-width: 31%">
        </applicant-cv-card>
      </div>
    </q-card>
  </div>
</template>

<script setup>
import {defineProps, onBeforeMount, reactive, ref} from "vue";

import ApplicantCvCard from "@/components/applicant/profile/ApplicantCvCard";

const props = defineProps({
  applicantId: {
    type: String,
    required: true
  }
});

const resumes = ref([])

onBeforeMount(() => {
  initResumes()
})


const firstResume = reactive({
  id: null,
  fileName: null,
  file: [],
  defaultResume: false
})
const secondResume = reactive({
  id: null,
  fileName: null,
  file: [],
  defaultResume: false
})
const thirdResume = reactive({
  id: null,
  fileName: null,
  file: [],
  defaultResume: false
})

function getResumes() {
  try {
    return fetch(process.env.VUE_APP_BACKEND_URL + "/applicants/" + props.applicantId + "/resumes", {
      method: 'GET',
      credentials: "include",
    })
        .then(response => {
          if (!response.ok) {
            throw new Error('Server response was not ok');
          }
          return response
        })
  } catch (error) {
    console.error(error);
    throw error
  }
}

function initResumes() {
  getResumes().then(response => {
    response.json().then(body => {
      console.info(body)
      resumes.value = body
      for (const resume of resumes.value) {
        resume.uploadedAt = new Date(resume.uploadedAt);
      }
      resumes.value.sort((a, b) => a.uploadedAt - b.uploadedAt);
      initializeResume(firstResume, 0)
      initializeResume(secondResume, 1)
      initializeResume(thirdResume, 2)
    })
  })
}

function initializeResume(resumeObj, index) {
  if (resumes.value.length > index) {
    resumeObj.id = resumes.value[index]?.id
    resumeObj.fileName = resumes.value[index]?.fileName
    resumeObj.file = resumes.value[index]?.file
    resumeObj.defaultResume = resumes.value[index]?.defaultResume
  }else {
    resumeObj.id = null
    resumeObj.fileName = null
    resumeObj.file = []
    resumeObj.defaultResume = false
  }
}
</script>

<style scoped>
.custom-full-height {
  height: 100%;
  box-sizing: border-box;
}
</style>