import {reactive} from 'vue'

export const currentUser = reactive({
    id: null,
    email: null,
    emailVerified: false,
    createdAt: null,
    firstName: null,
    lastName: null,
    phoneNumber: null,
    type: null,
    active: false,
    preferredContactTime: null,
    organizationId: null,
    favoriteOffersIds: [],
    isAuthenticated() {
        return this.id !== null;
    },
    isApplicant() {
        return this.type === 'APPLICANT'
    },
    resetCurrentUser() {
        currentUser.id = null
        currentUser.email = null
        currentUser.emailVerified = null
        currentUser.createdAt = null
        currentUser.firstName = null
        currentUser.lastName = null
        currentUser.phoneNumber = null
        currentUser.type = null
        currentUser.active = false
        currentUser.preferredContactTime = null
        currentUser.organizationId = null
        currentUser.favoriteOffersIds = []
    }
});