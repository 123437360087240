<template>
  <div class="full-width">
    <q-card flat class="q-mx-sm q-mt-sm q-mb-lg full-width" style="border-radius: 9px;background-color: rgba(229,237,246,0.82);">
      <q-card-section>
        <my-text class="text-white">
          <span class="text-weight-bold text-primary" style="font-size: 15px">Umowy</span> <span class="text-grey-8">
        pozwalają zdefiniować
        maksymalnie trzy opcje. Każda z nich wymaga podania typu umowy. Podanie stawki jest opcjonalne.
      </span>
        </my-text>
      </q-card-section>
    </q-card>
    <q-form greedy v-if="contracts.length > 0" @submit="validateChildComponents()"
    >
      <contract-info-additional
          ref="childComponentRef"
          :form-template-data="formTemplateData"
          v-for="contract in contracts"
          :key="contract.id"
          :v-for-key="contract.id"
          v-model:salary="contract.salary"
          v-model:type="contract.type"
          @addContract="addContract"
          @removeContractInfo="removeContractById">
      </contract-info-additional>
    </q-form>
    <q-card flat class="q-mx-none q-mt-lg q-mb-lg" style="border-radius: 9px;background-color: rgba(229,237,246,0.82);">
      <q-card-section class="row justify-between">
        <my-text class="text-white q-mt-sm">
          <span class="text-grey-8">
            <span v-if="contracts.length <= 0">Brak zdefiniowanych umów</span>
            <span v-else>Liczba zdefiniowanych umów: {{contracts.length}}</span>
            - maksymalna liczba 3.
        </span>
        </my-text>
        <q-btn class="q-px-md" label="Dodaj umowę" color="primary" dense size="5px" style="font-size: 10px" @click="addContract()">
          <q-icon
              class="q-ml-md"
              name="sym_o_add"
              size="1.5rem"
          />
        </q-btn>
      </q-card-section>
    </q-card>
    <div class="q-my-md">
      <q-separator></q-separator>
    </div>
  </div>
</template>

<script setup>

import ContractInfoAdditional from "@/components/common/add-offer/job-offer/contracts/ContractInfoAdditional";

import {computed, defineEmits, defineExpose, defineProps, ref} from 'vue'

const props = defineProps({
  formTemplateData: Object,
  modelValue: Object
})


const contracts = computed({
  get: () => props.modelValue,
  set: (val) => emit('update:modelValue', val),
});

const contractId = ref(1);

import {useQuasar} from 'quasar'
import NotificationService from "@/services/notification/NotificationService";

const $q = useQuasar();

function validateContractsNumber() {
  if (contracts.value.length > 2) {
    NotificationService.showNotification($q, 'negative', 'Możesz dodać maksymalnie 3 umowy.');
    return false;
  }
  return true;
}

function addContract() {
  if (validateContractsNumber()) {
    contracts.value.push({id: contractId.value, type: null, salary: {from: null, to: null, currency: null}})
    contractId.value = contractId.value + 1
  }
}

function removeContractById(id) {
  for (let i = 0; i < contracts.value.length; i++) {
    if (contracts.value[i].id === id) {
      contracts.value.splice(i, 1);
      return true; // return true to indicate successful removal
    }
  }
  return false; // return false to indicate that contract with given id was not found
}

const emit = defineEmits(['update:modelValue'])
const childComponentRef = ref(null);

async function validateChildComponents() {
  let pass = true;
  const validationPromises = await childComponentRef.value.map(child => {
    let validate = child.validate();
    return validate;
  });

  await Promise.all(validationPromises).then(results => {
    results.forEach(result => {
      if (!result) {
        pass = false;
      }
    });
  }).catch(error => {
    console.error('Error during validation:', error);
    pass = false;
  });
  console.info("kontrakty " + pass)
  return pass;
}


defineExpose({
  validateChildComponents
})
</script>