class StringUtils {
    isStringEmpty(str) {
        if (str === undefined || str === null) {
            return true;
        }
        const regex = /^\s*$/; // Regular expression for empty or whitespace-only string
        return regex.test(str);
    }
}

export default new StringUtils();