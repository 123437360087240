import script from "./OrganizationPlannedJobOffersBoard.vue?vue&type=script&setup=true&lang=js"
export * from "./OrganizationPlannedJobOffersBoard.vue?vue&type=script&setup=true&lang=js"

import "./OrganizationPlannedJobOffersBoard.vue?vue&type=style&index=0&id=78450aa8&lang=scss"

const __exports__ = script;

export default __exports__
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QHeader from 'quasar/src/components/header/QHeader.js';
import QDrawer from 'quasar/src/components/drawer/QDrawer.js';
import QList from 'quasar/src/components/item/QList.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import QPage from 'quasar/src/components/page/QPage.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QLayout,QHeader,QDrawer,QList,QBtn,QPageContainer,QPage});
