class OrganizationDataService {
    constructor(url) {
        this.url = url;
    }

    async getOrganizationInfo() {
        const url = this.url + '/organizations/' + localStorage.getItem("organizationId");
        try {
            const requestOptions = {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json', // Add any other headers if required
                },
            };

            return fetch(url, requestOptions).then(response =>{
                return response
            } );

        } catch (error) {
            console.error('Error during organization user GET method:', error);
            throw error;
        }
    }

    async updateOrganizationInfo(organizationData) {
        const url = this.url + '/organizations/' + localStorage.getItem("organizationId");
        try {
            const requestOptions = {
                method: 'PUT',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(organizationData)
            };

            return await fetch(url, requestOptions);
        } catch (error) {
            console.error('Error during organization update PUT method:', error);
            throw error;
        }
    }

    areMandatoryFieldsMissing(organizationData) {
        const isTypeMissing = !organizationData.type;
        console.info("DATA" + organizationData.toString())
        const isIndustryMissing = organizationData.industries.length <= 0;
        const isSizeMissing = !organizationData.size;
        let isLogoUrlEmpty = this.isStringEmpty(organizationData.logoUrl);

        console.log("Is type missing:", isTypeMissing);
        console.log("Is industry missing:", isIndustryMissing);
        console.log("Is size missing:", isSizeMissing);
        console.log("Is logo URL empty:", isLogoUrlEmpty);

        if (isTypeMissing || isIndustryMissing || isSizeMissing || isLogoUrlEmpty) {
            return true;
        }
    }


    isStringEmpty(str) {
        const regex = /^\s*$/; // Regular expression for empty or whitespace-only string
        return regex.test(str);
    }
}


export default new OrganizationDataService(process.env.VUE_APP_BACKEND_URL);
