<template>
  <q-dialog v-model="showDialog">
    <q-card style="border-radius: 9px">
      <q-card-section class="row items-center">
        <q-icon class="q-mr-sm" name="mdi-clock-check-outline" color="positive" size="lg" text-color="white"/>
        <span
            class="q-ml-sm text-grey-8">Publikacja została przedłużona o kolejny miesiąc</span>
      </q-card-section>
      <div class="q-mx-md">
        <q-separator></q-separator>
      </div>
      <q-card-actions align="right" class="q-mr-xs">
        <q-btn flat label="Ok" color="grey-8" v-close-popup/>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup>
import {computed, defineEmits, defineProps} from "vue";

const props = defineProps({
  showDialog: Boolean
})
const emit = defineEmits(['show:dialog'])

const showDialog = computed({
  get: () => props.showDialog,
  set: (val) => emit('show:dialog', val),
});

</script>