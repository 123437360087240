class ApplicationsService {
    constructor(url) {
        this.url = url;
    }

//TODO merge this method with OrganizationApplicationService
    async getApplications(params) {
        const requestOptions = {
            method: "POST",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(params) // Add the params to the body
        };

        const url = `${process.env.VUE_APP_BACKEND_URL}/organizations/${localStorage.organizationId}/applications`;

        try {
            const response = await fetch(url, requestOptions);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error:', error);
            throw error;
        }
    }

}

export default new ApplicationsService(process.env.VUE_APP_BACKEND_URL);
