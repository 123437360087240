<template>
  <div class="row justify-start q-pr-sm q-pt-none">
    <div class="row">
      <q-card bordered flat class="q-ma-none text-grey-9 q-mt-xs q-ml-none column"
              style="font-size: 13px; border-radius: 7px;">
        <!--        <q-icon class="q-pr-sm" color="white" size="15px" name="fa-solid fa-user-gear"></q-icon>-->
        <q-chip label="stanowisko"
                class="bg-transparent q-pl-xs q-pt-none q-mb-none q-pb-none q-mt-none text-accent"
                style="border-radius:5px;font-size: 10px"
                size="sm"></q-chip>

        <p style="margin-bottom: 1px; margin-left: 5px;margin-right: 5px"
           class="text-bold q-px-xs q-mb-none q-mt-none col">
          {{ JobPositionTranslator.translateConstant(props.jobPosition) }}</p>
      </q-card>
    </div>
    <div class="row q-mr-sm">
      <q-card bordered flat class="q-ma-none  text-grey-9 q-mt-xs q-ml-sm column"
              style="font-size: 13px; border-radius: 7px;">
<!--        background: rgba(132,189,192,0.29) !important;-->
        <!--        <q-icon class="q-pr-sm" color="white" size="15px" name="fa-solid fa-user-gear"></q-icon>-->
        <q-chip label="doświadczenie"
                class=" bg-transparent q-pl-xs q-pt-none q-mb-none q-pb-none q-mt-none text-accent"
                style="border-radius:5px;font-size: 10px"
                size="sm"></q-chip>

        <p style="margin-bottom: 1px; margin-left: 5px;margin-right: 5px"
           class="text-bold q-px-xs q-mb-none q-mt-none col">{{ translateExp() }}</p>
      </q-card>
    </div>
    <div class="row">
      <q-card bordered flat class="q-ma-none text-grey-9 q-mt-xs q-ml-none column "
              style="font-size: 13px; border-radius: 7px; ">
        <!--        <q-icon class="q-pr-sm" color="white" size="15px" name="fa-solid fa-user-gear"></q-icon>-->
        <q-chip label="tryb pracy"
                class="bg-transparent q-pl-xs q-pt-none q-mb-none q-pb-none q-mt-none text-accent"
                style="border-radius:5px;font-size: 10px"
                size="sm"></q-chip>

        <p style="margin-bottom: 1px; margin-left: 5px;margin-right: 5px"
           class="text-bold q-px-xs q-mb-none q-mt-none col">{{ translateOperatingModes() }}</p>
      </q-card>
    </div>
    <div class="row">
      <q-card bordered flat class="q-ma-none text-grey-9 q-mt-xs q-ml-sm column "
              style="font-size: 13px; border-radius: 7px;">
        <!--        <q-icon class="q-pr-sm" color="white" size="15px" name="fa-solid fa-user-gear"></q-icon>-->
        <q-chip label="wymiar pracy"
                class=" bg-transparent q-pl-xs q-pt-none q-mb-none q-pb-none q-mt-none text-accent"
                style="border-radius:5px;font-size: 10px"
                size="sm"></q-chip>

        <p style="margin-bottom: 1px; margin-left: 5px;margin-right: 5px"
           class="text-bold q-px-xs q-mb-none q-mt-none col">{{ translateWorkTypes() }}</p>
      </q-card>
    </div>
  </div>
</template>

<script setup>
import {defineProps} from 'vue';

const props = defineProps({
  jobPosition: {
    type: String,
    required: true,
    default: ''
  },
  modes: {
    type: Array,
    required: true,
  },
  workTypes: {
    type: Array,
    required: true,
  },
  seniorityLevelName: {
    type: String,
    required: true,
    default: ''
  },
  branding: {
    type: Object,
    required: true
  },
});

function translateOperatingModes() {
  const translations = {
    REMOTE: "zdalna",
    HYBRID: "hybrydowa",
    IN_OFFICE: "stacjonarna",
  };

  const translatedArray = props.modes.map((value) => {
    // Use the translation or the original value if not found
    return translations[value] || value;
  });

  return translatedArray.join(" | ");
}

function translateWorkTypes() {
  const translations = {
    FULL_TIME: "full-time",
    PART_TIME: "part-time",
  };

  const translatedArray = props.workTypes.map((value) => {
    // Use the translation or the original value if not found
    return translations[value] || value;
  });

  return translatedArray.join(" | ");
}

import OfferDataTranslator from "@/services/organization/offer/OfferDataTranslator";

function translateExp() {
  return OfferDataTranslator.translatePolishExperienceLevel(props.seniorityLevelName)
}

import JobPositionTranslator from "@/services/offer/JobPositionTranslator";

</script>

<style scoped lang="scss">
.my-card {
  width: 700px;
  margin: 5px auto;
}

my-text {
  color: $primary;
}
</style>