<template>
  <div style="height: 96vh">
    <q-layout view="hHh Lpr lff" container style="height: 100vh">
      <q-header>
        <div class="bg-white">
          <menu-bar class="q-pl-sm q-pr-sm"></menu-bar>
        </div>
      </q-header>
      <q-drawer
          v-model="drawerLeft"
          show-if-above
          @mouseover="miniStateLeft = false"
          @mouseout="miniStateLeft = true"
          :width="140"
          :breakpoint="500"
          bordered
          :class="$q.dark.isActive ? 'bg-white' : 'bg-white'"
      >
        <q-list bordered style="max-width: 140px">
          <p class="text-center text-grey-9 q-pb-xs q-mb-none">OFERTY</p>
          <q-btn @click="router.push({ name: 'OrganizationJobOffersBoard' });" square dense flat size="12px"
                 class="full-width text-grey-8" color="primary" label="Aktywne" no-caps/>
          <q-btn square dense flat size="12px" class="full-width" color="primary text-grey-8" label="Archiwalne" no-caps
                 @click="router.push({ name: 'OrganizationJobOffersBoard' });"/>
          <q-btn @click="router.push({ name: 'OrganizationPlannedJobOffersBoard' });" square dense flat size="12px"
                 class="full-width" color="primary text-grey-8" label="Zaplanowane" no-caps/>
        </q-list>
        <q-list bordered>
          <p class="text-center text-grey-9 q-pb-xs q-mb-none">KONTA</p>
          <q-btn square dense flat size="12px" class="full-width text-grey-8" color="primary" label="Aktywne" no-caps
                 @click="router.push({ name: 'OrganizationAccountManagement' });"/>
          <q-btn square dense flat size="12px" class="full-width" color="primary text-grey-8" label="Nieaktywne"
                 no-caps/>
        </q-list>
      </q-drawer>
      <q-page-container style="background-color: rgb(217,225,238);" class="q-pa-sm">
        <q-page
            style="background-color: rgb(217,225,238); position:fixed; flex:none; overflow-y:hidden; display: flex; flex-direction: column"
            class="parent q-pb-lg">
          <div v-if="isLoading">Loading...</div>
          <div v-else class="row justify-center q-mb-lg" style="flex: 1">
            <div class="q-pl-md q-pr-sm q-mt-lg q-pb-none q-mb-lg col-9 scrollableDiv q-mr-sm"
                 style="max-height: 90vh; display: flex; flex-direction: column;">
              <q-card flat class="q-mx-none q-mb-sm bg-indigo-3" style="border-radius: 9px">
                <q-card-section>
                  <my-text style="font-size: 13px" class=" text-white q-mb-none">
                    <span class="text-primary text-bold">Edycja zaplanowanej publikacj.</span>
                    Edycja publikacji pozwala zmienić dane oferty (przewiń formularz do dołu, żeby zobaczyć wszystkie
                    dane). Mamy mozliwość wybrania daty publikacji oraz sposobu składania CV.
                  </my-text>
                </q-card-section>
              </q-card>
              <q-card flat style="border-radius: 9px;">
                <q-card-section>
                  <job-offer-form :model-value="jobOffer" :title="jobOffer.title" :show-buttons="false"
                                  :form-template-data="formTemplateData"></job-offer-form>
                </q-card-section>
              </q-card>
            </div>
            <div class="q-pt-lg q-pb-lg q-pl-none q-pr-lg col-3 q-mr-md column"
                 style="display: flex; flex-direction: column; justify-content: space-between;">
              <div class="q-mb-none col-grow">
                <q-card flat class="q-mb-sm bg-indigo-3" style="border-radius: 9px">
                  <q-card-section>
                    <my-text style="font-size: 13px" class=" text-white q-mb-none">
                      <span class="text-primary text-bold">Wybierz datę publikacji.</span>
                      <br>
                      Wybierz datę publikacji
                      ogłoszenia.
                      Oferta pojawi się o godzinie 00:00 wybranego dnia.
                    </my-text>
                  </q-card-section>
                </q-card>
                <q-date class="full-width full-height col-grow" flat color="white" text-color="accent"
                        style="border-radius: 9px"
                        v-model="publicationDate"/>
              </div>
              <div>
                <q-card flat class="q-mt-lg bg-indigo-3" style="border-radius: 9px;">
                  <q-card-section>
                    <my-text style="font-size: 13px" class="text-white q-mb-none"><span
                        class="text-primary text-bold">Wybierz sposób aplikowania</span>
                      <br>
                      Aplikowanie z ByteJob pozwala sprawnie zarządzać procesem rekrutacji bez integracji z
                      zewnętrznymi
                      systemami.
                    </my-text>
                  </q-card-section>
                </q-card>
                <q-card style="border-radius: 9px" flat class="q-mt-sm">
                  <div class="q-pa-md q-pb-lg">
                    <q-btn-toggle
                        v-model="jobOffer.jobApplicationInfo.applicationType"
                        spread
                        no-caps
                        size="md"
                        outline
                        class="bg-white q-pt-none"
                        toggle-color="primary"
                        color="white"
                        text-color="grey-6"
                        :options="[
          {label: 'Aplikuj z ByteJob', value: 'INTERNAL'},
          {label: 'Aplikuj za pomocą zewnętrznego formularza', value: 'EXTERNAL'}
        ]"
                    />
                    <q-input class="q-mt-md q-pb-sm text-green" color="primary" stack-label outlined
                             style="border-radius: 9px;"
                             v-if="jobOffer.jobApplicationInfo.applicationType==='EXTERNAL'"
                             v-model="jobOffer.jobApplicationInfo.externalApplicationLink"
                             :rules="[val => val !== null|| 'Podaj adres www zewnętrznego formularza']"
                             label="URL do zewnętrznego formularza"
                             dense
                             bg-color="white"
                    >
                    </q-input>
                  </div>
                  <div class="row justify-between q-px-md">
                    <q-btn style="border-radius: 6px" type="submit" color="primary" no-caps dense
                           class="q-px-md q-mb-md"
                           size="12px" label="Zapisz zmiany"
                           @click="updateJobOffer()"/>
                    <q-btn style="border-radius: 6px" type="submit" color="grey-8" no-caps dense
                           class="q-px-md q-mb-md bg-white"
                           size="12px" outline
                           @click="fetchData()"
                           label="Cofnij niezapisane zmiany"/>
                  </div>
                </q-card>
              </div>
            </div>
          </div>


          <!--          <div v-else class="row justify-center q-mb-lg" style="max-height:94%; width: 92%; position:fixed; flex:none; overflow-y:hidden; display: flex; flex-direction: column">-->
          <!--            <div style="flex: 1; overflow: auto;" class="q-px-lg q-mt-lg q-mb-lg col-8">-->
          <!--                <q-card flat style="border-radius: 9px">-->
          <!--                  <q-card-section style="flex: 1; overflow: auto">-->
          <!--                    <job-offer-form :model-value="jobOffer" :title="jobOffer.title"-->
          <!--                                    :form-template-data="formTemplateData"></job-offer-form>-->
          <!--                  </q-card-section>-->
          <!--                </q-card>-->
          <!--            </div>-->
          <!--            <div class="q-pt-lg q-pb-lg q-pl-none q-pr-lg col-4">-->
          <!--              <q-date class="full-width" flat color="accent" style="border-radius: 9px" v-model="publicationDate"/>-->
          <!--            </div>-->
          <!--          </div>-->

        </q-page>
      </q-page-container>
    </q-layout>
  </div>
</template>
<script setup>
import {onMounted, ref} from 'vue'

const router = useRouter()

const drawerLeft = ref(false)
const miniStateLeft = ref(true)

import {useRouter} from 'vue-router';
import MenuBar from "@/components/common/MenuBar";
import jobOfferService from "@/services/JobOfferService";
import JobOfferForm from "@/components/common/add-offer/job-offer/JobOfferForm";
import FormTemplateDataService from "@/services/organization/offer/FormTemplateDataService";

const jobOfferId = router.currentRoute.value.params.id

const isLoading = ref(true);
const jobOffer = ref(null);
const publicationDate = ref(['2023/11/01'])
const formTemplateData = ref(null)
onMounted(() => {
  FormTemplateDataService.getFormTemplateData().then(data => {
    console.info(data)
    formTemplateData.value = data;
    isLoading.value = false;
  });
  fetchData();
});

import OfferDataTranslator from "@/services/organization/offer/OfferDataTranslator";
import NotificationService from "@/services/notification/NotificationService";
import OrganizatioOfferManagementService from "@/services/organization/offer/OrganizatioOfferManagementService";
import {useQuasar} from "quasar";

const fetchData = async () => {
  try {
    const data = await jobOfferService.getJobOffersForOrganization();
    const selectedOffer = data.find(offer => offer.id === jobOfferId);

    if (selectedOffer) {
      console.info("test", selectedOffer);
      jobOffer.value = selectedOffer;
      publicationDate.value = publishedAtToCalendarValue(selectedOffer.publication.publishedAt);
      jobOffer.value.seniority = OfferDataTranslator.translateFromSeniorityToPolish(jobOffer.value.seniority)
      jobOffer.value.languages = OfferDataTranslator.translateArrayLanguagesToPolish(jobOffer.value.languages)
      jobOffer.value.locations = OfferDataTranslator.translateArrayLocationsToPolish(jobOffer.value.locations)
      jobOffer.value.contracts = OfferDataTranslator.translateContractsToPolish(jobOffer.value.publication.contracts)
      jobOffer.value.questions.forEach(question => {
        question.answerType = OfferDataTranslator.translateAnswerTypeToPolish(question.answerType)
      })
      mapToFEContracts();

      OfferDataTranslator.mapModelToSkillLevels(jobOffer.value);
      console.info(jobOffer.value.publication)
    } else {
      console.warn("Selected job offer not found.");
    }
  } catch (error) {
    console.error(error);
  }
};

function publishedAtToCalendarValue(dateString) {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}/${month}/${day}`;
}

function mapToFEContracts() {
  jobOffer.value.contracts = jobOffer.value.publication.contracts
  jobOffer.value.contracts.forEach((contract, index) => {
    contract.id = index;
  });
  jobOffer.value.publication = null
}

function updateJobOffer() {
  sendPostWithJobOffer()
}

const $q = useQuasar()

function sendPostWithJobOffer() {
  OfferDataTranslator.mapSkillLevelsToModel(jobOffer.value)
  jobOffer.value.seniority = OfferDataTranslator.translateFromPolishToExperienceLevel(jobOffer.value.seniority)
  mapContractsTypeToModel()
  mapLanguagesToModel()
  mapLocationsToModel()
  mapQuestionsToModel()
  if (jobOffer.value.contactEmails.length === 0) {
//TODO add contact emails - kogo tutaj dodamy jak zalogowany uzytkownik doda oferte
  }

  OrganizatioOfferManagementService.updateJobOffer(jobOffer.value).then(async (response) => {
        console.log("status odp:" + response.status);
        if (response.status === 200) {
          NotificationService.showNotification($q, 'positive', 'Zaktualizowano ofertę')
          setTimeout(router.push({name: 'OrganizationPlannedJobOffersBoard'}), 1000)
        }
      }
  )
}

function mapContractsTypeToModel() {
  jobOffer.value.contracts.forEach(contract => {
    contract.type = OfferDataTranslator.translateFromPolishToContractType(contract.type)
  })
}

function mapLanguagesToModel() {
  jobOffer.value.languages.forEach(language => {
    console.info(language)
    language.language = OfferDataTranslator.translateFromPolishToLanguage(language.language)
    language.proficiency = OfferDataTranslator.mapFromLanguageRateToProficiency(language.proficiency)
  })
}

function mapLocationsToModel() {
  jobOffer.value.locations = jobOffer.value.locations.map(location => {
    return OfferDataTranslator.translateFromPolishToCity(location);
  });
}

function mapQuestionsToModel() {
  jobOffer.value.questions.forEach(question => {
    question.answerType = OfferDataTranslator.translateFromPolishToQuestionAnswerType(question.answerType);
  })
}
</script>

<style lang="scss">
.bg-my {
  background: linear-gradient(45deg, $primary, $secondary)
}

.bg-my-right {
  background: linear-gradient(180deg, $primary, $secondary)
}

.bg-my-bottom {
  background: linear-gradient(45deg, $secondary, $primary)
}

.scrollableDiv {
  overflow-y: auto;
  width: 65%;
  flex: 1;
}

.parent {
  display: flex;
  height: 100%; /* Ensures full height of the parent container */
}

.scrollable {
  flex: 1; /* Takes available space in the parent */
  overflow-y: auto; /* Enables vertical scrolling if content exceeds height */
}

</style>