import script from "./PlannedJobOfferEdit.vue?vue&type=script&setup=true&lang=js"
export * from "./PlannedJobOfferEdit.vue?vue&type=script&setup=true&lang=js"

import "./PlannedJobOfferEdit.vue?vue&type=style&index=0&id=4324ebe0&lang=scss"

const __exports__ = script;

export default __exports__
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QHeader from 'quasar/src/components/header/QHeader.js';
import QDrawer from 'quasar/src/components/drawer/QDrawer.js';
import QList from 'quasar/src/components/item/QList.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import QPage from 'quasar/src/components/page/QPage.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QDate from 'quasar/src/components/date/QDate.js';
import QBtnToggle from 'quasar/src/components/btn-toggle/QBtnToggle.js';
import QInput from 'quasar/src/components/input/QInput.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QLayout,QHeader,QDrawer,QList,QBtn,QPageContainer,QPage,QCard,QCardSection,QDate,QBtnToggle,QInput});
