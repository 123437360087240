<template>
  <q-dialog v-model="showDialog" class="row custom-dialog-wrapper">
    <div v-if="application.answers.length !== 0">
      <q-card style="border-radius: 9px" class="q-pt-md">
        <my-text style="font-size: 17px" class="q-mx-md text-weight-medium text-grey-9"><span class="text-primary text-weight-bold">Odpowiedzi</span> - {{application.applicantName}}</my-text>
        <q-card-section v-for="answer in application.answers" :key="answer.id">
          <my-text class="text-grey-9 q-px-sm" style="font-size: 13px">{{ answer.question }}</my-text>
          <q-card flat class="q-mt-md bg-primary"  style="border-radius: 9px">
            <q-card-section>
              <my-text class="text-white">{{ answer.answer }}</my-text>
            </q-card-section>
          </q-card>
        </q-card-section>
      </q-card>
    </div>
  </q-dialog>
</template>
<script setup>
import {computed, defineProps, defineEmits, onMounted} from 'vue'


const props = defineProps({
  modelValue: Object,
  id: String,
  showDialog: Boolean
})

const showDialog = computed({
  get: () => props.showDialog,
  set: (val) => emit('update:showDialog', val),
});

const emit = defineEmits(['modelValue', 'accepted'])

const application = computed({
  get: () => props.modelValue,
  set: (val) => emit('update:modelValue', val),
});

onMounted(() => {

})

// async function closeAnswers() {
//   showDialog.value = false
// }
</script>

<style lang="scss" scoped>

</style>