<template>
  <div class="column col">
    <q-card flat bordered v-ripple class="cursor-pointer q-hoverable col column flex-center"

            :class="evaluateCardBackgroundColor()"
            style="border-radius: 9px; border-color:#d5d7ea;"
            @mouseover="handleMouseOver"
            @mouseout="handleMouseOut"
            @click="handleClickOnCard()">
      <div v-if="!isResumeUploaded() && isMouseOnCard" class="column flex-center q-pt-md">
        <q-icon name="mdi-file-document-plus-outline" size="sm" class="text-primary q-ma-lg"></q-icon>
      </div>
      <div v-if="!isResumeUploaded() && !isMouseOnCard" class="column flex-center q-pt-md">
        <q-icon name="mdi-file-document-plus-outline" size="sm" class="text-grey-5 q-ma-lg"></q-icon>
      </div>

      <div v-if="isResumeUploaded() && !isMouseOnCard" class="column flex-center">
        <p class="q-mb-none text-weight-bold text-grey-5 ellipsis" style="width: 80px">
          {{ resume.fileName }}
        </p>
        <q-icon name="mdi-file-document-remove-outline" size="sm" class="text-grey-5 q-ma-md"></q-icon>
      </div>

      <div v-if="isResumeUploaded() && isMouseOnCard" class="column flex-center">
        <p class="q-mb-none text-weight-bold text-grey-5 ellipsis" style="width: 80px">
          {{ resume.fileName }}
        </p>
        <q-icon name="mdi-file-document-remove-outline" size="sm" class="text-dark q-ma-md"></q-icon>
      </div>
    </q-card>
    <div class="q-mt-sm">
      <q-checkbox v-model="resume.defaultResume" label="Domyślne Cv" class="text-grey-8 q-pl-none"
                  @click="handleDefaultResumeCheckbox()"
                  v-if="isResumeUploaded()"></q-checkbox>
      <q-checkbox v-model="resume.defaultResume" label="Domyślne Cv" class="text-grey-8" v-else disable
                  @click="handleDefaultResumeCheckbox()"></q-checkbox>
    </div>
  </div>
</template>
<script setup>


import {computed, defineEmits, defineProps, onMounted, ref} from "vue";
import {useQuasar} from "quasar";
import NotificationService from "@/services/notification/NotificationService";
import ApplicationService from "@/services/applicant/ApplicationService";

const props = defineProps({
  applicantId: String,
  modelValue: Object,

})
const emit = defineEmits(['resumes:update', 'update:modelValue'])
const resume = computed({
  get: () => props.modelValue,
  set: (val) => emit('update:modelValue', val),
});

onMounted(() => {
})

const isCvUploaded = ref(false)

function handleClickOnCard() {
  if (isResumeUploaded()) {
    removeCv().then(() => {
      NotificationService.showNotification($q, "negative", "Usunięto Cv")
    })
  } else {
    uploadCv()
  }
}

const $q = useQuasar()

function uploadCv() {
  let input = document.createElement('input');
  input.type = 'file';
  input.accept = '.pdf';
  input.onchange = () => {
    let files = Array.from(input.files);
    let file = files[0];
    if (file.size > 5 * 1024 * 1024) {
      NotificationService.showNotification($q, 'negative', 'Maksymalny rozmiar pliku - 5 MB.');
      return;
    }
    isCvUploaded.value = true
    ApplicationService.addResume(file).then((response) => {
      response.json().then(body => {
        if (response.ok) {
          emit("resumes:update")
          NotificationService.showNotification($q, "positive", "Dodano Cv")
        } else {
          NotificationService.showNotification($q, "negative", body.title)
        }

      })
    })
  };
  input.click();
}

function removeCv() {
  try {
    return fetch(process.env.VUE_APP_BACKEND_URL + "/applicants/" + props.applicantId + '/resumes/' + resume.value.id, {
      method: 'DELETE',
      credentials: "include",
    })
        .then(response => {
          if (!response.ok) {
            throw new Error('Server response was not ok');
          }
          emit("resumes:update")
          return response
        })
  } catch (error) {
    console.error(error);
    throw error
  }
}

// const srcData = ref(null)

// async function fetchByteArray() {
//   const base64Data = getResumes().then(response => {
//     response.
//   });
//   const byteArray = Uint8Array.from(atob(base64Data), c => c.charCodeAt(0));
//   const blob = new Blob([byteArray], {type: 'application/pdf'});
//   srcData.value = URL.createObjectURL(blob);
//   showCv.value = true;
// }


function isResumeUploaded() {
  console.info(resume)
  return resume.value.id !== null
}


const isMouseOnCard = ref(false);

function handleMouseOver() {
  isMouseOnCard.value = true;
}

function handleMouseOut() {
  isMouseOnCard.value = false;
}

function evaluateCardBackgroundColor() {
  if (isMouseOnCard.value && !isResumeUploaded()) {
    if (isMouseOnCard.value) {
      return 'bg-dark'
    } else {
      return 'bg-white'
    }
  } else {
    if (isMouseOnCard.value) {
      return 'bg-grey-1'
    } else {
      return 'bg-white'
    }
  }

}

function handleDefaultResumeCheckbox() {
  if (isResumeUploaded()) {
    emit("resumes:update", resume.value.id)
    ApplicationService.setResumeAsDefault(resume.value.id)
  }
}

</script>